import { Box } from '@mui/material'
import React, { useState } from 'react'
import { Link, LinkProps, NavLink } from 'react-router-dom'
import { theme } from '../../../../../assets/themes';
import { sxCollapseElement } from './style'

function CollapseElement(props:LinkProps) {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <>
      <Box sx={sxCollapseElement}>
        <NavLink
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            "background": hover ? theme.palette.primary.light : "",
            "color": "inherit",
            "textDecoration": "none",
            "padding": "0px 8px 0px 12px",
            "height": "42px",
            "display": "flex",
            "justifyContent": "space-between",
            "alignItems": "center",
            "transition": "0.5s ease all",
            "fontSize": "14px",
            "borderRadius": "12px",
          }}
          {...props}>
          {props.children}
        </NavLink>
      </Box>
    </>
  )
}

export default CollapseElement