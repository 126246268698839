import { AlertColor, FormControl, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Errors from "../../../../../../auth-views/login/components/Errors";
import Button from "../../../../../../../components/core-components/inputs/button";
import Alert from "../../../../../../../components/custom-components/alert";
import { sxField } from "../../../../../MrpManagement/mrp/components/modal/style";
import Progress from "../../../../../../../components/custom-components/progress";
import { useNavigate, useParams } from "react-router-dom";
import { BaseService } from "../../../../../../../network/services/base/BaseService";
import { APP_PREFIX_PATH } from "../../../../../../../configs/Appconfig";

function UpdateForm() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [fincode,setFincode] = useState<string>("")
  const [emailError, setEmailError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [surnameError, setSurnameError] = useState<string>("");
  const [fincodeError,setFincodeError] = useState<string>("")
  const workerService = new BaseService(`api/Workers/${params.id}`);
  const [loading, setLoading] = useState<boolean>();
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  //regex for email
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
  );

  //email should end with @code.edu.az
  const regex2 = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-zA-Z0-9_.+-]+@code.edu.az$"
  );
  // fincode
  const specialCharRegex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);

  const getWorkerDetail = () => {
    workerService
      .getAll()
      .then((res) => {
        setName(res.data.name);
        setSurname(res.data.surname);
        setEmail(res.data.email);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWorkerDetail();
  }, []);

  const handleAdd = () => {
    if (!regex.test(email)) {
      setEmailError("Email düzgün deyil");
      return;
    }
    if (!regex2.test(email)) {
      setEmailError("Code Academy emaili qeyd edin");
      return;
    }
    if (!name) {
      setNameError("Ad əlavə edin");
      return;
    }

    if (!surname) {
      setSurnameError("Soyad əlavə edin");
      return;
    }
    if (!email) {
      setEmailError("Email əlavə edin");
      return;
    }
    if (!fincode) {
        setFincodeError("Fincode əlavə edin");
        return;
      }
      if (specialCharRegex.test(fincode)) {
        setFincodeError("Finkod xüsusi simvollardan ibarət olmamalıdır!");
        return;
      }
      if (fincode.length !== 7) {
        setFincodeError("Finkod tam olaraq 7 simvol uzunluğunda olmalıdır!");
        return;
      }
    const model = {
      name: name,
      surname: surname,
      email: email,
    };
    setLoading(true);
    workerService.update(model).then((res) => {
      if (res) {
        setAlertInside({
          text: "Düzəliş edildi",
          severity: "success",
        });

        setAlertOpen(true);
        setLoading(false);
      } else {
        setAlertInside({
          text: "Xəta baş verdi",
          severity: "error",
        });
        setAlertOpen(true);
        setLoading(false);
      }
    });
  };
  if (loading) {
    return <Progress />;
  }
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
                {
                  right: "20px",
                },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              value={name}
              label="Ad"
              sx={sxField}
            />
            {nameError && <Errors message={nameError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
                {
                  right: "20px",
                },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              onChange={(e) => {
                setSurname(e.target.value);
                setSurnameError("");
              }}
              value={surname}
              label="Soyad"
              sx={sxField}
            />
            {surnameError && <Errors message={surnameError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
                {
                  right: "20px",
                },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              value={email}
              label="Email"
              sx={sxField}
            />
            {emailError && <Errors message={emailError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
              {
                right: "20px",
              },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              value={fincode}
              onChange={(e) => {
                setFincode(e.target.value);
                setFincodeError("");
              }}
              label="Fincode"
              sx={sxField}
            />
            {fincodeError && <Errors message={fincodeError} />}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleAdd}>Əlavə et</Button>
        </Grid>
      </Grid>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default UpdateForm;
