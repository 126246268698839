import React, { useEffect, useState } from "react";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";
import { DailySchedule } from "./models/DailySchedule";
import moment from "moment";
import { Room } from "./models/Room";
import { RoomsByWeeks } from "./models/RoomsByWeeks";
import { ClassSchedule } from "./models/ClassSchedule";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { OccupancyRates } from "./models/OccupancyRates";
import { Worker } from "./models/Worker";
import { FilterFields } from "./models/FilterFields";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Mrp() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [format, setFormat] = useState("Günlük");
  const [loading, setLoading] = useState<boolean>(false);
  const [days, setDays] = useState<string[]>([]);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [drawerStatus, setDrawerStatus] = useState<string>("");
  const [dailySchedulesData, setDailySchedulesData] = useState<DailySchedule[]>(
    []
  );
  const [index, setIndex] = useState<number>(0);
  const [selectedDailyScheduleData, setSelectedDailyScheduleData] =
    useState<DailySchedule>({
      date: "",
      calendar: [
        {
          roomName: "",
          roomCapacity: 0,
          sessions: [
            {
              classColor: "",
              className: "",
              startTime: "",
              endtime: "",
            },
          ],
        },
      ],
    });
  const [weeksinYear, setWeeksInYear] = useState<number[]>([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState<number>(0);
  const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [roomsByWeek, setRoomsByWeek] = useState<RoomsByWeeks[]>([]);
  const [classesByWeeks, setClassesByWeek] = useState<ClassSchedule[]>([]);
  const [occupancyRates, setOccupancyRates] = useState<OccupancyRates[]>([]);
  const [filterUpdate, setFilterUpdate] = useState<boolean>(false);
  const [filterUpdateNum, setFilterUpdateNum] = useState<number>(0);
  const [fields, setFields] = useState<FilterFields>({
    dateStart: null,
    dateEnd: null,
    session: "",
    room: [],
    equipment: "",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dailySchedules = new BaseService("/api/Schedules/schedules-of-days");
  const roomService = new BaseService("/api/rooms");
  const roomsByWeeksService = new BaseService(
    "/api/Schedules/schedule-of-rooms-byweeks"
  );
  const classServiceByWeeks = new BaseService(
    "/api/Schedules/schedule-of-classes-byweeks"
  );
  const occupancyRatesService = new BaseService("/api/schedules/rooms");
  const workerService = new BaseService("api/schedules/workers");

  const getRoomsByWeek = async () => {
    try {
      const res = await roomsByWeeksService.getAll();
      setRoomsByWeek(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getWorkers = async () => {
    try {
      const res = await workerService.getAll();
      setWorkers(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getOccupancyRates = async () => {
    try {
      const res = await occupancyRatesService.getAll();
      setOccupancyRates(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  const getClassesByWeeks = async () => {
    try {
      const res = await classServiceByWeeks.getAll();
      setClassesByWeek(res.data);
    } catch (error) {
      return console.log(error);
    }
  };
  const getDailySchedules = async () => {
    try {
      const res = await dailySchedules.getAll();
      setDailySchedulesData(res.data);

      const today = moment().format("YYYY-MM-DD");
      const todaysData = res.data.find(
        (item: DailySchedule) =>
          today === moment(item.date).format("YYYY-MM-DD")
      );
      setSelectedDailyScheduleData(todaysData);

      const allDays: string[] = [];
      res.data.map((item: DailySchedule) =>
        allDays.push(moment(item.date).format("YYYY-MM-DD"))
      );
      setDays(allDays);

      const todaysDataIndex = res.data.indexOf(todaysData);
      setIndex(todaysDataIndex);
    } catch (err) {
      return console.log(err);
    }
  };

  const getRooms = async () => {
    try {
      const res = await roomService.getAll();

      setRooms(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getDailySchedules(),
      getRooms(),
      getRoomsByWeek(),
      getClassesByWeeks(),
      getOccupancyRates(),
      getWorkers(),
    ])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    const todaysWeek = parseInt(moment().format("w"));
    let allWeeksCount = moment().isoWeeksInYear();
    const allWeeks = [];
    for (let i = 1; i <= allWeeksCount + 1; i++) {
      allWeeks.push(i);
    }
    setWeeksInYear(allWeeks);
    setCurrentWeekIndex(allWeeks.indexOf(todaysWeek));
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  if (loading) {
    return <Progress />;
  }

  return (
    <>
      <iframe
        style={{
          width: "100%",
          height: "90%",
        }}
        title="Report Section"
        src="https://app.powerbi.com/view?r=eyJrIjoiNzkwMmRmMDMtZTkxZC00NThhLWE1YzMtZDA1ZDUzOGZiOWM1IiwidCI6ImRhMmY3Y2MyLWQ4YjktNDM5ZS04ZWI0LTY2NDczZjU4ODIyOSIsImMiOjl9"
        frameBorder={0}        
        allowFullScreen={true}
      ></iframe>
    </>
  );
}

export default Mrp;
