import React from "react";
import {
  DataGrid as MaterialDataGridComponent,
  GridColDef,GridRowId
} from "@mui/x-data-grid";

import { datagridSx } from "./style";
import { localeText } from "../../../configs/Locales";

interface DataGridModel {
  data: any;
  handleClickStudentsDetail?:(params: { id: GridRowId}) => void;
  columnsData: GridColDef[];
  pageSizeData: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  disableColumnMenu?: boolean;
  field?: any;
  [x: string]: any;
}

function DataGrid(props: DataGridModel) {
  const {
    data,
    columnsData,
    pageSizeData,
    setPageSize,
    disableColumnMenu,
    field,
    handleClickStudentsDetail,
    ...rest
  } = props;
  return (
    <MaterialDataGridComponent
      rows={data}
      columns={columnsData}
      pageSize={pageSizeData}
      disableColumnMenu={disableColumnMenu}
      // getRowHeight={() => 'auto'}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 15]}
      experimentalFeatures={{ newEditingApi: true }}
      componentsProps={{
        pagination: {
          labelRowsPerPage: "Səhifədəki sətir sayı:",
        },
      }}
      localeText={localeText}
      autoHeight={true}
      rowThreshold={0}
      sx={datagridSx}
      getRowId={field ? (row) => row[field] : (row) => row.id}
      onRowClick={handleClickStudentsDetail}
      {...rest} 
    />
  );
}

export default DataGrid;
