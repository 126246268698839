import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import Title from "../components/Title";
import PasswordRefreshForm from "../refreshpassword/PasswordRefreshFom";
import { Params, useParams } from "react-router-dom";

interface RouteParams {
  keyValue: string;
}
function ChangePassword() {
  const params: Readonly<Params<string>> = useParams();
  const keyValue = params.key;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const content = {
    mainTitle: "  İndi yeni şifrə",
    boldTitle: "təyin et.",
    subtitle: " Şifrəni 2 dəfə daxil edərək yeniləyə bilərsən..",
    boldSubtitle: "",
  };

  return (
    <>
      <Box
        pr={isLargeScreen ? 20 : 0}
        display="flex"
        flexDirection={"column"}
        alignItems="left"
        justifyContent="center"
        mx={10}
        sx={{ height: "100%" }}
      >
        <Title content={content} />

        <Box py={2}>
          <PasswordRefreshForm keyValue={keyValue} />
        </Box>
      </Box>
    </>
  );
}

export default ChangePassword;
