import React from 'react'
import { Box } from '@mui/material';
import PageHeader from '../../../../../../../components/custom-components/page-header';
import Breadcrumbs from '../../../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import PageTitle from '../../../../../../../components/custom-components/page-title';
import { APP_PREFIX_PATH } from '../../../../../../../configs/Appconfig';
import { Session } from '../../models/Session';

interface PropsModel {
    sessions: Session[],
}

function Header(props: PropsModel) {
    const { sessions} = props

    return (
        <>
            <PageHeader>
                <Box>
                    <Breadcrumbs>
                        <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
                        <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
                        <BreadcrumbsLink name="Sessiya" url={`${APP_PREFIX_PATH}/classes`} />
                        <BreadcrumbsTypography name={sessions[0]?.className} />
                    </Breadcrumbs>
                    <PageTitle name={sessions[0]?.className} />
                </Box>
            </PageHeader>
        </>
    )
}

export default Header