import { Fragment, useEffect, useState } from 'react';
import Header from './components/header';
import Card from './components/card';
import {Grid, CircularProgress} from '@mui/material';
import {IUnMarkedAttendancyCardModel} from './components/card';
import { BaseService } from '../../../../network/services/base/BaseService';
import DataGrid from "../../../../components/custom-components/data-grid";
import {tableHeaders} from './tableHeaders';
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

interface IClassModel{
  id: number;
  name: string
}
interface IUnmarkedByClasses{
  attendancePercentage: number | null;
  class: IClassModel | null;
  studentsCount: number | null;
  unMarkDays: number | null;
}

export default function Index() {
  let [searchParams, setSearchParams] = useSearchParams();


  const getDefaultDate = () => {
    if(searchParams.get("startDate") && searchParams.get("endDate")){
       return [dayjs(searchParams.get("startDate")), dayjs(searchParams.get("endDate"))]
    }else{
      let monthLastDay = (dayjs() as any).$M+1 == 2 ? 28 : ((dayjs() as any).$M+1) % 2 == 0 ? 30 : 31
      console.log(((dayjs() as any).$M+1) % 2)
      return [dayjs(new Date(`${(dayjs() as any).$y}-${(dayjs() as any).$M+1}-01`)), dayjs(new Date(`${(dayjs() as any).$y}-${(dayjs() as any).$M+1}-${monthLastDay}`))];
    }
  }

  const [loading, setLoading] = useState(true);
  const [unmarkedByClassesLoading, setUnmarkedByClassesLoading] = useState(false);
  const [programs, setPrograms] = useState<IUnMarkedAttendancyCardModel[]>([]);
  const [currentActive, setCurrentActive] = useState<any>(null);
  const [unmarkedByClasses, setUnmarkedByClasses] = useState<IUnmarkedByClasses[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState(()=> getDefaultDate());
  const allTheRefs: any = {};

  

  useEffect(()=> {
    async function getData() {
      setLoading(true);
      try {
        const classService = new BaseService(`api/Programs`);
        let startDate = `${(date[0] as any)?.$M+1 < 10 ? `0${(date[0] as any)?.$M+1}` : (date[0] as any)?.$M+1}-${(date[0] as any)?.$D < 10 ? `0${(date[0] as any)?.$D}` : (date[0] as any)?.$D}-${(date[0] as any)?.$y}`;
        let endDate = `${(date[1] as any)?.$M+1 < 10 ? `0${(date[1] as any)?.$M+1}` : (date[1] as any)?.$M+1}-${(date[1] as any)?.$D < 10 ? `0${(date[1] as any)?.$D}` : (date[1] as any)?.$D}-${(date[1] as any)?.$y}`;
        setSearchParams({startDate: startDate, endDate: endDate})
        const res = await classService.getAll(
          `api/Programs/unmarked-attendances?startDate=${startDate}&endDate=${endDate}`
        );
        if (res.statusCode === 200) {
          setPrograms(res.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [date]);

  useEffect(()=> {
    async function getData() {
      setUnmarkedByClassesLoading(true);
      try {
        const classService = new BaseService(`api/Programs`);
        let startDate = `${(date[0] as any)?.$M+1 < 10 ? `0${(date[0] as any)?.$M+1}` : (date[0] as any)?.$M+1}-${(date[0] as any)?.$D < 10 ? `0${(date[0] as any)?.$D}` : (date[0] as any)?.$D}-${(date[0] as any)?.$y}`;
        let endDate = `${(date[1] as any)?.$M+1 < 10 ? `0${(date[1] as any)?.$M+1}` : (date[1] as any)?.$M+1}-${(date[1] as any)?.$D < 10 ? `0${(date[1] as any)?.$D}` : (date[1] as any)?.$D}-${(date[1] as any)?.$y}`;

        const res = await classService.getAll(
          `api/Programs/${currentActive.id}/unmarked-attendances-classes?startDate=${startDate}&endDate=${endDate}`
        );
        if (res.statusCode === 200) {
          setUnmarkedByClasses(res.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setUnmarkedByClassesLoading(false);
      }
    }
    if(currentActive){
      getData();
      allTheRefs[currentActive.key!]?.scrollTo();
    }
  }, [currentActive, date])

  const handleOnCardClick = (elem: any, key: number) => {
    if(currentActive?.id === elem.program?.id || elem.unMarkedAttendancesCount === 0){
      setCurrentActive(null);
      setUnmarkedByClasses([]);
    }else{
      setCurrentActive({id: elem.program?.id, key: Math.ceil((key+1)/4)});
    }
  }

  return (
    <>
      <Header date={date} setDate={setDate} />
      {
            loading ? (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress/>
                </div>
            ) : (
              <Grid container sx={{overflowY: 'scroll'}} spacing={2}>
                {
                  programs.map((elem, key)=> (
                    <Fragment key={key}>
                      <Grid item xs={12} md={3} lg={3}>
                        <Card program={elem.program} 
                          unMarkedAttendancesCount={elem.unMarkedAttendancesCount} 
                          totalUnMarkedAttendancesCount={elem.totalUnMarkedAttendancesCount} 
                          totalAttendancePercentage={elem.totalAttendancePercentage}
                          isActive={currentActive?.id === elem.program?.id}
                          onClick={()=> {handleOnCardClick(elem, key)}}
                          />
                      </Grid>
                      {
                        ((key+1) % 4 === 0 || key === programs.length - 1) ? (
                          <Grid item xs={12} ref={ref => allTheRefs[Math.ceil((key+1)/4)] = ref}>
                            {
                              (currentActive && currentActive.key === Math.ceil((key+1)/4)) ? (
                                unmarkedByClassesLoading ? (
                                  <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                      <CircularProgress/>
                                  </div>
                                ) :
                                <DataGrid
                                  data={unmarkedByClasses.map((row: any, index: any) => ({
                                    ...row,
                                    id: index,
                                  }))}
                                  disableSelectionOnClick
                                  columnsData={tableHeaders}
                                  pageSizeData={pageSize}
                                  setPageSize={setPageSize}
                                  disableColumnMenu={true}
                                />
                              ) : null
                            }
                            
                          </Grid>
                        ) : null
                      }  
                    </Fragment>
                  ))
                }
              </Grid>
              )
            }
    </>
  );
}
