import { AlertColor } from '@mui/material/Alert';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Alert from '../../../../../components/custom-components/alert';
import Progress from '../../../../../components/custom-components/progress';
import { ApiInstance } from '../../../../../network/services/core/apiInstance';
import Header from './components/header'
import TableList from './components/table-list'
import { PostClassSessions } from './models/PostClassSessions';
import { Session } from './models/Session';
import InformativeText from '../../../../../components/custom-components/informative-text';

function Detail() {
    const [sessions, setSessions] = useState<Session[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sessionsData, setSessionsData] = useState<PostClassSessions>({ classId: "", sessions: [] });
    const [alertInside, setAlertInside] = useState({ text: "", severity: "success" as AlertColor });
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [sessionStatus, setSessionStatus] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [disableButtonLab, setDisableButtonLab] = useState<boolean>(false);
    
    useEffect(() => {
        if (sessions.length > 0 && sessionStatus === false) {
            setSessionStatus(true)
            setSessionsData({
                classId: params.id!,
                sessions: sessions.map(session => {
                    return {
                        dayOfWeek: session.dayOfWeek,
                        theoricStart: session.startTime.substring(0, 5),
                        theoricEnd: "",
                        practicStart: "",
                        practicEnd: "",
                        labStart: "",
                        labEnd: "",
                        category: session.category
                    }
                })
            })
        }

    }, [sessions])

    useEffect(() => {
        if (sessionsData.sessions.length > 0) {
            setSessionsData({
                classId: params.id!,
                sessions: sessions.map(session => {
                    let selectedSession = sessionsData.sessions.find(item => item.dayOfWeek === session.dayOfWeek)
                    if(session.category==="Lab"){
                        selectedSession = sessionsData.sessions.find(item => item.dayOfWeek === session.dayOfWeek && item.category===session.category)
                    }
                    return {
                        dayOfWeek: session.dayOfWeek,
                        theoricStart: session?.category !== "Lab" ? (selectedSession?.theoricStart ?? "") : "",
                        theoricEnd: session?.category !== "Lab" ? (selectedSession?.theoricEnd ?? "") : "",
                        practicStart: session?.category !== "Lab" ? (selectedSession?.practicStart ?? "") : "",
                        practicEnd: session?.category !== "Lab" ? (selectedSession?.practicEnd ?? "") : "",
                        labStart: session?.category === "Lab" ? (selectedSession?.labStart ?? "") : "",
                        labEnd: session?.category === "Lab" ? (selectedSession?.labEnd ?? "") : "",
                        category: session.category
                    }
                })
            })
        }
    }, [sessions])

    const params = useParams();

    const getSessions = (): void => {
        setLoading(true)
        ApiInstance.get(`api/classes/${params.id}/sessions/`)
            .then(res => {
                setSessions(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getSessions();
    }, [])

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };
    return (
        <>
            {loading ? (
                <Progress />
            )
                : (
                    <>
                            <Header
                                sessions={sessions}
                                sessionsData={sessionsData}
                                setLoading={setLoading}
                                setAlertInside={setAlertInside}
                                setAlertOpen={setAlertOpen}
                                setSessions={setSessions}
                                disableButton={disableButton}
                                setDisableButton={setDisableButton}
                                disableButtonLab={disableButtonLab}
                                setDisableButtonLab={setDisableButtonLab}
                            />

                            <TableList
                                sessions={sessions}
                                setSessions={setSessions}
                                sessionsData={sessionsData}
                                setSessionsData={setSessionsData}
                                setDisableButton={setDisableButton}
                                setDisableButtonLab={setDisableButtonLab}
                            />
                            <InformativeText
                                text={"Diqqət! Yadda saxla düyməsinin aktiv olması üçün daxil edilən saatların toplamı ümumi saata bərabər olmalıdır."}
                            />
                        {alertOpen &&
                            <Alert
                                open={alertOpen}
                                text={alertInside.text}
                                severity={alertInside.severity}
                                handleClose={handleCloseAlert}
                            />
                        }
                    </>
                )
            }

        </>
    )
}

export default Detail