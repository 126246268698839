import {ITableHeaderCellModel} from './header';

export const headerCells : ITableHeaderCellModel[] = [
    {
        text: 'Modulun adı',
        key: 'name',
        hasSort: true,
        style: {display: 'flex', alignItems: 'center', justifyContent: 'space-between'}
    },
    {
        text: 'Alt modullar',
        key: 'subModules'
    },
    {
        text: 'Başlama tarixi',
        key: 'startDate'
    },
    {
        text: 'Bitmə tarixi',
        key: 'endDate'
    },
    {
        text: 'Ümumi saat',
        key: 'hours'
    },
    {
        text: 'Müəllim',
        key: 'muellim'
    },
    {
        text: 'Mentor/ M.K',
        key: 'mentor'
    },
]