import {
  Drawer,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material/";
import React from "react";
import { sxDrawer, sxFilter, sxFilterPart, sxFooterFilter } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../../../../assets/themes";
import Button from "../../../../../../components/core-components/inputs/button";
import { Program } from "../../../../../../network/models/program/Program";

interface PropsModel {
  open: boolean;
  programs: Program[];
  classesByProgram: any[];
  filteredProgram: string;
  filteredClass: string;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  getClassesById: (id: string) => Promise<void>;
  setFilteredProgram: React.Dispatch<React.SetStateAction<string>>;
  setClassesByProgram: React.Dispatch<React.SetStateAction<any[]>>;
  setFilteredClass: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getStudentDetails: (id: string) => Promise<void>;
}

function Filter(props: PropsModel) {
  const {
    open,
    setOpen,
    programs,
    filteredProgram,
    classesByProgram,
    filteredClass,
    setFilteredClass,
    setFilteredProgram,
    setClassesByProgram,
    getClassesById,
    getStudentDetails,
    setIsSubmitted,
  } = props;
  return (
    <>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="right"
        sx={sxDrawer}
      >
        <Box sx={sxFilter}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant={"h3"}
              sx={{ fontSize: "24px", fontFamily: 'Visby CF Bold, sans-serif',}}
            >
              Filter
            </Typography>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.secondary.light,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box component={"form"}>
            <Box sx={sxFilterPart}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: 'Visby CF Bold, sans-serif',
                      marginBottom: "16px",
                    }}
                    variant="h4"
                  >
                    Tədris sahəsi
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Proqram
                    </InputLabel>
                    <Select
                      sx={{
                        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                          {
                            fontFamily: 'Visby CF Bold, sans-serif',
                            fontSize: "14px",
                          },
                        borderRadius: "12px",
                      }}
                      label="Tədris Sahəsi"
                      value={filteredProgram?.length ? filteredProgram : ""}
                      onChange={(e: any) => {
                        getClassesById(e.target.value);
                        setFilteredClass("");
                        setFilteredProgram(e.target.value);
                      }}
                    >
                      {programs &&
                        programs.map((program) => {
                          return (
                            <MenuItem key={program.id} value={program.id}>
                              {program.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: 'Visby CF Bold, sans-serif',
                      marginBottom: "16px",
                    }}
                    variant="h4"
                  >
                    Qrup
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">Qrup</InputLabel>
                    <Select
                      disabled={classesByProgram.length > 0 ? false : true}
                      sx={{
                        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                          {
                            fontFamily: 'Visby CF Bold, sans-serif',
                            fontSize: "14px",
                          },
                        borderRadius: "12px",
                      }}
                      label="Qrup"
                      onChange={(e) => {
                        setFilteredClass(e.target.value);

                        // getStudentDetails(e.target.value);
                      }}
                      value={filteredClass || "qrup"}
                    >
                      <MenuItem sx={{ display: "none" }} value={"qrup"}>
                        Qrup
                      </MenuItem>
                      {classesByProgram &&
                        classesByProgram.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={sxFooterFilter}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    getStudentDetails(filteredClass);
                    setOpen(false);
                    setIsSubmitted(true);
                  }}
                >
                  Tətbiq et
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setFilteredClass("");
                    setFilteredProgram("");
                    setClassesByProgram([]);
                  }}
                  style={{
                    color: "#0E0D0D",
                    background: theme.palette.primary.light,
                  }}
                >
                  Filteri təmizlə
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default Filter;
