import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import Header from "./components/header/Header";
import { HolidayModel } from "./models";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DataGrid from "../../../../components/custom-components/data-grid";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { sxEditIcon } from "../../EducationSystemManagement/groups/style";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";
import ConfirmModal from "../../../../components/custom-components/confirm-modal";
import Button from "../../../../components/core-components/inputs/button";
import { sxBoxCenter } from "./styles";
interface Holiday extends HolidayModel {
  startDateObj: Date;
}
function Holidays() {
  const [id, setId] = useState<string>("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [holidays, setHolidays] = useState<HolidayModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const holidayService = new BaseService(`/api/holidays`);
  const holidayDeleteService = new BaseService(`/api/holidays`);
  const getHolidays = async () => {
    setLoading(true);

    try {
      const res = await holidayService.getAll();
      const dataWithStartDateObj = res.data.map((holiday: any) => ({
        ...holiday,
        startDateObj: new Date(holiday.startDate),
      }));
      const sortedData = dataWithStartDateObj.sort(
        (a: Holiday, b: Holiday) =>
          b.startDateObj.getTime() - a.startDateObj.getTime()
      );
      setHolidays(sortedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await holidayDeleteService.delete(id);
      getHolidays();
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteConfirmationOpen(false);
      setLoading(false);
    }
  };
  const handleClose = () => {
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    getHolidays();
  }, []);
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Başlıq",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Başlama Tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("DD MMMM YYYY"),
    },
    {
      field: "endDate",
      headerName: "Bitmə Tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("DD MMMM YYYY"),
    },
    {
      field: "operations",
      headerName: "Əməliyyatlar",
      flex: 0.4,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box sx={sxBoxCenter}>
            <Tooltip title="Düzəliş et">
              <Link to={`${APP_PREFIX_PATH}/holidays/${params.row.id}`}>
                <EditOutlinedIcon color={"secondary"} sx={sxEditIcon} />
              </Link>
            </Tooltip>
            <Tooltip title="Sil">
              <Box
                onClick={() => {
                  setDeleteConfirmationOpen(true);
                  setId(params.row.id);
                }}
              >
                <DeleteOutlineIcon color={"secondary"} sx={sxEditIcon}/>
              </Box>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  if (loading) {
    return <Progress />;
  }

  return (
    <>
        <Header />

        {/* <Filter open={open} setOpen={setOpen} /> */}
        <Box sx={{ width: "100%", height: "auto" }}>
          <Link to={`${APP_PREFIX_PATH}/holidays/new`}>
            {" "}
            <Button>Yeni</Button>
          </Link>
          <DataGrid
            data={holidays}
            columnsData={columns}
            pageSizeData={pageSize}
            setPageSize={setPageSize}
            disableColumnMenu={true}
          />
        </Box>
        {deleteConfirmationOpen && (
          <ConfirmModal
            open={deleteConfirmationOpen}
            handleClose={handleClose}
            handleAgree={() => handleDelete()}
            text={"Seçilmiş tarixi silmək istədiyinizə əminsiniz?"}
            isWarning={false}
            sendBtnText="Sil"
          />
        )}
    </>
  );
}

export default Holidays;
