export type IFilterModel = {
  status?: string | undefined;
  programId?: string | undefined;
  studyCount?: number | undefined;
  studyCountStatus?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  teacherId?: string | undefined;
  mentorId?: string | undefined;
  startAttendancePercentage?: number | undefined;
  endAttendancePercentage?: number | undefined;
};

export const groupStatuses = [
  {
    value: 'active',
    name: 'Aktiv qruplar',
    bgColor: '#4BA458',
  },
  {
    value: 'new',
    name: 'Açılacaq qruplar',
    bgColor: '#468CBC',
  },
  {
    value: 'close',
    name: 'Bitmiş qruplar',
    bgColor: '#D54939',
  },
];

export const studentCount = [
  {
    name: 'Buna bərabər',
    id: 'equal',
  },
  {
    name: 'Bundan az',
    id: 'less',
  },
  {
    name: 'Bundan çox',
    id: 'more',
  },
];
