import React from 'react'
import Typography from '@mui/material/Typography';


function BreadcrumbsTypography(props: { name: string }) {
    return (
        <Typography
            variant='h2'
            sx={{ color: "#131112", fontSize: "14px", fontFamily: 'Visby CF Bold, sans-serif', }}
        >
            {props.name}
        </Typography>
    )
}

export default BreadcrumbsTypography