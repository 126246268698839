import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isLoggedIn: boolean;
  isInitialized: boolean;
  user: {
    id: string;
    name: string;
    surname: string;
    roleName: string[];
  } | null;
  isLoading: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  isLoading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    registerAuth: (state, action) => {
      const user = action.payload;
      state.user = user;
      state.isLoggedIn = true;
      state.isInitialized = true;
    },
    loginAuth: (state, action) => {
      const user = action.payload;
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = user;
    },
    logoutAuth: (state) => {
      state.isInitialized = false;
      state.isLoggedIn = false;
      state.user = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { registerAuth, loginAuth, logoutAuth, setLoading } =
  authSlice.actions;

export default authSlice.reducer;
