import React from 'react'
import Header from './components/header'
import UpdateForm from './components/update-form'

function WorkerUpdate() {
    return (
        <>
                <Header />
                <UpdateForm />
        </>
    )
}

export default WorkerUpdate