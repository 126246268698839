import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  MenuItem,
  Typography,
  Select,
  Table,
  TableBody as MaterialTableBody,
  TableContainer,
  TableRow,
  Button,
  TextField,
  SelectChangeEvent,
  ClickAwayListener,
  Popover,
  TableCell,
  Dialog,
  Slide,
  FormControl,
  Grid,
} from "@mui/material";

import { TransitionProps } from "react-transition-group/Transition";
import Autocomplete from "@mui/material/Autocomplete";
import TeacherTableHeader from "../../table-header/teacher-table-header";
import { Teachers } from "../../../../models/Teachers";
import { CategoryHours } from "../../../../models/CategoryHours";
import TableCellBody from "../../../../../../../../components/custom-components/table-cell-body";
import { sxSelect, sxSelectStatus, sxAutocomplete } from "../style";
import { TimeInput } from "../time-input/TimeInput";
import { Worker } from "../../../../../../../../network/models/worker/Worker";
import { ROLES } from "../../../../../../../../constants/auth/roles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Class } from "../../../../models/Class";
enum StatusEnum {
  Full = "Tam iştirak",
  Late = "Erkən çıxma",
  Early = "Gecikmə",
  Absent = "İştirak etməyib",
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface TableListModel {
  // hasTheoric: boolean;
  // hasPratice: boolean;
  // hasLab: boolean;
  // classes?: Class[];
  selectedClass?: Class;
  categoryHours: CategoryHours[];
  // setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  // setSelectedClass?: React.Dispatch<React.SetStateAction<Class>>;
  // lessonFormat: LessonFormat;
  // format: string;
  // teacherChecks?: any;
  teachers: Teachers[];
  setTeachers: React.Dispatch<React.SetStateAction<Teachers[]>>;
  workers: Worker[];
  isGreatherThanCategoryHour: (roleName: string, totalHours: number) => number | boolean | undefined;
}
function  TeacherTableList(props: TableListModel) {
  const { teachers, setTeachers, categoryHours, workers,isGreatherThanCategoryHour } = props;
  const handleSelectChange = (event: any, workerId: number) => {
    const valueEnum = StatusEnum[event.target.value as keyof typeof StatusEnum];
    const value = event.target.value as StatusEnum;
    setTeachers((prevTeachers) => {
      const updatedTeachers = prevTeachers.map((teacher) => {
        let teacherTotalHours = null;
        let teachertotalMinutes = null;
        if (valueEnum === StatusEnum.Absent) {
          teacherTotalHours = 0;
          teachertotalMinutes = 0;
        }
        if (
          (valueEnum === StatusEnum.Full) &&
          teacher.roleName === ROLES.MUELLIM 
        ) {
          teacherTotalHours =
            categoryHours.find((item) => item.category === "Theoric")?.hour! ??
            0;
          teachertotalMinutes = 0;
        } else if (
          (valueEnum === StatusEnum.Full) &&
          teacher.roleName === ROLES.MENTOR
        ) {
          teacherTotalHours =
            categoryHours.find((item) => item.category === "Practice")?.hour! ||
            categoryHours.find((item) => item.category === "Lab")?.hour! ||
            categoryHours.find((item) => item.category === "Theoric")?.hour!;
          teachertotalMinutes = 0;
        }
        return teacher.workerId === workerId
          ? {
              ...teacher,
              attendanceStatus: value,
              totalHours: teacherTotalHours,
              totalMinutes: teachertotalMinutes,
            }
          : teacher;
      });
      return updatedTeachers;
    });
  };
  const handleWorkerChange = (
    event: React.ChangeEvent<{}>,
    newValue: Worker | null,
    selectedWorkerId: number,
    roleId: number
  ) => {
    if (newValue) {
      setTeachers((prevTeachers) => {
        const updatedTeachers = prevTeachers.map((teacher) =>
          teacher.workerId === selectedWorkerId
            ? {
                ...teacher,
                workerId: newValue.id,
                roleId: roleId,
                name: newValue.name,
                surname: newValue.surname,
                totalHours:null,
                totalMinutes:null,
                attendanceStatus:null
                
              }
            : teacher
        );
        return updatedTeachers;
      });
    }
  };
  const handleColorOptions = (type: String) => {
    if (type === StatusEnum.Full) {
      return "#4BA458";
    }
    if (type === StatusEnum.Late) {
      return "#E7873C";
    }
    if (type === StatusEnum.Early) {
      return "#D54939";
    }
    return "#999898";
  };
  const uniqueWorkerIds = teachers.map((teacher) => teacher.workerId);
  return (
    <TableContainer>
      <Table
        sx={{
          width: "100%",
          border: "none",
        }}
        aria-label="simple table"
      >
        <TeacherTableHeader teachers={teachers} />
        <MaterialTableBody
          sx={{ fontSize: "14px", fontFamily: "Visby CF Bold, sans-serif" }}
        >
          {categoryHours.length > 0 ? (
            <>
              {React.Children.toArray(
                teachers.map((teacher, index) => {
                  const valueEnum =
                    StatusEnum[
                      teacher.attendanceStatus as keyof typeof StatusEnum
                    ];
                  const disabled =
                    valueEnum === StatusEnum.Full ||
                    valueEnum === StatusEnum.Absent ||
                    valueEnum === undefined;
                    const filteredWorkers = workers.filter(
                      (worker) => !uniqueWorkerIds.includes(worker.id)
                    );
                  return (
                    <TableRow key={teacher.workerId}>
                      <TableCellBody width="5%">{index + 1}</TableCellBody>
                      <TableCellBody width="65%">
                        <FormControl fullWidth>
                          <Autocomplete
                            value={
                              workers.find(
                                (worker) => worker.id === teacher.workerId
                              ) || null
                            }
                            onChange={(event, newValue) =>
                              handleWorkerChange(
                                event,
                                newValue,
                                teacher.workerId,
                                teacher.roleId
                              )
                            }
                            options={filteredWorkers}
                            getOptionLabel={(worker) =>
                              `${worker.name} ${worker.surname}`
                            }
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                   "& .MuiInputBase-input": {
                                    padding: "0px!important",
                                  },
                                }}
                                {...params}
                                placeholder={"Axtar..."}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {teacher.roleName === ROLES.MUELLIM
                                        ? "Müəllim:"
                                        : "Mentor:"}
                                    </Typography>
                                  ),
                                }}
                              />
                            )}
                            sx={sxAutocomplete}
                          />
                        </FormControl>
                      </TableCellBody>
                      <TableCellBody disabled={true} width="15%">
                        <FormControl sx={sxSelectStatus} fullWidth>
                          <Select
                            style={{
                              backgroundColor: handleColorOptions(valueEnum),
                            }}
                            value={teacher.attendanceStatus}
                            onChange={(event) =>
                              handleSelectChange(event, teacher.workerId)
                            }
                            renderValue={(value) =>
                              teacher.attendanceStatus
                                ? valueEnum
                                : "Qeyd edilməyib"
                            }
                            displayEmpty 
                            IconComponent={() => null}
                          >
                            {React.Children.toArray(
                              Object.keys(StatusEnum).map((key) => {
                                const value =
                                  StatusEnum[key as keyof typeof StatusEnum];
                                return (
                                  <MenuItem
                                    sx={sxSelect}
                                    value={key}
                                    key={value}
                                  >
                                    {value}
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                        </FormControl>
                      </TableCellBody>
                      <TableCell sx={{ padding: 0 }} width="15%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimeInput
                            roleName={teacher.roleName}
                            disabled={disabled}
                            totalHours={teacher.totalHours}
                            totalMinutes={teacher.totalMinutes}
                            workerId={teacher.workerId}
                            setTeachers={setTeachers}
                            categoryHours={categoryHours}
                            isGreatherThanCategoryHour={isGreatherThanCategoryHour}
                          />
                        </LocalizationProvider>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </>
          ) : (
            <>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Bü günün tarixində davamiyyət yoxdur
                </TableCell>
              </TableRow>
            </>
          )}
        </MaterialTableBody>
      </Table>
    </TableContainer>
  );
}
export default TeacherTableList;
