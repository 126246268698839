import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "moment/locale/az";
import moment from "moment";
import { TeacherDetails } from "../models/ReportModels";
import {
  sxAvatar,
  sxClassName,
  sxModal,
  sxModalClass,
  sxStudentName,
} from "../../../../EducationProcessesManagement/continuity-result-students/components/modal/style";
import Progress from "../../../../../../components/custom-components/progress";
import ModalField from "./ModalField";
import { localeText } from "../../../../../../configs/Locales";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

interface PropsModel {
  modalData: TeacherDetails;
  modalLoading: boolean;
}

function Modal(props: PropsModel) {
  const { modalData, modalLoading } = props;
  const [pageSize, setPageSize] = useState<number>(8);

  const uncompletedClassesColumns: GridColDef[] = [
    {
      field: "className",
      headerName: "Qruplar",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Başlanğıc tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("D MMMM YYYY"),
    },
    {
      field: "endDate",
      headerName: "Bitiş Tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("D MMMM YYYY"),
    },
  ];
  const getRowId = (row: any) => row.classId;
  if (modalLoading) {
    return <Progress />;
  }

  return (
    <>
      <Box sx={sxModalClass}>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
          <Box sx={{ paddingRight: "16px", borderRight: "1px solid #E9E9E9" }}>
            <Stack direction={"row"} spacing={"12px"} marginBottom={"24px"}>
              <Avatar sx={sxAvatar} alt="Remy Sharp" src="" />
              <Stack justifyContent={"space-evenly"}>
                <Typography variant="h2" sx={sxStudentName}>
                  {modalData.name} {modalData.surname}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"column"}>
              <ModalField
                name={"Mail:"}
                value={`${modalData?.eMail ? modalData?.eMail : "-"}`}
                bgColor={"#EDF4F8"}
              />

              <ModalField
                name={"Davamiyyət faizi:"}
                value={`${modalData?.attendancePercent === 100
                    ? modalData?.attendancePercent
                    : modalData?.attendancePercent?.toFixed(2) || "-"
                  }%`}
                bgColor={"transparent"}
              />
              <ModalField
                name={"Dərs saatı sayğacı"}
                value={modalData?.totalHours?.toString()}
                bgColor={"#EDF4F8"}
              />
              <ModalField
                name={"Tamamladığı dərs saat sayğacı"}
                value={modalData?.completedHours?.toString()}
                bgColor={"transparent"}
              />
              <ModalField
                name={"Ləğv edilən dərs saatı sayğacı"}
                value={modalData?.canceledHours?.toString()}
                bgColor={"#EDF4F8"}
              />
              <ModalField
                name={"Sinif sayı"}
                value={modalData?.totalClasses?.toString()}
                bgColor={"transparent"}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <PieChart width={360} height={270}>
                  <Pie
                    data={[
                      { name: "İştirak", value: modalData?.attendancePercent },
                      {
                        name: "Yoxdur",
                        value: 100 - modalData?.attendancePercent,
                      },
                    ]}
                    // isAnimationActive={false}
                    innerRadius={20}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={(item) => `${item.value.toFixed(2)}%`}
                  >
                    <Cell fill={"#2E7D32"} />
                    <Cell fill={"#d32f2f"} />
                  </Pie>
                  <Tooltip formatter={(value) => `${value}%`} />
                </PieChart>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ paddingLeft: "16px" }} position={"relative"}>
            <Box sx={{ width: "100%", height: "524px", position: "relative" }}>
              <Stack mb={2} justifyContent={"space-evenly"}>
                <Typography variant="h2" sx={sxStudentName}>
                  Davam edən qruplar
                </Typography>
              </Stack>
              <DataGrid
                hideFooterPagination
                hideFooterSelectedRowCount
                autoHeight
                localeText={localeText}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: "Səhifədəki sətir sayı:",
                  },
                }}
                pageSize={4}
                getRowId={getRowId}
                columns={uncompletedClassesColumns}
                rows={modalData.unCompletedClasses ?? []}
              />
              <Stack my={2} justifyContent={"space-evenly"}>
                <Typography variant="h2" sx={sxStudentName}>
                  Tamamlanmış qruplar
                </Typography>
              </Stack>
              <DataGrid
                hideFooterPagination
                hideFooterSelectedRowCount
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: "Səhifədəki sətir sayı:",
                  },
                }}
                autoHeight
                localeText={localeText}
                pageSize={4}
                getRowId={getRowId}
                columns={uncompletedClassesColumns}
                rows={modalData.completedClasses ?? []}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Modal;
