import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Progress from "../../../../../../components/custom-components/progress";
import { ClassService } from "../../../../../../network/services/classService/ClassService";
import { RootState } from "../../../../../../store/reducers";
import { fetchWorkers } from "../../../../../../store/reducers/workerReducer";
import Header from "./components/header";
import TableList from "./components/table-list";
import { Module } from "./models/Module";
import { PostData } from "./models/PostData";
import { AlertColor } from "@mui/material/Alert";
import Alert from "../../../../../../components/custom-components/alert";
import { ROLES } from "../../../../../../constants/auth/roles";

function SetProgramDetail() {
  const [loading, setLoading] = useState<boolean>(false);
  const [classData, setClassData] = useState<Module[]>([]);
  const [instructors, setInstructors] = useState<string[]>([]);
  const [mentors, setMentors] = useState<string[]>([]);
  const [instructor, setInstructor] = useState<string>("");
  const [mentor, setMentor] = useState<string>("");
  const [checked, setChecked] = useState<string[]>([]);
  const [copiedData, setCopiedData] = useState<Module[]>([]);
  const [postData, setPostData] = useState<PostData[]>([]);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const params = useParams();
  const classService = new ClassService();

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const workers = useSelector((state: RootState) => state.worker.workers);
  const statusWorkers = useSelector((state: RootState) => state.worker.status);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchWorkers());
    setLoading(false);
  }, []);

  useEffect(() => {
    const filteredInstructors = workers.filter((worker) =>
      worker.roles.some((role) => role.name === ROLES.MUELLIM)
    );
    const filteredMentors = workers.filter((worker) =>
      worker.roles.some((role) => role.name === ROLES.MENTOR)
    );
    setInstructors(
      filteredInstructors.map((worker) => `${worker.name} ${worker.surname}`)
    );
    setMentors(
      filteredMentors.map((worker) => `${worker.name} ${worker.surname}`)
    );
  }, [statusWorkers, workers]);

  const getClassById = () => {
    setLoading(true);
    classService
      .getById(params.id!)
      .then((res) => {
        res.data = res.data.sort((a: any, b: any) => parseInt(b?.version) - parseInt(a?.version))
        res.data = res.data.map((item: Module) => {
          item.subModules.sort((a: any, b: any) => {
            return parseInt(b?.version.split(".")[1]) - parseInt(a?.version.split(".")[1])
          });
          return item;
        });

        setClassData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getClassById();
  }, []);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  if (statusWorkers === "loading") {
    return <Progress />;
  }

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
            <Header
              instructor={instructor}
              setInstructor={setInstructor}
              mentor={mentor}
              setMentor={setMentor}
              instructors={instructors}
              mentors={mentors}
              data={classData}
              copiedData={copiedData}
              checked={checked}
              postData={postData}
              setLoading={setLoading}
              setAlertInside={setAlertInside}
              setAlertOpen={setAlertOpen}
              setData={setClassData}
            />
            <TableList
              data={classData}
              instructors={instructors}
              mentors={mentors}
              workers={workers}
              checked={checked}
              setChecked={setChecked}
              copiedData={copiedData}
              setCopiedData={setCopiedData}
              instructor={instructor}
              mentor={mentor}
              postData={postData}
              setPostData={setPostData}
            />
          {alertOpen && (
            <Alert
              open={alertOpen}
              text={alertInside.text}
              severity={alertInside.severity}
              handleClose={handleCloseAlert}
            />
          )}
        </>
      )}
    </>
  );
}

export default SetProgramDetail;
