import React from "react";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Teachers } from "../../../../models/Teachers";
import { sxTableCell } from "../style";
interface TableHeaderProps {
  // hasPratice: any;
  // hasTheoric: any;
  // hasLab: any;
  //  classes?: Class[];
  // setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  // categoryHours: CategoryHours[];
  // selectedClass?: Class;
  // setSelectedClass?: React.Dispatch<React.SetStateAction<Class>>;
  // disableTheoric: boolean;
  // disablePractice: boolean;
  // disableLab: boolean;
  // format: string;
  teachers: Teachers[];
}
function TeacherTableHeader(props: TableHeaderProps) {
  const { teachers } = props;

  return (
    <>
      <TableHead>
        <TableRow>
          {
            teachers && teachers?.length > 0 ? (
              <>
                <TableCell width={"5%"} sx={[sxTableCell,{fontWeight:"700"}]} align="left">
                  №
                </TableCell>
                <TableCell width={"65%"} sx={sxTableCell} align="left">
                  Müəllim / Mentor
                </TableCell>
                <TableCell width={"15%"} sx={sxTableCell} align="left">
                  İştirak
                </TableCell>
                <TableCell width={"15%"} sx={sxTableCell} align="left">
                  Dərsdə iştirak vaxtı
                </TableCell>
              </>
            ) : (
              <></>
            )
            // teachers.map((item: any, index: number) => {})
          }
        </TableRow>
      </TableHead>
    </>
  );
}
export default TeacherTableHeader;
