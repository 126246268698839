import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import {
  sxAvatar,
  sxContainer,
  sxContent,
  sxDescription,
  sxTitle,
} from "./style";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Group } from "../../group-list/models";
import Actions from "../actions";
import { Worker } from "../../../models";

import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import RemoveGroupModal from "../remove-group";
import AddUserToGroupModal from "../add-user-groups";
import EditGroupModal from "../edit-group";

interface GroupBoxProps {
  group: Group;
  isLast: boolean;
  getGroupsService: () => void;
  users: Worker[];
}
function GroupBox(props: GroupBoxProps) {
  const navigate = useNavigate();
  const { group, isLast, getGroupsService, users } = props;
  const [showActions, setShowActions] = useState(false);
  const [showActionDelete, setShowActionDelete] = useState(false);
  const [showActionEdit, setShowActionEdit] = useState(false);
  const [addShowAction, setAddShowAction] = useState(false);
  const toggleActions = (event: any) => {
    event.stopPropagation();
    setShowActions(!showActions);
  };
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const modalContainer = modalContainerRef.current;
      if (
        showActions &&
        modalContainer &&
        !modalContainer.contains(event.target as Node)
      ) {
        setShowActions(!showActions);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [toggleActions]);
  return (
    <>
      <Box
        onClick={(event) => {
          navigate(`${APP_PREFIX_PATH}/permissions/${group.id}`);
        }}
        ref={modalContainerRef}
        sx={{
          ...sxContainer,
          "&:hover": !showActions
            ? {
                backgroundColor: "#FFF",
                boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
                transition: "0.5s ease all",
              }
            : "none",
        }}
      >
        <Box sx={sxContent}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Typography lineHeight="16px" sx={sxTitle}>
                {group.name}
              </Typography>
              <Typography sx={sxDescription}>
                {`${group.userCount}`} istifadəçi
              </Typography>
            </Box>
            <Box sx={{ position: "relative" }}>
              <img
                src={`/icons/${!showActions ? "more.svg" : "more-blue.svg"}`}
                alt="more Icon"
                style={{ cursor: "pointer" }}
                onClick={toggleActions}
              />
              {showActions && (
                <Actions
                  groupName={group.name}
                  groupId={group.id.toString()}
                  setShowActions={setShowActions}
                  setShowActionDelete={setShowActionDelete}
                  setAddShowAction={setAddShowAction}
                  setShowActionEdit={setShowActionEdit}
                  isLast={isLast}
                />
              )}
            </Box>
          </Box>
          <Typography lineHeight="24px" sx={sxDescription}>
            {group.description}
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={-1}
          sx={{ height: "100%", alignItems: "flex-end" }}
        >
          {group.workers && (
            <>
              {group.workers.slice(0, 4).map((worker: Worker) => (
                <Avatar
                  key={worker.id}
                  sx={{ ...sxAvatar, bgcolor: worker.avatarColor }}
                >
                  {worker.name[0]}
                  {worker.surname[0]}
                </Avatar>
              ))}
              {group.userCount > 4 && (
                <Avatar sx={sxAvatar}>+{group.userCount - 4}</Avatar>
              )}
            </>
          )}
        </Stack>
      </Box>
      <RemoveGroupModal
        id={group.id}
        getGroupsService={getGroupsService}
        open={showActionDelete}
        setShowActionDelete={setShowActionDelete}
      />
      <AddUserToGroupModal
        groupId={group.id}
        getGroupsService={getGroupsService}
        setAddShowAction={setAddShowAction}
        open={addShowAction}
        users={users}
      />
      <EditGroupModal
        open={showActionEdit}
        setOpen={setShowActionEdit}
        users={users}
        getGroupsService={getGroupsService}
        groupId={group.id}
      />
    </>
  );
}

export default GroupBox;
