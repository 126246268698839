import Box from '@mui/material/Box'
import React from 'react'

function PageHeader({ children }: { children: React.ReactNode }) {
    return (
        <>
            <Box marginBottom={"14px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                {children}
            </Box>
        </>
    )
}

export default PageHeader