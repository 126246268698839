export const sxSearchIcon: React.CSSProperties = {
  width: "20px",
  height: "20px",
  marginRight: "6px",
};
export const sxBtn: React.CSSProperties = {
  padding: "8px 24px",
  borderRadius: "12px",
  fontFamily: 'Visby CF Bold, sans-serif',
  fontWeight: "700",
  textTransform: "initial",
  lineHeight: "24px",
  letterSpacing: "0.4px",
};

export const sxColumnHeader: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const sxEditIcon = {
  cursor: "pointer",
  height: "20px",
  width: "20px",
  transition: "0.5s ease all",
  "&:hover": {
    color: "primary.main",
  },
};

export const filterHeaderStyle = {
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  mb: '16px',
};

export const groupStatusSx = {
display: 'flex',
alignItems: 'center'
}

export const groupStatusCircle = {
width: '8px', 
height: '8px', 
borderRadius: '50%', 
marginRight: '12px'
}
