import {
  Checkbox,
  TableRow,
  Box,
  TableBody as MaterialTableBody,
  Typography,
  FormControl,
  Select,
  TableCell,
  Collapse,
  Table,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import TableCellBody from "../../../../../../../../components/custom-components/table-cell-body";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Module } from "../../models/Module";
import { SubModule } from "../../models/SubModule";
import { Worker as NetworkWorker } from "../../../../../../../../network/models/worker/Worker";
import { sxSelect } from "./style";
import { ROLES } from "../../../../../../../../constants/auth/roles";

interface TableBodyProps {
  copiedData: Module[];
  checked: string[];
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  workers: NetworkWorker[];
  setCopiedData: React.Dispatch<React.SetStateAction<Module[]>>;
  instructors: string[];
  mentors: string[];
}

function TableBody(props: TableBodyProps) {
  const {
    copiedData,
    checked,
    setChecked,
    workers,
    setCopiedData,
    instructors,
    mentors,
  } = props;
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [clickedIcon, setClickedIcon] = useState<number>(-1);

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    const { checked, value } = event.target;
    if (checked) {
      setChecked((prevState) => [...prevState, value]);
      if (item.subModules) {
        const subModuleIds = item.subModules.map(
          (subModule: SubModule) => subModule.id
        );
        setChecked((prevState) => [
          ...prevState,
          ...subModuleIds.filter(
            (subModuleId: string) => !prevState.includes(subModuleId)
          ),
        ]);
      }
    } else {
      setChecked((prevState) => prevState.filter((id) => id !== value));
      if (item.subModules) {
        const subModuleIds = item.subModules.map(
          (subModule: SubModule) => subModule.id
        );
        setChecked((prevState) =>
          prevState.filter((id) => !subModuleIds.includes(id))
        );
      }
    }
  };
  const handleClick = (index: number): void => {
    setOpenIndex(index === openIndex ? -1 : index);
    setClickedIcon(index === clickedIcon ? -1 : index);
  };
  const handleChange = (
    e: SelectChangeEvent,
    topId: string,
    id: string,
    isSubmodule: boolean,
    type: string
  ) => {
    const selectedWorker = workers.find(
      (worker) => `${worker.name} ${worker.surname}` === e.target.value
    );
    const updatedData = copiedData.map((item) => {
      if (isSubmodule) {
        return {
          ...item,
          subModules: item.subModules.map((subModule: SubModule) => {
            if (subModule.id === topId) {
              const updatedWorkers = subModule.workers.filter(
                (worker) => worker.roleId !== id
              );
              if (selectedWorker) {
                const convertedWorker = {
                  ...selectedWorker,
                  role: selectedWorker.roles.filter((q) => q.name === type)[0]
                    ?.name,
                  roleId: selectedWorker.roles
                    .filter((q) => q.name === type)[0]
                    ?.id?.toString()!,
                  id: selectedWorker.id?.toString()!,
                };
                updatedWorkers.push(convertedWorker);
              }
              return {
                ...subModule,
                workers: updatedWorkers,
              };
            } else {
              return subModule;
            }
          }),
        };
      } else {
        if (item.id === topId) {
          const updatedWorkers = item.workers.filter(
            (worker) => worker.roleId !== id
          );
          if (selectedWorker) {
            const convertedWorker = {
              ...selectedWorker,
              role: selectedWorker.roles.filter((q) => q.name === type)[0]
                ?.name,
              roleId: selectedWorker.roles
                .filter((q) => q.name === type)[0]
                ?.id?.toString()!,
              id: selectedWorker.id?.toString()!,
            };
            updatedWorkers.push(convertedWorker);
          }
            const itemSubmodules =  item.subModules.map((subModule: any) => {
              const updatedSubmoduleWorkers = subModule.workers.filter(
                (worker: any) => worker.roleId !== id
              );
          if (item.subModules && selectedWorker) {
              const convertedSubmoduleWorker = {
                ...selectedWorker,
                role: selectedWorker.roles.filter((q) => q.name === type)[0]
                  ?.name,
                roleId: selectedWorker.roles
                  .filter((q) => q.name === type)[0]
                  ?.id?.toString()!,
                id: selectedWorker.id?.toString()!,
              };
              updatedSubmoduleWorkers.push(convertedSubmoduleWorker);
              return {
                ...subModule,
                workers: updatedSubmoduleWorkers,
              };
            }
            });

          return {
            ...item,
            workers: updatedWorkers,
            subModules: itemSubmodules
          };
        } else {
          return item;
        }
      }
    });
    setCopiedData(updatedData);
  };
  return (
    <>
      <MaterialTableBody
        sx={{ fontSize: "14px", fontFamily: "Visby CF Bold, sans-serif" }}
      >
        <>
          {React.Children.toArray(
            copiedData
              .map((item, index) => (
                <>
                  <TableRow>
                    <TableCellBody width="5%">
                      <Checkbox
                        sx={{ padding: "0" }}
                        checked={checked.includes(item.id)}
                        onChange={(e) => handleCheckboxClick(e, item)}
                        value={item.id}
                      />
                    </TableCellBody>

                    <TableCellBody width="25%">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {item.subModules.length > 0 && (
                          <ArrowRightIcon
                            onClick={() => handleClick(index)}
                            sx={{
                              color: "#999898",
                              cursor: "pointer",
                              transition: "0.5s ease all",
                              transform: `${
                                index === clickedIcon ? "rotate(90deg)" : "none"
                              }`,
                              "&:hover": {
                                color: "primary.main",
                              },
                            }}
                          />
                        )}
                        <Typography sx={{ fontSize: "14px" }} component={"p"}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontFamily: "Visby CF Bold, sans-serif",
                            }}
                            component={"span"}
                          >
                            {item.version}.{" "}
                          </Typography>
                          {item.name}
                        </Typography>
                      </Box>
                    </TableCellBody>

                    <TableCellBody width="15%">
                      {item.subModules.length} alt modul
                    </TableCellBody>

                    <TableCellBody width="15%">{item.hours} saat</TableCellBody>

                    <TableCellBody width="20%">
                      <FormControl fullWidth>
                        <Select
                          value={
                            item.workers.find(
                              (worker) => worker.role === ROLES.MUELLIM
                            )
                              ? item.workers.find(
                                  (worker) => worker.role === ROLES.MUELLIM
                                )?.name +
                                " " +
                                item.workers.find(
                                  (worker) => worker.role === ROLES.MUELLIM
                                )?.surname
                              : " "
                          }
                          onChange={(e) =>
                            handleChange(
                              e,
                              item.id,
                              item.workers.find(
                                (worker) => worker.role === ROLES.MUELLIM
                              )?.roleId!,
                              false,
                              ROLES.MUELLIM
                            )
                          }
                          sx={sxSelect}
                        >
                          <MenuItem
                            value={" "}
                            sx={{ display: "none" }}
                          ></MenuItem>
                          {React.Children.toArray(
                            instructors.map((item, instructorIndex) => (
                              <MenuItem
                                value={item}
                                autoFocus={instructorIndex === 0 ? true : false}
                              >
                                {item}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </TableCellBody>

                    <TableCellBody width="20%">
                      <FormControl fullWidth>
                        <Select
                          value={
                            item.workers.find(
                              (worker) => worker.role === ROLES.MENTOR
                            )
                              ? item.workers.find(
                                  (worker) => worker.role === ROLES.MENTOR
                                )?.name +
                                " " +
                                item.workers.find(
                                  (worker) => worker.role === ROLES.MENTOR
                                )?.surname
                              : " "
                          }
                          onChange={(e) =>
                            handleChange(
                              e,
                              item.id,
                              item.workers.find(
                                (worker) => worker.role === ROLES.MENTOR
                              )?.roleId!,
                              false,
                              ROLES.MENTOR
                            )
                          }
                          sx={sxSelect}
                        >
                          <MenuItem
                            value={" "}
                            sx={{ display: "none" }}
                          ></MenuItem>
                          {React.Children.toArray(
                            mentors.map((item, mentorIndex) => (
                              <MenuItem
                                value={item}
                                autoFocus={mentorIndex === 0 ? true : false}
                              >
                                {item}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </TableCellBody>
                  </TableRow>

                  {item.subModules.length > 0 && (
                    <TableRow sx={{ width: "100%" }}>
                      <TableCell sx={{ padding: "0" }} colSpan={6}>
                        {/* <Collapse in={openIndex === index}> */}
                        {openIndex === index && (
                          <Table>
                            <MaterialTableBody>
                              {React.Children.toArray(
                                item.subModules
                                  .map((subModule: SubModule) => (
                                    <TableRow>
                                      <TableCellBody width="5%">
                                        <Checkbox
                                          sx={{ padding: "0" }}
                                          checked={checked.includes(
                                            subModule.id
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxClick(e, subModule)
                                          }
                                          value={subModule.id}
                                        />
                                      </TableCellBody>

                                      <TableCellBody width="25%">
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Tooltip title={subModule.name}>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                              }}
                                              component={"p"}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "14px",
                                                  fontFamily:
                                                    "Visby CF Bold, sans-serif",
                                                }}
                                                component={"span"}
                                              >
                                                {subModule.version}{" "}
                                              </Typography>
                                              {subModule.name}
                                            </Typography>
                                          </Tooltip>
                                        </Box>
                                      </TableCellBody>

                                      <TableCellBody width="15%">
                                        -
                                      </TableCellBody>

                                      <TableCellBody width="15%">
                                        {subModule.hours} saat
                                      </TableCellBody>

                                      <TableCellBody width="20%">
                                        <FormControl fullWidth>
                                          <Select
                                            value={
                                              subModule.workers.find(
                                                (worker) =>
                                                  worker.role === ROLES.MUELLIM
                                              )
                                                ? subModule.workers.find(
                                                    (worker) =>
                                                      worker.role ===
                                                      ROLES.MUELLIM
                                                  )?.name +
                                                  " " +
                                                  subModule.workers.find(
                                                    (worker) =>
                                                      worker.role ===
                                                      ROLES.MUELLIM
                                                  )?.surname
                                                : " "
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                subModule.id,
                                                subModule.workers.find(
                                                  (worker) =>
                                                    worker.role ===
                                                    ROLES.MUELLIM
                                                )?.roleId!,
                                                true,
                                                ROLES.MUELLIM
                                              )
                                            }
                                            sx={sxSelect}
                                          >
                                            <MenuItem
                                              value={" "}
                                              sx={{ display: "none" }}
                                            ></MenuItem>

                                            {React.Children.toArray(
                                              instructors.map(
                                                (item, instructorIndex) => (
                                                  <MenuItem
                                                    value={item}
                                                    autoFocus={
                                                      instructorIndex === 0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                )
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </TableCellBody>

                                      <TableCellBody width="20%">
                                        <FormControl fullWidth>
                                          <Select
                                            value={
                                              subModule.workers.find(
                                                (worker) =>
                                                  worker.role === ROLES.MENTOR
                                              )
                                                ? subModule.workers.find(
                                                    (worker) =>
                                                      worker.role ===
                                                      ROLES.MENTOR
                                                  )?.name +
                                                  " " +
                                                  subModule.workers.find(
                                                    (worker) =>
                                                      worker.role ===
                                                      ROLES.MENTOR
                                                  )?.surname
                                                : " "
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                subModule.id,
                                                subModule.workers.find(
                                                  (worker) =>
                                                    worker.role === ROLES.MENTOR
                                                )?.roleId!,
                                                true,
                                                ROLES.MENTOR
                                              )
                                            }
                                            sx={sxSelect}
                                          >
                                            <MenuItem
                                              value={" "}
                                              sx={{ display: "none" }}
                                            ></MenuItem>

                                            {React.Children.toArray(
                                              mentors.map(
                                                (item, mentorIndex) => (
                                                  <MenuItem
                                                    value={item}
                                                    autoFocus={
                                                      mentorIndex === 0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                )
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </TableCellBody>
                                    </TableRow>
                                  ))
                                  .reverse()
                              )}
                            </MaterialTableBody>
                          </Table>
                        )}
                        {/* </Collapse> */}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
              .reverse()
          )}
        </>
      </MaterialTableBody>
    </>
  );
}

export default TableBody;
