import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { BootstrapDialog } from "./style";

interface PropsModel {
  text: string;
  open: boolean;
  handleClose: () => void;
}

export const Dialog = (props: PropsModel) => {
  const { text, open, handleClose } = props;
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 24,
            height: 24,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogTitle id="customized-dialog-title">
            Yanlış davamiyyət doldurulması
          </DialogTitle>
          <Typography gutterBottom>
            Xahiş edirik, davamiyyətin düzgün yadda saxlanılması üçün{" "}
            <span className="lesson">{text}</span> davamiyyətində ən azı bir
            tələbənin iştirakını qeyd edin.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Bağla
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
