import { Box, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { AppModulesGroup, PermissionLevel } from "../../../../models";
import { BaseService } from "../../../../../../../network/services/base/BaseService";
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#6B696A",
  display: "flex",
  "&.Mui-checked": {
    color: "#468CBC",
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#888",
  },
}));

interface HeaderProps {
  setAppModulesGroup: React.Dispatch<React.SetStateAction<AppModulesGroup>>;
  appModulesGroup: AppModulesGroup;
}
function Header(props: HeaderProps) {
  const { setAppModulesGroup,appModulesGroup } = props;
  const [permissionLevels, setPermissionLevels] = useState<PermissionLevel[]>(
    []
  );
  const permissionLevelsService = new BaseService(
    "api/PermissionLevels/with-accesses"
  );
  const getPermissionLevels = async () => {
    try {
      const res = await permissionLevelsService.getAll();
      setPermissionLevels(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  useEffect(() => {
    getPermissionLevels();
  }, []);
  const handeAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setAppModulesGroup((oldAppModulesGroup) => {
      return {
        ...oldAppModulesGroup,
        appModules: oldAppModulesGroup.appModules.map((oldAppModule) => {
          return {
            ...oldAppModule,
            permissionAccesses: oldAppModule.permissionAccesses.map(
              (permissionAccess) => {
                if (permissionAccess.id === id) {
                  return {
                    ...permissionAccess,
                    isAccess: event.target.checked,
                  };
                }
                return permissionAccess;
              }
            ),
            subAppModules: oldAppModule.subAppModules.map(
              (oldSubAppModule) => {
                return {
                  ...oldSubAppModule,
                  permissionAccesses: oldSubAppModule.permissionAccesses.map(
                    (permissionAccess) => {
                      if (permissionAccess.id === id) {
                        return {
                          ...permissionAccess,
                          isAccess: event.target.checked,
                        };
                      }
                      return permissionAccess;
                    }
                  ),
                };
              }
            ),
          };
        }),
      };
    }
    );
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      alignSelf="stretch"
      marginTop="14px"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "260px",
        }}
      ></Box>

      {permissionLevels.map((item) => (
        <FormGroup>
          <FormControlLabel
            control={<CustomCheckbox color="primary" />}
            label={
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "Visby CF Bold",
                  whiteSpace: "nowrap",
                }}
              >
                Select all
              </Typography>
            }
            checked={appModulesGroup.appModules.every((appModule) =>(
              appModule.permissionAccesses.some((permissionAccess) => permissionAccess.id === item.id && permissionAccess.isAccess)
            ))}
            onChange={(e: any) => handeAllCheckboxChange(e, item.id)}
          />
        </FormGroup>
      ))}
    </Box>
  );
}

export default Header;
