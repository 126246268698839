import { useEffect, useState } from "react";
import {
    Box,
    Popover,
    List,
    ListItemButton,
    ListItemText,
    Divider,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { BaseService } from "../../../network/services/base/BaseService";

interface INotificationsDropdownModel {
    notificationAnchorEl: any,
    setNotificationAnchorEl: any,
    setCount: any
}

const service = new BaseService(`api/Notification`);

export default function NotificationsDropdown({notificationAnchorEl, setNotificationAnchorEl, setCount } : INotificationsDropdownModel) {

    const [notifications, setNotifications] = useState<any>([]);
    const [currentNotification, setCurrentNotification] = useState<any>(null);

    const getNotifications = async () => {
        try{
          const res = await service.getAll('api/Notification');
          if(res.statusCode === 200){
            setNotifications(res.data);
            setCount(res.data.filter((elem: any)=> elem.isRead === false).length)
          }
        }catch(err){
          console.error(err);
        }
    }

    const markNotificationAsRead = async (notificationId: number) => {
        try{
            const res = await service.update({}, `api/Notification/${notificationId}`);
        }
        catch(err){
            console.error(err)
        }
    }

    useEffect(() => {
      getNotifications();
    }, [notificationAnchorEl]);

    const handleNotificationClick = async (elem: any) => {
        await markNotificationAsRead(elem.id);
        setCurrentNotification(elem);
        setNotifications(notifications.map((not:any) => not.id == elem.id ? {...not, isRead: true} : not))
    }

  return (
    <>
        <Popover
        open={Boolean(notificationAnchorEl)}
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        >
        <List
            sx={{
            minWidth: 240,
            maxWidth: 360,
            bgcolor: "background.paper",
            borderRadius: "10px",
            }}
        >
            {
                notifications.map((elem: any)=> (
                    <>
                        <ListItemButton key={elem.id} sx={!elem.isRead ? {bgcolor: '#fbecea'} : null} onClick={() => handleNotificationClick(elem)}>
                            <ListItemText
                                sx={{ fontSize: "12px" }}
                                primary={elem.title}
                            />
                            {
                            !elem.isRead && (
                                    <span style={{backgroundColor: '#d65345', height: '10px', width: '10px', borderRadius: '50%'}}></span>
                            ) 
                            }
                        </ListItemButton>
                    </>
                ))
            }
        </List>
        </Popover>
          <Dialog
          open={Boolean(currentNotification)}
          fullWidth
          maxWidth="sm"
          onClose={()=> setCurrentNotification(null)}
        >
          <DialogTitle>{currentNotification?.title}</DialogTitle>
          <DialogContent >
            <DialogContentText sx={{wordBreak: 'break-word'}}>
              <div dangerouslySetInnerHTML={{ __html: currentNotification?.content }}>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> setCurrentNotification(null)}>Bağla</Button>
          </DialogActions>
        </Dialog>
    </>
  )
}
