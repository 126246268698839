import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Box } from "@mui/material";
export const ModelSkeleton = () => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        flex-start
        marginBottom="10px"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="16px"
        >
          <Box display="flex" alignItems="center" gap="16px ">
            <Skeleton width={70} height={32} />
            <Skeleton width={150} height={32} />
          </Box>
          <Box display="flex" alignItems="center" gap="8px">
            <Skeleton width={24} height={24} />
            <Skeleton width={200} height={24} />
            <Skeleton width={100} height={24} />
          </Box>
        </Box>
        <Box display="flex" gap="16px">
          <Skeleton width={150} height={80} />
          <Skeleton width={150} height={80} />
          <Skeleton width={150} height={80} />
        </Box>
      </Box>
      <Box
        display="flex"
        height="32px"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
      >
        <Box sx={{ height: "40px" }}>
          <Skeleton width={80} height={32} />
        </Box>
        <Box display="flex" alignItems="flex-start" gap="8px">
          <Skeleton width={24} height={24} />
          <Skeleton width={150} height={24} />
        </Box>
      </Box>
      <Box width="100%">
        <Skeleton width="100%" height={400} />
      </Box>
      <Box
        style={{
          display: "flex",
          gap: "16px",
          position: "absolute",
          bottom: 0,
          width: "100%",
          background: "#ffffff",
          padding: "16px 0px",
        }}
      >
        <Skeleton width={140} height={40} />
        <Skeleton width={140} height={40} />
        <Skeleton width={80} height={40} />
      </Box>
    </>
  );
};
