import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface ProgressPercentageProps extends CircularProgressProps {
    format?: string;
    size?: number;
  }
export function ProgressPercentage(props: ProgressPercentageProps) {
    const { value=0,format, size=24 } = props
    let colorMain: string="#468CBC";
    let colorShadow:string='#C6DBEA';
    if (format!=='standart') {
        if (value < 25) {
            colorMain = "#D54939"; // Kırmızı
            colorShadow='#F2C7C2'
          } else if (value < 50) {
            colorMain = "#E7873C"; // Turuncu
            colorShadow='#F8DAC3'
          } else if (value < 75) {
            colorMain = "#FF9800"; // Sarı
            colorShadow='#fce8ca'
          } else {
            colorMain = "#4BA458"; // Yeşil
            colorShadow='#C7E3CB'
          }
    }
  
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color:colorShadow
        }}
        size={size}
        thickness={8}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color:colorMain,
          position: "absolute",
          left: 0,
        }}
        size={size}
        thickness={8}
        {...props}
        value={value}
      />
    </Box>
  );
}
