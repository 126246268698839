import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  sxTableCell,
  sxTimePicker,
  sxTableCellTypography,
} from "./style";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { changeWeekday } from "../../helper/changeWeekDay";
import { PostClassSessions } from "../../../models/PostClassSessions";

interface PropsModel {
  groupedDataState: any;
  sessionsData?: PostClassSessions;
  setSessionsData?: React.Dispatch<React.SetStateAction<PostClassSessions>>;
}

function TableList(props: PropsModel) {
  const { groupedDataState, sessionsData ,setSessionsData} = props;

  const changedTimeFormat = (value: string) => {
    const now = new Date();
    const [hour, minute] = value.split(":");
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      Number(hour),
      Number(minute)
    );
  };
  const handleChange = (newValue: any, category: string, day: string, name: string) => {
    if (setSessionsData != null) {
      setSessionsData((prev) => {
        const sessionExists = prev.sessions.some((q) => q.dayOfWeek === day && q.category === category);
        if (sessionExists) {
          return {
            ...prev,
            sessions: prev.sessions.map((q) => {
              if (q.dayOfWeek === day && q.category === category) {
                return {
                  ...q,
                  [name]: newValue.toString().substring(16, 21),
                };
              } else {
                return q;
              }
            }),
          };
        } else {
          // Belirtilen gün ve kategoriye sahip bir oturum yoksa, yeni bir oturum ekle
          return {
            ...prev,
            sessions: [
              ...prev.sessions,
              {
                dayOfWeek: day,
                category: category,
                [name]: newValue.toString().substring(16, 21),
              },
            ],
          };
        }
      });
    }
  };
  return (
    <>
      {groupedDataState && (
        <>
          <TableContainer>
            <Table
              sx={{
                width: "100%",
                border: "none",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "none" }} align="left">
                    {""}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    <Typography
                      component="span"
                      sx={[sxTableCellTypography, { background: "#702DFF" }]}
                    >
                      Dərs
                    </Typography>
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    <Typography
                      component="span"
                      sx={[sxTableCellTypography, { background: "#2DA9FF" }]}
                    >
                      Lab
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  fontSize: "14px",
                  fontFamily: "Visby CF Bold, sans-serif",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {Object.keys(groupedDataState).map((dayKey) => {
                    return (
                      <TableRow key={dayKey}>
                        <TableCell
                          sx={{
                            padding: "0 0 16px 0",
                            border: "none",
                            fontSize: "16px",
                            fontFamily: "Visby CF Medium, sans-serif",
                          }}
                          width="7%"
                        >
                          <>{changeWeekday(dayKey)}</>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 24px 16px 16px",
                            border: "none",
                          }}
                          width="50%"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}
                          >
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  groupedDataState[dayKey].Theoric?.startTime ??
                                    ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, dayKey,"Theoric","start")
                              }
                              sx={sxTimePicker}
                            />
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  groupedDataState[dayKey].Theoric?.endTime ??
                                    ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, dayKey,"Theoric","end")
                              }
                              sx={sxTimePicker}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 24px 16px 16px",
                            border: "none",
                          }}
                          width="50%"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}
                          >
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  groupedDataState[dayKey].Lab
                                    ?.startTime ?? ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue,"Lab", dayKey, "start")
                              }
                              sx={sxTimePicker}
                            />
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  groupedDataState[dayKey].Lab?.endTime ??
                                    ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue,"Lab", dayKey, "end")
                              }
                              sx={sxTimePicker}
                            />
                          </Box>
                        </TableCell>
                     
                      </TableRow>
                    );
                  })}
                </LocalizationProvider>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default TableList;
