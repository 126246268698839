import React, { useCallback, useEffect, useState } from "react";
import Header from "./components/header";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Module } from "../../../shared/continuity/models/Module";
import { Student } from "../../../shared/continuity/models/Student";
import { Class } from "../../../shared/continuity/models/Class";
import { CategoryHours } from "../../../shared/continuity/models/CategoryHours";
import { AlertColor } from "@mui/material/Alert";
import { lessonFormats } from "./data";
import { LessonFormat } from "../../../shared/continuity/models/LessonFormat";
import { BaseService } from "../../../../../network/services/base/BaseService";
import Progress from "../../../../../components/custom-components/progress";
import Alert from "../../../../../components/custom-components/alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers";
import { Counter } from "../../../shared/continuity/models/Counter";
import TabHeader from "../../../shared/continuity/components/tab-header";
import TableList from "../../../shared/continuity/components/table/table-list/student-table-list";
import { Teachers } from "../../../shared/continuity/models/Teachers";
import { HeldModule } from "../../../shared/continuity/models/HeldModule";
import TeacherTableList from "../../../shared/continuity/components/table/table-list/teacher-table-list";
import StudentTableList from "../../../shared/continuity/components/table/table-list/student-table-list";
import { fetchWorkers } from "../../../../../store/reducers/workerReducer";
import { ROLES } from "../../../../../constants/auth/roles";

function Continuity() {
  const params = useParams();
  const [value, setValue] = useState<number>(0);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const workers = useSelector((state: RootState) => state.worker.workers);
  const [selectedClassLoading, setSelectedClassLoading] =
    useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });

  let isAdmin = true;
  const [isLessonsModalOpen, setIsLessonsModalOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [heldModules, setHeldModules] = useState<HeldModule[]>([{}]);
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [theoricWorker, setTheoricWorker] = useState<string>("");
  const [labWorker, setLabWorker] = useState<string>("");
  const [selectedWorker, setSelectedWorker] = useState<string>("");
  const [totalHours, setTotalHours] = useState({});
  const [practiceWorker, setPracticeWorker] = useState<string>("");
  const [categoryHours, setCategoryHours] = useState<CategoryHours[]>([]);
  const [format, setFormat] = useState<string>(" ");
  const [selectedClass, setSelectedClass] = useState<Class>({
    id: "",
    name: "",
    programId: 0,
    totalHours: 0,
    attendanceHours: 0,
  });
  const [index, setIndex] = useState<number>(-1);
  const [lessonFormat, setLessonFormat] = useState<LessonFormat>({
    name: "",
    value: -1,
  });
  const [counter, setCounter] = useState<Counter>({ hour: 0, totalHour: 0 });
  const [teachers, setTeachers] = useState<Teachers[]>([]);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [hasPratice, sethasPratice] = useState<boolean>(false);
  const [hasTheoric, sethasTheoric] = useState<boolean>(false);
  const [hasLab, sethasLab] = useState<boolean>(false);

  const [teacherChecks, setTeacherChecks] = useState<any>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [indexCheck, setIndexCheck] = useState<number>(-1);
  const [isMentor, setIsMentor] = useState<boolean>(false);
  const modulesByClassesIdService = new BaseService(
    `/api/classes/${params.id}/modules?date=${params.date}`
  );
  const studentsService = new BaseService(
    `/api/classes/${params.id}/students?date=${params.date}`
  );
  const classesService = new BaseService(`/api/classes`);

  const categoryHoursService = new BaseService(
    `/api/classes/${params.id}/sessions-category?date=${params.date}`
  );
  const totalHoursService = new BaseService(
    `/api/classes/${params.id}/counter`
  );
  const teachersByClassId = new BaseService(
    `/api/classes/${params.id!}/workers?date=${params.date}`
  );
  const modulesService = new BaseService(
    `/api/Programs/${selectedClass?.programId}/modules`
  );
  const heldModulesService = new BaseService(
    `/api/Classes/${params?.id}/held-modules/admin?date=${params.date}`
  );
  const getTeacherByClassId = async () => {
    const res = await teachersByClassId.getAll();
    setTeachers(res.data);
    let initialTeacherChecksObj: any = {};
    res.data.forEach((item: any) => {
      initialTeacherChecksObj[item.roleName] = item.isAttendance;
    });
    setTeacherChecks(initialTeacherChecksObj);

    const mentor = res?.data?.find(
      (teacher: Teachers) => teacher.roleName === "mentor"
    );
    mentor ? setIsMentor(true) : setIsMentor(false);
    const teacher = res?.data?.find(
      (teacher: Teachers) => teacher.roleName === "muellim"
    );
    setTheoricWorker(`${teacher?.name} ${teacher?.surname}`);
    setLabWorker(`${mentor?.name} ${mentor?.surname}`);
    setPracticeWorker(`${mentor?.name} ${mentor?.surname}`);
  };
  const getTotalHours = async () => {
    try {
      const res = await totalHoursService.getAll();
      setCounter(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  const getModules = async () => {
    try {
      const res = await modulesService.getAll();
      setModules(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  
  const getHeldModules = async () => {
    try {
      const res = await heldModulesService.getAll();
      if (res.data.length ==0 ) {
        setHeldModules([{}])
      }else{
        setHeldModules(
          res.data.map((item: any) => {
            return {
              moduleId: item.id,
              totalHours: item.totalHours,
              moduleName: `${item.version} - ${item.name}`,
            };
          })
        );
      }
    
    } catch (err) {
      setHeldModules([{}]);
    }
  };
  const getModulesByClassesId = async () => {
    try {
      const res = await modulesByClassesIdService.getAll();
      // setModules(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getCategoryHours = async () => {
    try {
      const res = await categoryHoursService.getAll();
      setCategoryHours(res.data);
      
      let hasTheoric = res.data.find((q: any) => q.category == "Theoric");
      let hasPratice = res.data.find((q: any) => q.category == "Practice");
      let hasLab = res.data.find((q: any) => q.category == "Lab");
      
      sethasTheoric(hasTheoric ? true : false);
      sethasPratice(hasPratice ? true : false);
      sethasLab(hasLab ? true : false);
      const { selectedLessonFormats } = lessonFormats(
        hasTheoric,
        hasPratice,
        hasLab,
        res.data
      );
      setLessonFormat(selectedLessonFormats);
    } catch (err) {
      return console.log(err);
    }
  };

  const getStudents = async () => {
    try {
      const res = await studentsService.getAll();
      setStudents(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getClasses = async () => {
    try {
      await classesService.getById(params.id!).then((res) => {
        setSelectedClass({
          name: res.data.name,
          id: params.id!,
          programId: res.data.program.id,
          totalHours: res.data.totalHours,
          attendanceHours: res.data.currentHours,
        });
      });
    } catch (err) {
      return console.log(err);
    }
  };
  useEffect(()=>{
    getClasses()
  },[])
  useEffect(() => {
    setIndexCheck(-1);
    setIsChecked(false);
    setLoading(true);
    Promise.all([
      getHeldModules(),
      getModules(),
      getModulesByClassesId(),
      getStudents(),
      getCategoryHours(),
      getTotalHours(),
      dispatch(fetchWorkers()),
      getTeacherByClassId(),
    ])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [selectedClass, params.date]);
  useEffect(() => {
    if (modules.length === 3) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [modules]);
  
  useEffect(()=> {
    if(lessonFormat.name === "Dərs ləğv edildi" || lessonFormat.name === "Lab ləğv edildi" ){
      setDisabledBtn(false);
    }
    else{
      if(students.some(x=> x.sessions.some(y=> y.hour !== 0)) 
          && Object.keys(heldModules[0]).length !== 0
          && teachers.some(x=> x.totalHours !== 0)){
            if(teachers.some(x=> x.attendanceStatus === '0')){
              setDisabledBtn(true);
            }else if(teachers.some(x=> x.attendanceStatus !== 'Absent' && x.totalHours === 0)){
              setDisabledBtn(true);
            }
            else{
              setDisabledBtn(false);
            }
      }else{
        setDisabledBtn(true);
      }
    }
  }, [students, heldModules, teachers, lessonFormat])

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const isGreatherThanCategoryHour = (roleName: string, totalHours: number) => {
    if (roleName === ROLES.MENTOR) {
      return (
        totalHours >
        (categoryHours.find((item) => item.category === "Practice")?.hour ||
          categoryHours.find((item) => item.category === "Lab")?.hour ||
          categoryHours.find((item) => item.category === "Theoric")?.hour || 0)
      );
    }
    if (roleName === ROLES.MUELLIM) {
      return (
        totalHours >
          (categoryHours.find((item) => item.category === "Theoric")?.hour ||
        categoryHours.find((item) => item.category === "Practice")?.hour ||
        categoryHours.find((item) => item.category === "Lab")?.hour || 0)
      );
    }
    
  };

  if (loading) {
    return <Progress />;
  }

  return (
    <>
        <Header
          setLessonFormat={setLessonFormat}
          setIndex={setIndex}
          today={params.date!}
          hasTheoric={hasTheoric}
          hasPratice={hasPratice}
          hasLab={hasLab}
          totalHours={totalHours}
          categoryHours={categoryHours}
          workers={workers}
          setLoading={setLoading}
          setAlertInside={setAlertInside}
          setAlertOpen={setAlertOpen}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
          lessonFormat={lessonFormat}
          index={index}
          students={students}
          modules={modules}
          getTotalHours={getTotalHours}
          getStudents={getStudents}
          theoricWorker={theoricWorker}
          setTheoricWorker={setTheoricWorker}
          practiceWorker={practiceWorker}
          setPracticeWorker={setPracticeWorker}
          labWorker={labWorker}
          setLabWorker={setLabWorker}
          getTeacherByClassId={getTeacherByClassId}
          isMentor={isMentor}
          setSelectedWorker={setSelectedWorker}
          setTeachers={setTeachers}
          teachers={teachers}
          setTeacherChecks={setTeacherChecks}
          teacherChecks={teacherChecks}
          format={format}
          heldModules={heldModules}
          setHeldModules={setHeldModules}
          isGreatherThanCategoryHour={isGreatherThanCategoryHour}
        />
{selectedClassLoading ? (
          <Progress />
        ) : (
          <>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <TeacherTableList
                workers={workers}
                teachers={teachers}
                setTeachers={setTeachers}
                categoryHours={categoryHours}
                selectedClass={selectedClass}
                isGreatherThanCategoryHour={isGreatherThanCategoryHour}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <StudentTableList
                setTeachers={setTeachers}
                teacherChecks={teacherChecks}
                hasTheoric={hasTheoric}
                setHasTheoric={sethasTheoric}
                hasPratice={hasPratice}
                hasLab={hasLab}
                setHasLab={sethasLab}
                students={students}
                setStudents={setStudents}
                categoryHours={categoryHours}
                setCategoryHours={setCategoryHours}
                selectedClass={selectedClass}
                setSelectedClass={setSelectedClass}
                lessonFormat={lessonFormat}
                format={format}
                isLessonsModalOpen={isLessonsModalOpen}
                setIsLessonsModalOpen={setIsLessonsModalOpen}
              />
            </Box>
          </>
        )}
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default Continuity;
