export const sxTab = {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
    textTransform: "none",
    "&.Mui-selected": {
        fontFamily: 'Visby CF Bold, sans-serif',
        color: "#000000",
    }
}

export const sxInstructors: React.CSSProperties = {
    fontSize:"12px",
}