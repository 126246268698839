import { Box, Typography } from "@mui/material";
import PermissionLevelsRow from "../permissionLevelsRow";
import { ChangeableStatus, PermissionAccesses, PermissionLevel } from "../../../models";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import { useEffect } from "react";


interface AddPemisssionLevelsProps {
  setIsChangeable: React.Dispatch<React.SetStateAction<ChangeableStatus>>;
  isChangeable: ChangeableStatus;
  addPermissionLevel: PermissionLevel;
  setAddPermissionLevel: React.Dispatch<React.SetStateAction<PermissionLevel>>;
}
function AddPemisssionLevels(props: AddPemisssionLevelsProps) { 
  const { setIsChangeable, isChangeable,setAddPermissionLevel,addPermissionLevel} = props;
 
  const permissionLevelsService = new BaseService(
    "api/PermissionLevels/permission-accesses"
  );
  const getPermissionLevels= async () => {
    try {
      const res = await permissionLevelsService.getAll();
      setAddPermissionLevel((oldValue)=>({...oldValue, permissionAccesses:res.data}))
    } catch (err) {
      return console.log(err);
    }
  };
  useEffect(() => {
    getPermissionLevels();
  }, [isChangeable.isCreatePermissionLevel]);
  return (
    <>
      {isChangeable.isCreatePermissionLevel ? (
        <PermissionLevelsRow  addPermissionLevel={addPermissionLevel} setAddPermissionLevel={setAddPermissionLevel} isChangeable={isChangeable} disabled={isChangeable.isCreatePermissionLevel}/>
      ) : (
        <Box
        onClick={() =>setIsChangeable((oldValue) => ({
          ...oldValue,
          isCreatePermissionLevel: true,
        }))}
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            borderRadius: "16px",
            background: "#F7F7F7",
            width: "100%",
            cursor: "pointer"
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap="12px"
          >
            <Box
              sx={{
                height: "44px",
                width: "44px",
                background: "#FFF",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/icons/ic24-plus.svg" alt="levels add" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="8px"
            >
              <Typography
                sx={{ fontFamily: "Visby CF Bold", lineHeight: "20px" }}
              >
                Yenisini yarat
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Visby CF Regular, sans-serif",
                  lineHeight: "16px",
                  fontSize: "12px",
                  color: "#6B696A",
                }}
              >
                Yeni icazə növü yaratmaq üçün klikləyin
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default AddPemisssionLevels;
