export const sxSearchIcon: React.CSSProperties = {
    width: "20px",
    height: "20px",
    marginRight: "6px"
}

export const sxColumnHeader: React.CSSProperties = {
    display: "flex",
    alignItems: "center"
}

export const sxSelect = {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
        padding: "0",
        fontFamily: 'Visby CF Bold, sans-serif',
        fontSize: "14px"
    },
    '& .MuiOutlinedInput-notchedOutline':
        { border: "none" },
}