import React, { useState } from "react";
import { sxColumnHeader, sxSearchIcon } from "../../../continuity-result/style";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { sxSelect, sxTableCell } from "./style";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Class } from "../../../../shared/continuity/models/Class";
import { set } from "react-hook-form";

type StudentDetailsItem = {
  id: string;
  name: string;
  surname: string;
  className: string;
  arrivalHours: number;
  absentHours: number;
  attendance: number;
};

type TableCellBodyProps = {
  getStudentDetails: (classId: string) => void;
  classes: Array<Class>;
  studentDetails: Array<StudentDetailsItem>;
  classesByProgram: any[];
  isSubmitted: boolean;
  handleClickOpen: () => void;
  getStudentAttendance: (id: string, classId: string) => void;
  getStudentAbsentsDetails: (id: string) => void;
  setSelectedStudentDetail: any;
};

function TableCellBody({
  getStudentDetails,
  classes,
  isSubmitted,
  studentDetails,
  classesByProgram,
  handleClickOpen,
  getStudentAttendance,
  getStudentAbsentsDetails,
  setSelectedStudentDetail,
}: TableCellBodyProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortedData, setSortedData] = useState<StudentDetailsItem[]>([]);
  const [classId, setClassId] = useState<string>("");

  const handleChange = (term: string) => {
    const filtered = studentDetails.filter((student) => {
      const cleanedTerm = term.toLocaleLowerCase().replace(/[^\w\s]/gi, "");
      const cleanedSurname = student.surname
        .toLocaleLowerCase()
        .replace(/[^\w\s]/gi, "");
      const cleanedName = student.name
        .toLocaleLowerCase()
        .replace(/[^\w\s]/gi, "");

      return (
        cleanedSurname.includes(cleanedTerm) ||
        cleanedName.includes(cleanedTerm) ||
        `${cleanedName} ${cleanedSurname}`.includes(cleanedTerm)
      );
    });
    setSearchTerm(term);
    setSortedData(filtered);
  };

  return (
    <div>
      <TextField
        autoFocus
        id="standard-basic"
        variant="standard"
        placeholder="Tələbə axtar.."
        value={searchTerm}
        onChange={(e) => handleChange(e.target.value.trimStart())}
        InputProps={{
          disableUnderline: false,
        }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={sxTableCell} width={"20%"} align="left">
                <Box sx={sxColumnHeader}>
                    A.S.A
                </Box>
              </TableCell>
              <TableCell sx={sxTableCell} width={"20%"} align="left">
                <FormControl sx={{ width: "200px" }}>
                  <Select
                    sx={sxSelect}
                    labelId="class-select-label"
                    id="class-select"
                    value={"all"}
                    onChange={(event: any) => {
                      let id = event.target.value as string;
                      setClassId(id);

                      getStudentAbsentsDetails(id);
                      getStudentDetails(id);
                    }}
                  >
                    <MenuItem sx={{ display: "none" }} value="all">
                      Qrup
                    </MenuItem>
                    {isSubmitted
                      ? React.Children.toArray(
                          classesByProgram.map((classItem: any) => (
                            <MenuItem key={classItem?.id} value={classItem?.id}>
                              {classItem?.name}
                            </MenuItem>
                          ))
                        )
                      : React.Children.toArray(
                          classes.map((classItem: any) => (
                            <MenuItem key={classItem?.id} value={classItem?.id}>
                              {classItem?.name}
                            </MenuItem>
                          ))
                        )}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={sxTableCell} width={"20%"} align="left">
                Dərsdə
              </TableCell>
              <TableCell sx={sxTableCell} width={"20%"} align="left">
                Yoxdur
              </TableCell>
              <TableCell sx={sxTableCell} width={"20%"} align="left">
                Davamiyyət faizi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 || studentDetails.length > 0 ? (
              sortedData.length === 0 ? (
                studentDetails?.map((row: any) => (
                  <TableRow
                    onClick={() => {
                      if (isSubmitted) {
                        const filtered: any = classesByProgram.filter(
                          (item) => item.name === row.className
                        );

                        getStudentAttendance(row?.studentId, filtered[0].id);
                        setSelectedStudentDetail(row);
                        handleClickOpen();
                      } else {
                        const filtered: any = classes.filter(
                          (item) => item.name === row.className
                        );

                        getStudentAttendance(row?.studentId, filtered[0].id);
                        setSelectedStudentDetail(row);
                        handleClickOpen();
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                    key={row.id}
                  >
                    <TableCell>{`${row?.name} ${row?.surname}`}</TableCell>
                    <TableCell>{row?.className}</TableCell>
                    <TableCell>{row?.arrivalHours} saat</TableCell>
                    <TableCell>{row?.absentHours} saat</TableCell>
                    <TableCell>
                      {row?.attendance === 100
                        ? row?.attendance
                        : row?.attendance.toFixed(2)}{" "}
                      %
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                sortedData?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell>{`${row?.name} ${row?.surname}`}</TableCell>
                    <TableCell>{row?.className}</TableCell>
                    <TableCell>{row?.arrivalHours} saat</TableCell>
                    <TableCell>{row?.absentHours} saat</TableCell>
                    <TableCell>
                      {row?.attendance === 100
                        ? row?.attendance
                        : row?.attendance.toFixed(2)}{" "}
                      %
                    </TableCell>
                  </TableRow>
                ))
              )
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{ height: "104px" }}
                    colSpan={6}
                    align="center"
                  >
                    Sətir yoxdur
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableCellBody;
