import React from "react";
import { Link } from "react-router-dom";

function BreadcrumbsLink(props: { name: string; url: string }) {
  const { name, url } = props;
  return (
    <Link style={{ color: "#131112", textDecoration: "none" }} to={url}>
      {name}
    </Link>
  );
}

export default BreadcrumbsLink;
