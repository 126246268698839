import React from "react"

export const sxSelect = {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
        padding: "0",
        fontFamily: 'Visby CF Bold, sans-serif',
        fontSize: "14px"
    },
    '& .MuiOutlinedInput-notchedOutline':
        { border: "none" },
}

export const sxTableCell: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
    color: "#000000",
}

export const sxSelectInside = {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
        fontWeight: "400",
    },
    width: "100%",
}

export const sxButtonIcon:React.CSSProperties={
    padding:0,
    margin:0,
    display:"flex",
    justifyContent:"flex-start",
    position:"relative",
    minWidth:"20px"
}

export const sxNoteIcon:React.CSSProperties={
    width:"20px",
    height:"20px"
}

export const sxPoint:React.CSSProperties={
    width:"8px",
    height:"8px",
    background:"#D54939",
    border:"2px solid #FFFFFF",
    position:"absolute",
    top:"-1px",
    right:"-2px",
    borderRadius:"50%"
}