import React, { useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import Errors from "../components/Errors";
import Buttons from "../components/Buttons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import { API_URL } from "../../../../configs/Appconfig";

interface PasswordInputs {
  password: string;
  confirmPassword: string;
}

export default function PasswordRefreshFom(params: any) {
  const [alertText, setAlertText] = useState<string>("");
  const [open, setOpen] = useState(false);

  const [severity, setSeverity] = useState("success" as AlertColor);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PasswordInputs>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const handleFormSubmit = handleSubmit(async (data) => {
    try {
      const res = await axios.post(
        API_URL + "api/Auth/update-password",
        { key: params.keyValue, password: data.password },
        { withCredentials: true }
      );
      if (res.status === 204) {
        setAlertText("Şifrəniz dəyişdirilmişdir.");
        setSeverity("success" as AlertColor);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      setAlertText("Yeniden sınayın.");
      setSeverity("error" as AlertColor);
      setOpen(true);
      console.log(error);
    }
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility = (id: string) => {
    if (id === "password") {
      setShowPassword1((prevShowPassword) => !prevShowPassword);
    } else if (id === "confirm") {
      setShowPassword2((prevShowPassword) => !prevShowPassword);
    }
  };

  const [activeInput, setActiveInput] = useState("");

  const handleInputFocus = (id: string) => {
    setActiveInput(id);
  };

  const handleInputBlur = () => {
    setActiveInput("");
  };
  return (
    <>
      {open && (
        <Alert
          open={open}
          text={alertText}
          severity={severity}
          handleClose={handleClose}
        />
      )}
      <form
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        onSubmit={handleFormSubmit}
      >
        <Controller
          name="password"
          control={control}
          rules={{
            required: "Yeni şifrənizi qeyd edin.",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              type={showPassword1 ? "text" : "password"}
              variant="outlined"
              error={!!errors.password}
              InputLabelProps={{
                style: { color: "#0E0D0D" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleTogglePasswordVisibility("password")}
                      edge="end"
                    >
                      {showPassword1 ? (
                        <VisibilityOff
                          color="disabled"
                          sx={{
                            color:
                              activeInput === "password"
                                ? "#468CBC"
                                : undefined,
                          }}
                        />
                      ) : (
                        <Visibility
                          color="disabled"
                          sx={{
                            color:
                              activeInput === "password"
                                ? "#468CBC"
                                : undefined,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                onFocus: () => handleInputFocus("password"),
                onBlur: handleInputBlur,
                sx: {
                  borderRadius: 3,
                  "& fieldset": {
                    borderColor: "grey.500",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#468CBC !important",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: "Şifrəni təsdiqlə",
            validate: (value) => value === password || "Şifrələr eyni deyil",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Confirm Password"
              type={showPassword2 ? "text" : "password"}
              id="confirm"
              variant="outlined"
              error={!!errors.confirmPassword}
              InputLabelProps={{
                style: { color: "#0E0D0D" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleTogglePasswordVisibility("confirm")}
                      edge="end"
                    >
                      {showPassword2 ? (
                        <VisibilityOff
                          sx={{
                            color:
                              activeInput === "confirm" ? "#468CBC" : undefined,
                          }}
                          color="disabled"
                        />
                      ) : (
                        <Visibility
                          sx={{
                            color:
                              activeInput === "confirm" ? "#468CBC" : undefined,
                          }}
                          color="disabled"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                onFocus: () => handleInputFocus("confirm"),
                onBlur: handleInputBlur,
                sx: {
                  borderRadius: 3,
                  "& fieldset": {
                    borderColor: "grey.500",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#468CBC !important",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          )}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Buttons />
        </Box>
        {errors.password?.message && (
          <Errors message={errors.password.message} />
        )}
        {errors.confirmPassword?.message && (
          <Errors message={errors.confirmPassword.message} />
        )}
      </form>
    </>
  );
}
