import React from "react";
import { Box, Typography } from "@mui/material";
// import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
// import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
// import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
// import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
// import PageTitle from "../../../../../components/custom-components/page-title";
// import Button from "../../../../../components/core-components/inputs/button";
// import PageHeader from "../../../../../components/custom-components/page-header";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import { sxFilter } from "/../../../continuity-result/components/header/style";
// import PageHeader from "../../../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import Button from "../../../../../components/core-components/inputs/button";
import PageHeader from "../../../../../components/custom-components/page-header";

function Header() {
  return (
    <>
      <PageHeader>
        <Box>
          {/* <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Admin Əməliyyatları" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Dəstək mesajları" />
          </Breadcrumbs> */}
          <Box>
            <PageTitle name="Dəstək Mesajları" />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}></Box>
      </PageHeader>
    </>
  );
}

export default Header;
