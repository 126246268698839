export const changeWeekday = (weekDay: string): string => {
    switch (weekDay) {
        case "Monday":
            return "B.e";
        case "Tuesday":
            return "Çər.";
        case "Wednesday":
            return "Ç.a";
        case "Thursday":
            return "C.a";
        case "Friday":
            return "Cümə";
        case "Saturday":
            return "Şənbə";
        case "Sunday":
            return "Bazar";
        default:
            return "-";
    }
};