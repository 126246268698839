import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Buttons() {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <Button
        sx={{
          borderRadius: "12px",
          backgroundColor: "#4BA458",
          textTransform: "none",
          padding: "12px 24px",
          marginTop: "20px",
        }}
        type="submit"
        variant="contained"
        color="success"
      >
        Davam et
      </Button>
      <Button
        onClick={() => navigate("/auth/login", { replace: true })}
        sx={{
          borderRadius: "12px",
          backgroundColor: "#EDF6EE",
          color: "black",
          padding: "12px 24px",
          textTransform: "none",
          marginTop: "20px",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#EDF6EE",
          },
        }}
        type="button"
        variant="contained"
      >
        Ləğv et
      </Button>
    </>
  );
}

export default Buttons;
