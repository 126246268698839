import React, { useState } from "react";
import CollapseElement from "../collapse-element";
import MenuElement from "../menu-element";
import { Box, Collapse } from "@mui/material";
import { ElementModel } from "./models/ElementModel";
import { CollapseElementModel } from "../menu-elements/models/CollapseElement";
import { Link } from "react-router-dom";
import { MenuItemRoleProvider } from "../../../../../utils/auth/MenuItemRoleProvider";

function Element(props: ElementModel) {
  const {
    open,
    menuElement,
    menuCollapseElements,
    menuIcon,
    element,
    setElement,
    permission,
    disabled,
    url
  } = props;
  const [collapseElements, setCollapseElements] = useState<
    CollapseElementModel[]
  >([]);
  const [hover, setHover] = useState<boolean>(false);
  const handleClick = (
    name: string,
    collapseElements: CollapseElementModel[]
  ): void => {
    setElement(name);
    setCollapseElements(collapseElements);
  };
  return (
    <>
      <MenuItemRoleProvider permission={permission}>
        <Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{ 
            position: "relative",
            pointerEvents: disabled ? "none" : "auto",
            opacity: disabled ? 0.5 : 1,
           }}
        >
          {menuCollapseElements.length > 0 ? (
            <MenuElement
              element={element}
              menuElement={menuElement}
              open={open}
              setElement={setElement}
              handleClick={handleClick}
              menuCollapseElements={menuCollapseElements}
              menuIcon={menuIcon}
              hover={hover}
            />
          ) : (
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to={`/admin/app/${url ? url : menuElement}`}
            >
              <MenuElement
                element={element}
                menuElement={menuElement}
                open={open}
                setElement={setElement}
                handleClick={handleClick}
                menuCollapseElements={menuCollapseElements}
                menuIcon={menuIcon}
                hover={hover}
              />
            </Link>
          )}


          {open && menuCollapseElements.length > 0 && (
            <Collapse in={element === menuElement}>
              {React.Children.toArray(
                collapseElements &&
                collapseElements.map((item) => (
                  <CollapseElement to={item.url}>{item.name}</CollapseElement>
                ))
              )}
            </Collapse>
          )}
        </Box>
      </MenuItemRoleProvider>

    </>
  );
}

export default Element;
