// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/VisbyBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/VisbyRegular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/VisbyMedium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/VisbyDemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/VisbyLight.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Visby CF Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF DemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/style/font.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,+DAAsD;EACtD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,+DAAyD;EACzD,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,+DAAwD;EACxD,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,+DAA0D;EAC1D,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,+DAAuD;EACvD,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Visby CF Bold\";\n  src: url(\"../fonts/VisbyBold.woff\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Visby CF Regular\";\n  src: url(\"../fonts/VisbyRegular.woff\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Visby CF Medium\";\n  src: url(\"../fonts/VisbyMedium.woff\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Visby CF DemiBold\";\n  src: url(\"../fonts/VisbyDemiBold.woff\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Visby CF Light\";\n  src: url(\"../fonts/VisbyLight.woff\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
