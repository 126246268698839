import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import ConfirmModal from "../../../../../../components/custom-components/confirm-modal";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
interface RemoveGroupProps {
  getGroupsService?: () => void;
  id: number;
  isFromDetail?: boolean;
  open: boolean;
  setShowActionDelete: (value: boolean) => void;
}
function RemoveGroupModal(props: RemoveGroupProps) {
  const navigate = useNavigate();
  const { open,setShowActionDelete,getGroupsService, id, isFromDetail } = props;
  const GroupsService = new BaseService(`api/PermissionGroups`);
  const removeGroupHandler = (groupId: number) => {
    GroupsService.delete(groupId.toString())
      .then((res) => {
        isFromDetail && navigate(`${APP_PREFIX_PATH}/permissions`);
        getGroupsService && getGroupsService();
      })
      .catch((err) => console.log(err));
  };
  return (
      <ConfirmModal
        text="Qrupu silmək istədiyinizdən əminsinizmi?"
        sendBtnText="Qrupu sil"
        isWarning={false}
        open={open}
        handleClose={(e: any) => {
          setShowActionDelete(false);
        }}
        handleAgree={(e: any) => {
          removeGroupHandler(id);
        }}
      />
  );
}

export default RemoveGroupModal;
