import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";

const sxTableCell: React.CSSProperties = {
  fontSize: "14px",
  fontFamily: 'Visby CF Bold, sans-serif',
  color: "#000000",
}

export interface ITableHeaderCellModel{
  onClick?: any,
  text: string,
  key: string,
  hasSort?: boolean,
  style?: any
}

interface ITableHeaderModel{
  cells: ITableHeaderCellModel[],
  hasCheckBox: boolean,
  onCheckBoxChecked?: any,
  areAllSelected: boolean,
  indeterminate: boolean,
  handleSort?: any
  hasDelete?:boolean
}

export default function Index({cells, hasCheckBox = false, onCheckBoxChecked, areAllSelected = false, indeterminate = false, handleSort, hasDelete} : ITableHeaderModel) {
  return (
    <TableHead>
        <TableRow>
          {
            hasCheckBox ? (
              <TableCell sx={sxTableCell} align="left">
              <Checkbox
                sx={{ padding: "0" }}
                checked={areAllSelected}
                indeterminate={indeterminate}
                onChange={onCheckBoxChecked}
              />
              </TableCell>
            ) : null
          }
          {
            cells.map((elem, index)=> (
              <TableCell
                key={index}
                sx={sxTableCell}
                style={elem.style}
                onClick={elem.onClick}
                align="left"
              >
                {elem.text}
                {
                  elem.hasSort ? (
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleSort}
                    >
                      <img src="/icons/sort-down.svg" />
                    </button>
                  ): null
                }
              </TableCell>
            ))
          }
          {
            hasDelete && (
              <TableCell sx={sxTableCell} width="5%">
                <span></span>
              </TableCell>
            )
          }
        </TableRow>
    </TableHead>
    
  )
}
