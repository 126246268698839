export const sxTab = {
    display: "flex",
    padding: "0px 8px",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
    textTransform: "none",
    "&.Mui-selected": {
      fontFamily: "Visby CF Bold, sans-serif",
      color: "#000000",
    },
  };