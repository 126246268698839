import React from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import azLocale from "dayjs/locale/az";
import FormControl from '@mui/material/FormControl';

let capitalizedMonthNames: any[] = [];

if (azLocale.months && Array.isArray(azLocale.months)) {
  capitalizedMonthNames = azLocale.months.map((monthName) => {
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  });
}

const customAzLocale = {
  ...azLocale,
  months: capitalizedMonthNames,
};

export default function Header({date, setDate} : any) {

  return (
    <>
      <Box
        marginBottom={"24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box sx={{width: '100%'}}>
          {/* <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Yazılmamış davamiyyətlər" />
          </Breadcrumbs> */}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <PageTitle name="Yazılmamış davamiyyətlər" />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormControl sx={{width: '100%'}}>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                      <LocalizationProvider
                        locale={customAzLocale}
                        dateAdapter={AdapterDayjs}
                      >
                        <DateRangePicker
                          format="DD MM YYYY"
                          value={date}
                          onChange={(innerDate) => {
                            setDate(innerDate)
                          }}
                          sx={{border: 'none', width: '100%'}}
                          slotProps={{ textField: { variant: 'standard', } }}
                          // components={{
                          //   OpenPickerIcon: ArrowDropDownSharpIcon,
                          // }}
                        />
                      </LocalizationProvider>
                    </Box>
                  
                </FormControl>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
