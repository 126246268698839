import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./components/header";
import { ApiInstance } from "../../../../../network/services/core/apiInstance";
import moment from "moment";
import TableList from "./components/table-list";
import Progress from "../../../../../components/custom-components/progress";
import { Session, Sessions } from "./models/Sessions";
import { AlertColor } from "@mui/material/Alert";
import Alert from "../../../../../components/custom-components/alert";

function SetSessionByDay() {
  const [session, setSession] = useState<Session[]>([]);
  const [practiceHours, setPracticeHours] = useState<any>(0);
  const [theoricHours, setTheoricHours] = useState<any>(0);
  const [labHours, setLabHours] = useState<any>(0);
  const [practiceEndHours, setPracticeEndHours] = useState<string>("");
  const [practiceStartHours, setPracticeStartHours] = useState<string>("");
  const [theoricStartHours, setTheoricStartHours] = useState<string>("");
  const [theoricEndHours, setTheoricEndHours] = useState<string>("");
  const [labStartHours, setLabStartHours] = useState<string>("");
  const [labEndHours, setLabEndHours] = useState<string>("");
  const [sessions, setSessions] = useState<Sessions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  //add post request model
  const [sessionsData, setSessionsData] = useState<any>([]);
  const params = useParams();
  const classId = params.id;
  const date = params.date;
  const getSessions = (): void => {
    setLoading(true);
    ApiInstance.get(`api/classes/${params.id}/class-session?date=${date}`)
      .then((res) => {
        setSessions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getClasses = () => {
    setLoading(true);
    ApiInstance.get(
      `api/classes/${classId}/sessions-category?date=${date}`
    ).then((res) => {

      setSession(res.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    Promise.all([getSessions(), getClasses()]).catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getSessionsData();
  }, [session, sessions, theoricHours, practiceHours, labHours]);

  const getSessionsData = () => {
    if (session.length > 0) {
      for (let i = 0; i < session.length; i++) {
        const category = session[i];

        if (category.category === "Theoric") {
          const hour = category.hour;
          setTheoricHours(hour);
        } else if (category.category === "Practice") {
          const hour = category.hour;
          setPracticeHours(hour);
        } else if (category.category === "Lab") {
          const hour = category.hour;
          setLabHours(hour);
        }
      }
      // if (theoricHours === 0 && !theoricHours) {
      const model = {
        totalHours: theoricHours + practiceHours + labHours,
        classId: classId,
        dayOfWeek: moment(date).format("dddd "),
        date: date,
        theoricStart: "",
        theoricEnd: "",
        practicStart: "",
        practicEnd: "",
        labStart: "",
        labEnd: "",
      };

      for (const session of sessions) {
        if (session.category === "Lab") {
          if (session.startTime && session.endTime) {
            model.labStart = session.startTime.substring(0, 5);
            model.labEnd = session.endTime.substring(0, 5);
          }
        } else if (session.category === "Practice") {
          if (session.startTime && session.endTime) {
            model.practicStart = session.startTime.substring(0, 5);
            model.practicEnd = session.endTime.substring(0, 5);
          }
        } else if (session.category === "Theoric") {
          if (session.startTime && session.endTime) {
            model.theoricStart = session.startTime.substring(0, 5);
            model.theoricEnd = session.endTime.substring(0, 5);
          }
        }
      }
      setSessionsData(model);
    }
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      {" "}
        <Header
          sessions={sessions}
          sessionsData={sessionsData}
          setLoading={setLoading}
          setAlertInside={setAlertInside}
          setAlertOpen={setAlertOpen}
        />
        {loading ? (
          <Progress />
        ) : (
          <TableList
            setTheoricStartHours={setTheoricStartHours}
            setTheoricEndHours={setTheoricEndHours}
            setPracticeStartHours={setPracticeStartHours}
            setPracticeEndHours={setPracticeEndHours}
            setLabStartHours={setLabStartHours}
            setLabEndHours={setLabEndHours}
            practiceEndHours={practiceEndHours}
            practiceStartHours={practiceStartHours}
            theoricStartHours={theoricStartHours}
            theoricEndHours={theoricEndHours}
            labStartHours={labStartHours}
            labEndHours={labEndHours}
            isLoading={loading}
            sessions={sessions}
            sessionsData={sessionsData}
            setSessionsData={setSessionsData}
          />
        )}
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default SetSessionByDay;
