import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import PageHeader from "../../../../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../../../configs/Appconfig";
import Button from "../../../../../../../components/core-components/inputs/button";
import { Session } from "../../models/Session";
import { PostClassSessions } from "../../models/PostClassSessions";
import moment from "moment";
import { ApiInstance } from "../../../../../../../network/services/core/apiInstance";
import { AlertColor } from "@mui/material/Alert";
import { PostSession } from "../../models/PostSession";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";
interface PropsModel {
  sessions: Session[];
  setSessions: React.Dispatch<React.SetStateAction<Session[]>>;
  sessionsData: PostClassSessions;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertInside: any;
  disableButton: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  disableButtonLab: boolean;
  setDisableButtonLab: React.Dispatch<React.SetStateAction<boolean>>;
}

function Header(props: PropsModel) {
  const {
    sessions,
    sessionsData,
    setLoading,
    setAlertOpen,
    setAlertInside,
    setSessions,
    disableButton,
    setDisableButton,
    disableButtonLab,
    setDisableButtonLab
  } = props;
  const [status, setStatus] = useState<boolean>(false);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     if (sessionsData.sessions.length > 0) {
  //       setStatus(true);
  //       sessions.forEach((session) => {
  //         const totalMinutes = session.totalHours * 60;
  //         sessionsData.sessions.forEach((data) => {
  //           if (data.dayOfWeek === session.dayOfWeek) {
  //             const theoricStart = moment(data.theoricStart, "HH:mm");
  //             const theoricEnd = moment(data.theoricEnd, "HH:mm");
  //             const practicStart = moment(data.practicStart, "HH:mm");
  //             const practicEnd = moment(data.practicEnd, "HH:mm");
  //             if (
  //               timeDifferences(theoricEnd, theoricStart) < 0 ||
  //               timeDifferences(practicEnd, practicStart) < 0 ||
  //               timeDifferences(practicStart, theoricEnd) < 0 ||
  //               timeDifferences(theoricEnd, theoricStart) +
  //                 timeDifferences(practicEnd, practicStart) !==
  //                 totalMinutes
  //             ) {
  //               return setStatus(false);
  //             }
  //           }
  //         });
  //       });
  //     }
  //   }, [sessionsData]);
  useEffect(() => {
    let totalHours = 0;
    if (sessionsData.sessions.length > 0) {
      let totalHoursDifference = 0;

      sessions.forEach((session) => {
        const totalMinutes = session.totalHours * 60;
        totalHours += session.totalHours;
        let sessionHoursDifference = 0;
        sessionsData.sessions.forEach((data) => {
          if (data.dayOfWeek === session.dayOfWeek) {
            const theoricStart = session.category === "Lab" ? null : (data.theoricStart
              ? moment(data.theoricStart, "HH:mm")
              : null);
            const theoricEnd = session.category === "Lab" ? null : (data.theoricEnd
              ? moment(data.theoricEnd, "HH:mm")
              : null);
            const practicStart = session.category === "Lab" ? null : (data.practicStart
              ? moment(data.practicStart, "HH:mm")
              : null);
            const practicEnd = session.category === "Lab" ? null : (data.practicEnd
              ? moment(data.practicEnd, "HH:mm")
              : null);
            const labStart = session.category === "Lab" ? (data.labStart
              ? moment(data.labStart, "HH:mm")
              : null) : null;
            const labEnd = session.category === "Lab" ? (data.labStart
              ? moment(data.labEnd, "HH:mm")
              : null) : null;

            const theoricHoursDifference =
              theoricStart && theoricEnd
                ? timeDifferences(theoricEnd, theoricStart) / 60
                : 0;
            const practicHoursDifference =
              practicStart && practicEnd
                ? timeDifferences(practicEnd, practicStart) / 60
                : 0;
            const labHoursDifference =
              labStart && labEnd
                ? timeDifferences(labEnd, labStart) / 60
                : 0;

            sessionHoursDifference +=
              theoricHoursDifference + practicHoursDifference + labHoursDifference;
          }
        });
        totalHoursDifference += sessionHoursDifference;
      });
      if (totalHoursDifference === totalHours) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [sessionsData]);

  const timeDifferences = (end: moment.Moment, start: moment.Moment) => {
    return moment.duration(end.diff(start)).asMinutes();
  };

  const handleClick = () => {
    setLoading(true);
    const newData: PostClassSessions = {
      classId: sessionsData.classId,
      sessions: sessionsData.sessions.reduce((filteredSessions, session) => {
        const theoricSession = {
          dayOfWeek: session.dayOfWeek,
          category: "Theoric",
          start: session.theoricStart,
          end: session.theoricEnd,
        };
        const practiceSession = {
          dayOfWeek: session.dayOfWeek,
          category: "Practice",
          start: session.practicStart,
          end: session.practicEnd,
        };
        const labSession = {
          dayOfWeek: session.dayOfWeek,
          category: "Lab",
          start: session.labStart,
          end: session.labEnd,
        };

        if (session.theoricStart !== "" && session.theoricEnd !== "") {
          filteredSessions.push(theoricSession);
        }

        if (session.practicStart !== "" && session.practicEnd !== "") {
          filteredSessions.push(practiceSession);
        }

        if (session.labStart !== "" && session.labEnd !== "") {
          filteredSessions.push(labSession);
        }

        return filteredSessions;
      }, [] as PostSession[]),
    };



    ApiInstance.post(`/api/classsessions/`, newData)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Sessiya təyin edildi",
          severity: "success" as AlertColor,
        });
        setTimeout(() => {
          navigate(`${APP_PREFIX_PATH}/groups`);
        }, 1500);
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Sessiya təyin edilmədi",
          severity: "error" as AlertColor,
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewRow = (category: string) => {
    let daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const totalHours = Array.from({ length: 10 }, (_, i) => i + 1);

    if (category === "Day") {
      sessions.forEach((session) => {
        if (daysOfWeek.includes(session.dayOfWeek)) {
          daysOfWeek = daysOfWeek.filter((day) => day !== session.dayOfWeek);
        }
      });
    }

    const newSession = {
      className: sessions[0].className,
      dayOfWeek: daysOfWeek[0],
      endTime: "",
      id: uuid(),
      startTime: "",
      totalHours: totalHours[0],
      days: daysOfWeek,
      hours: totalHours,
      category: category
    };

    setSessions((prev) => {
      return [...prev, newSession];
    });
    if(category==="Lab"){
      setDisableButtonLab(true)
    }else{
      setDisableButton(true);
    }
  };

  return (
    <>
      {sessions.length > 0 && (
        <PageHeader>
          <Box>
            {/* <Breadcrumbs>
              <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
              <BreadcrumbsLink
                name="Sessiya təyin et"
                url={`${APP_PREFIX_PATH}/classes`}
              />
              <BreadcrumbsTypography name={sessions[0].className} />
            </Breadcrumbs> */}
            <PageTitle name={sessions[0].className} />
          </Box>
          <Stack direction={"row"} gap="16px">
            <Button onClick={() => addNewRow("Lab")} disabled={disableButtonLab}>
              Lab əlavə et
            </Button>
            <Button onClick={() => addNewRow("Day")} disabled={disableButton}>
              Gün əlavə et
            </Button>
            <Button
              disabled={status ? false : true}
              onClick={() => handleClick()}
            >
              Yadda saxla
            </Button>
          </Stack>
        </PageHeader>
      )}
    </>
  );
}

export default Header;
