import IconButton from '@mui/material/IconButton';
import { GridColDef } from "@mui/x-data-grid";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Link } from 'react-router-dom';

export const tableHeaders : GridColDef[] = [
    {
        field: "class",
        headerName: "Qrup",
        flex: 1,
        valueGetter: (params) => params.row?.class.name,
    },
    {
        field: "studentsCount",
        headerName: "Tələbə sayı",
        flex: 1,
    },
    {
        field: "attendancePercentage",
        headerName: "Davamiyyət faizi",
        flex: 1,
    },
    {
        field: "unMarkDays",
        headerName: "Yazılmamış gün",
        flex: 1,
    },
    {
        field: "",
        headerName: "Əməliyyat",
        flex: 1,
        renderCell: (params) => {
            return (
                <Link to={`/admin/app/classes/${params.row.class.id}/class-sessions/${params.row.lastDate}`}>
                    <IconButton size='small'>
                      <DriveFileRenameOutlineIcon/>
                    </IconButton>
                </Link>
            );
          },
    },
]