import { ApiInstance } from "../core/apiInstance";
import { ResponseModel } from "../../models/core/ResponseModel";

export class BaseService<T> {
  private endpointUrl: string = "";

  constructor(url: string) {
    this.endpointUrl = url;
  }

  async getAll(url: string = this.endpointUrl): Promise<ResponseModel> {
    try {
      let apiResponse = await ApiInstance.get(url);
      let response: ResponseModel = {
        data: apiResponse.data, 
        status: true,
        statusCode: apiResponse.status,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      throw response;
    }
  }

  async getById(
    id: string,
    url: string = `${this.endpointUrl}/${id}`
  ): Promise<ResponseModel> {
    try {
      let apiResponse = await ApiInstance.get(url);
      
      let response: ResponseModel = {
        data: apiResponse.data,
        status: true,
        statusCode: apiResponse.status,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };

      throw response;
    }
  }

  async add(data: T, url: string = this.endpointUrl): Promise<ResponseModel> {
    try {
      const apiResponse = await ApiInstance.post(url, data);
      
      let response: ResponseModel = {
        data: data,
        status: true,
        statusCode: 200,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      throw response;
    }
  }

  async addForm(data: T, url: string = this.endpointUrl): Promise<ResponseModel> {
    try {
      const form = new FormData();
      for(let key in data){
        if(data[key] == undefined){
          continue;
        }
        if(key === 'images'){
          for(let imgKey in data[key]){
            if(!isNaN(+imgKey)){
              form.append(key, (data as any).images[imgKey])
            }
          }
        }else{
          form.append(key, data[key] as any)
        }
      }
      const apiResponse = await ApiInstance.post(url, form);
      let response: ResponseModel = {
        data: data,
        status: true,
        statusCode: 200,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      throw response;
    }
  }

  async update(
    data: T,
    url: string = this.endpointUrl
  ): Promise<ResponseModel> {
    try {
      const apiResponse = await ApiInstance.put(url, data);
      
      let response: ResponseModel = {
        data: data,
        status: true,
        statusCode: 200,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      throw response;
    }
  }

  async delete(
    id: string,
    url: string = `${this.endpointUrl}/${id}`
  ): Promise<ResponseModel> {
    try {
      const apiResponse = await ApiInstance.delete(url);
      
      let response: ResponseModel = {
        data: {},
        status: true,
        statusCode: 200,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      throw response;
    }
  }

  async cancel(
    data: T,
    url: string = this.endpointUrl
  ): Promise<ResponseModel> {
    try {
      const apiResponse = await ApiInstance.post(url, data);
      
      let response: ResponseModel = {
        data: data,
        status: true,
        statusCode: 200,
        errorMessage: "",
      };
      return response;
    } catch (error: any) {
      if(error?.response?.status === 403){
        window.location.href = '/403';
      }
      let response: ResponseModel = {
        data: {},
        status: false,
        statusCode: error.response.status,
        errorMessage: error.message,
      };
      throw response;
    }
  }
}
