import { Box, Typography } from "@mui/material";
import { sxContainer, sxLabTitle } from "./style";
interface InfoBannerModel {
  setIsLessonsModalOpen:React.Dispatch<React.SetStateAction<boolean>>;
}
function InfoBanner(props:InfoBannerModel) {
  const {setIsLessonsModalOpen}=props
  return (
    <Box sx={sxContainer}>
      <Box display="flex" alignItems="flex-end" gap="24px">
        <Box>
          <img src="/icons/evenodd.svg" />
        </Box>
        <Box sx={sxLabTitle}>
          Salam,
          <Typography
            sx={{ fontSize: "34px", fontFamily: "Visby CF Bold, sans-serif" }}
          >
            bugün Lab day’dir?
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: "320px",
          top: "-90px",
        }}
      >
        <img src="/icons/arrow07.svg" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems='flex-start' gap='8px'>
        <Typography sx={{width:"223px",fontFamily:"Visby CF Medium",color:"#1F1C1D",fontSize:"14px",lineHeight:"20px",letterSpacing:"0.4px"}}>
          Necə qeyd edəcəyini bilirsən? Əgər bilmirsənsə yuxarıdakı videonu izlə
          və ya
        </Typography>
        <Typography onClick={()=>setIsLessonsModalOpen((oldValue)=>!oldValue)}  sx={{fontFamily:"Visby CF Bold",textDecorationLine:"underline",color:"#1F1C1D",fontSize:"14px",lineHeight:"20px",letterSpacing:"0.4px",cursor:"pointer"}}>Lab günü təyin et</Typography>
      </Box>
    </Box>
  );
}

export default InfoBanner;
