import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import DataGrid from "../../../../../components/custom-components/data-grid";
import Progress from "../../../../../components/custom-components/progress";
import { RootState } from "../../../../../store/reducers";
import { fetchClasses } from "../../../../../store/reducers/classReducer";
import { Box, Typography, Button } from "@mui/material";
import Header from "./components/header";
import SearchIcon from "@mui/icons-material/Search";
import { sxColumnHeader, sxSearchIcon } from "./style";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import { fetchPrograms } from "../../../../../store/reducers/programReducer";
import { Class } from "../../../../../network/models/class/Class";
import { localeText } from "../../../../../configs/Locales";

function ClassList() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [program, setProgram] = useState<string>("");
  const [programsList, setProgramsList] = useState<string[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const classesData = useSelector((state: RootState) => state.class.classes);
  const statusClasses = useSelector((state: RootState) => state.class.status);
  const programs = useSelector((state: RootState) => state.program.programs);
  const statusPrograms = useSelector(
    (state: RootState) => state.program.status
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    dispatch(fetchClasses());
    dispatch(fetchPrograms());
    setProgram("Bütün proqramlar");
  }, []);

  useEffect(() => {
    let list = ["Bütün proqramlar"];
    const programNames = list.concat(programs.map((program) => program.name));
    programNames.sort(function (a, b) {
      if (a === "Bütün proqramlar") {
        return -1;
      } else if (b === "Bütün proqramlar") {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });

    setProgramsList(programNames);
  }, [programs]);

  useEffect(() => {
    if (program === "" || program === "Bütün proqramlar") {
      setClasses(
        classesData.filter((c) =>
          c.className.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setClasses(
        classesData
          .filter((q) => q.programName === program)
          .filter((c) =>
            c.className.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
    }
  }, [program, classesData, searchTerm]);
  useEffect(() => {
    if (classes.length === 0) {
      setClasses(classesData);
    }
  }, [classes, classesData]);

  if (statusPrograms === "loading" || statusClasses === "loading") {
    return <Progress />;
  }

  const columns: GridColDef[] = [
    {
      field: "className",
      headerName: "Qrupun adı",
      flex: 0.5,
    },
    {
      field: "sessionName",
      headerName: "Sessiya",
      flex: 0.7,
    },
    {
      field: "startDate",
      headerName: "Başlanğıc tarixi",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.value.split("T")[0]}
          </Typography>
        );
      },
    },
    {
      field: "programName",
      headerName: "Proqramın adı",
      flex: 0.7,
    },
    {
      field: "operation",
      headerName: "",
      flex: 0.5,
      align: "left",
      headerAlign: "left",
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                padding: "0px 10px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
                to={`${APP_PREFIX_PATH}/classes/${params.id}`}
              >
                Sessiya
              </Link>
            </Button>

            <Button
              variant="contained"
              sx={{
                padding: "0px 10px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
                to={`${APP_PREFIX_PATH}/classes/${params.id}/class-sessions`}
              >
                Davamiyyət
              </Link>
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
          <Header
            programs={programsList}
            program={program}
            setProgram={setProgram}
          />

          {classes.length > 0 && (
            <Box sx={{ width: "100%", height: "auto" }}>
              <DataGrid
                data={classes}
                columnsData={columns}
                pageSizeData={pageSize}
                setPageSize={setPageSize}
                disableColumnMenu={false}
                localeText={localeText}
                components={{
                  Toolbar: () => (
                    <TextField
                      autoFocus
                      id="standard-basic"
                      variant="standard"
                      placeholder="Qrup axtar.."
                      value={searchTerm}
                      onChange={(e) =>
                        setSearchTerm(e.target.value.trimStart())
                      }
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ),
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                  pagination: {
                    // Update the label for rows per page
                    labelRowsPerPage: "Səhifədəki sətir sayı:",
                  },
                }}
              />
            </Box>
          )}
    </>
  );
}

export default ClassList;
