export const sxFilter:React.CSSProperties={
    width:"100%"
}

export const sxDrawer={
    "& .MuiDrawer-paperAnchorRight":{
        width:"33%",
        padding:"32px"
    }
}

export const sxFilterPart:React.CSSProperties={
    marginTop:"32px"
}

export const sxFooterFilter:React.CSSProperties={
    display:"flex",
    gap:"16px",
    position:"fixed",
    bottom:"32px"
}