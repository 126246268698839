import { Box} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';


function Footer() {
    return (
        <Box
            sx={{
                background: "#EBECF1",
                borderRadius: "4px",
                alignItems: "center"
            }}
            display={"flex"}
            padding={"14px 16px"}
        >
            <WarningAmberIcon sx={{ color: "#ACB1C0" }} />
            <Typography
                paragraph={true}
                sx={{
                    margin: "0px 0px 0px 12px",
                    color: "#4F5259",
                    fontSize: "14px"
                }}
            >Diqqət! Modulun saatların cəmi ümumi dərs saatına bərabər olmalıdır.</Typography>
        </Box>
    )
}

export default Footer