import { GridColDef } from "@mui/x-data-grid";
import { Typography, Box} from "@mui/material";
import { ProgressPercentage } from "../../../../../components/custom-components/progress-percentage";
import {gridNumberComparator} from '@mui/x-data-grid'

const titleSorting = (a: any, b: any) => {
  return a - b;
}

export const tabColumns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "№",
      width: 40,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.rowNumber}
          </Typography>
        );
      },
    },
    {
      field: "StudentName",
      headerName: "Tələbə adı",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.name} {params.row.surname}
          </Typography>
        );
      },
      valueGetter: (params) => {
        return params.row.name;
      },
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.email ? params.row.email : "-"}
          </Typography>
        );
      },
    },
    {
      field: "Number",
      headerName: "Telefon nömrəsi",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.phoneNumber ? params.row.phoneNumber : "-"}
          </Typography>
        );
      },
    },
    {
      field: "attendance",
      headerName: "Davamiyyət faizi",
      flex: 0.8,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              fontFamily: "Visby CF Bold,sans-serif",
            }}
          >
            <>
              <ProgressPercentage value={Math.round(params.row.attendance)} />
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Visby CF Bold,sans-serif",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                }}
              >
                {Math.round(params.row.attendance)}%
              </Typography>
            </>
          </Box>
        );
      },
    },
  ];