// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_overlay__GkRMg{
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.49);
    position: absolute;
    left: 0;
    top: 0;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}

.style_imgWrapper__vZCd0:hover .style_overlay__GkRMg{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/app-views/SupportManagement/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".overlay{\n    display: none;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.49);\n    position: absolute;\n    left: 0;\n    top: 0;\n    /* display: flex; */\n    justify-content: center;\n    align-items: center;\n    border-radius: 30px;\n    cursor: pointer;\n}\n\n.imgWrapper:hover .overlay{\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `style_overlay__GkRMg`,
	"imgWrapper": `style_imgWrapper__vZCd0`
};
export default ___CSS_LOADER_EXPORT___;
