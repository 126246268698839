import { Box } from '@mui/material'
import React from 'react'
import MaterialBreadcrumbsComponent from '@mui/material/Breadcrumbs';
import { BoxProps } from '@mui/material/Box';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';



function Breadcrumbs(props: BoxProps) {
    return (
        <Box sx={{ marginBottom: "12px" }} role="presentation" >
            <MaterialBreadcrumbsComponent
                separator={
                    <KeyboardDoubleArrowRightOutlinedIcon
                        sx={{ width: "20px", height: "20px" }}
                        color="primary"
                    />
                }
                sx={{ fontSize: "14px" }}
            >
                {props.children}
            </MaterialBreadcrumbsComponent>
        </Box>
    )
}

export default Breadcrumbs