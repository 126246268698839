import React from "react";
import Header from "./components/Header";
import AddForm from "./components/AddForm";
function WorkersAdd() {
  return (
    <>
        <Header />
        <AddForm />
    </>
  );
}

export default WorkersAdd;
