import { Box } from "@mui/material";
import React from "react";
import {
  sxMenuElementParent,
  sxMenuElement,
  sxElementLeftSide,
  sxElementName,
  sxRenctangle,
  sxArrow,
} from "./style";
import BaseIcon from "../../../../custom-components/base-icon";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CollapseElementModel } from "../menu-elements/models/CollapseElement";
import { UserHasPermission } from "../../../../../utils/hooks/userPermissions";

interface PropsModel {
  open: boolean;
  menuElement: string;
  menuCollapseElements: CollapseElementModel[];
  menuIcon: React.ReactNode;
  element: string;
  setElement: React.Dispatch<React.SetStateAction<string>>;
  handleClick: (
    elemen: string,
    collapseElement: CollapseElementModel[]
  ) => void;
  hover: boolean;
}

function MenuElement(props: PropsModel) {
  const {
    element,
    menuElement,
    open,
    setElement,
    handleClick,
    menuCollapseElements,
    menuIcon,
    hover,
  } = props;

  let menuCollapseElementsWithroles: CollapseElementModel[] = [];

  menuCollapseElements.forEach((element) => {
    if(UserHasPermission(element.permissions)){
      menuCollapseElementsWithroles.push(element);
    }
  });

  return (
    <>
      <Box sx={sxMenuElementParent}>
        <Box sx={sxMenuElement}>
          <Box
            sx={sxElementLeftSide}
            width="100%"
            onClick={() => {
              element === menuElement
                ? setElement("")
                : handleClick(menuElement, menuCollapseElementsWithroles);
            }}
          >
            <Box sx={sxElementLeftSide}>
              <BaseIcon>{menuIcon}</BaseIcon>

              {open && (
                <Typography
                  variant="h2"
                  sx={[
                    sxElementName,
                    {
                      fontFamily:
                        element === menuElement
                          ? "Visby CF Bold, sans-serif"
                          : "Visby CF DemiBold, sans-serif",
                    },
                  ]}
                >
                  {menuElement}
                </Typography>
              )}
            </Box>
            {open && menuCollapseElementsWithroles.length > 0 && (
              <KeyboardArrowDownOutlinedIcon
                color="primary"
                sx={[
                  sxArrow,
                  {
                    transform:
                      element === menuElement
                        ? "rotate(0deg)"
                        : "rotate(90deg)",
                  },
                ]}
              />
            )}
          </Box>
          <Box
            sx={sxRenctangle}
            style={{ width: element === menuElement || hover ? "8px" : "0" }}
          ></Box>
        </Box>
      </Box>
    </>
  );
}

export default MenuElement;
