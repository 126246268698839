import * as yup from "yup";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/


export const supportCreateSchema = yup.object().shape({
    title: yup.string().required("Boş ola bilməz"),
    description: yup.string().required("Boş ola bilməz"),
    phoneNumber: yup.string().required("Boş ola bilməz").matches(phoneRegExp, 'Phone number is not valid').min(10),
    categoryId: yup.object().required("Boş ola bilməz"),
    classId: yup.object().when('categoryId', {is: (val: any)=> {return val?.id != 1} , then: (s) => s.required()}),
  });

  export const supportUpdateSchema = yup.object().shape({
    status: yup.string().required("Boş ola bilməz"),
  });