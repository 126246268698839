import { SxProps } from '@mui/material';

export const datagridSx: SxProps = {
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    ".css-1jbbcbn-MuiDataGrid-columnHeaderTitle,.programName-cell": {
        fontWeight: "600"
    },
    '&.MuiDataGrid-root': {
        border: 'none',
    },
    ".MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus": {
        outline: "none"
    },
    '& .MuiDataGrid-cell': {
        alignItems: "flex-start"
    },
    ".column-operation-grid,.row-operation-grid": {
        paddingRight: "45px",
        display: 'flex'
    },
    ".MuiBox-root":{
        overflow: "hidden",
        whiteSpace: "nowrap",
    }
}

export const detailStyles: React.CSSProperties = {
    height: "48px",
    display: "flex",
    alignItems: "center"
}