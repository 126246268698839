export const sxContainer = {
    display: "flex",
    width: "177px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "9px",
    background: "var(--Other-White, #FFF)",
    boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
    transition: "0.5s ease all",
    position: "absolute",
    zIndex: 9999,
  };