import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { StudentHeaderDetail } from "../../models/StudentDetail";
import moment from "moment";
interface PropsModel {
  children: React.ReactNode;
  tittle: string;
  description?: string;
  studentsHeaderDetail?: StudentHeaderDetail | undefined;
}
function ModalHeader(props: PropsModel) {
  const startDate = props.studentsHeaderDetail?.startDate;
  const endDate = props.studentsHeaderDetail?.endDate;
  const formattedDateRange =
    startDate && endDate
      ? `${moment(startDate).format("DD MMMM YYYY")} - ${moment(endDate).format(
          "DD MMMM YYYY"
        )}`
      : "mövcüd bir tarix aralığı yoxdur";
  return (
    <Box
      display="flex"
      width="width: 1240px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="16px"
      >
        <Box display="flex" alignItems="center" gap="16px ">
          <Typography variant="h4" fontFamily="Visby CF Bold, sans-serif">
            {" "}
            {props.tittle}
          </Typography>
          <Chip
            label={props.studentsHeaderDetail?.program.name!}
            sx={{
              background: "#468CBC",
              color: "#FFFFFF",
              letterSpacing: "0.4px",
              "& .css-6od3lo-MuiChip-label": {
                fontFamily: "VVisby CF Medium, sans-serif",
                fontWeight: "500",
                fontSize: "14px",
              },
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" gap="8px">
          <CalendarTodayOutlinedIcon
            sx={{ color: "#468CBC", height: "24px", width: "24px" }}
          />
          <Typography
            color="#0E0D0D"
            fontFamily="Visby CF DemiBold, sans-serif"
            lineHeight="24px"
            letterSpacing="0.4px"
            fontSize="14px"
          >
            {formattedDateRange}
          </Typography>

          <Chip
            label={props.studentsHeaderDetail?.session.name!}
            sx={{
              background: "#E9E9E9",
              color: "#0E0D0D",
              letterSpacing: "0.4px",
              height: "24px",
              "& .css-6od3lo-MuiChip-label": {
                fontFamily: "Visby CF DemiBold, sans-serif",
              },
            }}
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap="16px">
        {props.children}
      </Box>
    </Box>
  );
}

export default ModalHeader;
