import React, { useEffect, useState } from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Box, Typography, Tab, Tabs,Link } from "@mui/material";
import { sxBtn } from "../style";
import { ModelSkeleton } from "../components/model-skeleton";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { sxTab } from "../components/tab-header/style";
import {
  StudentTableDetail,
  StudentHeaderDetail,
} from "../models/StudentDetail";
import DataGrid from "../../../../../components/custom-components/data-grid";
import { Kpi } from "../components/kpi";
import { API_URL } from "../../../../../configs/Appconfig";
import { ModalState } from "../set-program-detail/models/ModalState";
import Attendance from './attendance';
import './style.css';
import AttendanceGraph from "./attendanceGraph";
import {tabColumns} from './tableColumns';
interface PropsModel {
  studentsTableDetail: StudentTableDetail[];
  studentsHeaderDetail: StudentHeaderDetail | undefined;
  modalIsLoading: boolean;
  classId: any;
  onClose: () => void;
  handleModalState:(state:ModalState)=>void
}
function ClassDetailModal(props: PropsModel) {
  const {
    handleModalState,
    studentsTableDetail,
    studentsHeaderDetail,
    classId,
    modalIsLoading,
    onClose,
  } = props;
  const [studentsProgres, setStudentProgres] = useState(0);

  const [isGraph, setIsGraph] = useState<boolean>(false);
  
  const startDate = studentsHeaderDetail?.startDate;
  const endDate = studentsHeaderDetail?.endDate;
  const formattedDateRange =
    startDate && endDate
      ? `${moment(startDate).format("DD MMMM YYYY")} - ${moment(endDate).format(
          "DD MMMM YYYY"
        )}`
      : "mövcüd bir tarix aralığı yoxdur";

  return (
    <>
      <Box
        sx={{
          height: "89%",
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        {modalIsLoading ? (
          <ModelSkeleton />
        ) : (
          <>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              flex-start
              marginBottom="10px"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="16px"
              >
                <Box display="flex" alignItems="center" gap="16px ">
                  <Typography
                    variant="h4"
                    fontFamily="Visby CF Bold, sans-serif"
                  >
                    {" "}
                    {studentsHeaderDetail?.name}
                  </Typography>
                  <Chip
                    label={studentsHeaderDetail?.program.name}
                    sx={{
                      background: "#468CBC",
                      color: "#FFFFFF",
                      letterSpacing: "0.4px",
                      "& .css-6od3lo-MuiChip-label": {
                        fontFamily: "VVisby CF Medium, sans-serif",
                        fontWeight: "500",
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                  <CalendarTodayOutlinedIcon
                    sx={{ color: "#468CBC", height: "24px", width: "24px" }}
                  />
                  <Typography
                    color="#0E0D0D"
                    fontFamily="Visby CF DemiBold, sans-serif"
                    lineHeight="24px"
                    letterSpacing="0.4px"
                    fontSize="14px"
                  >
                    {formattedDateRange}
                  </Typography>

                  <Chip
                    label={studentsHeaderDetail?.session.name}
                    sx={{
                      background: "#E9E9E9",
                      color: "#0E0D0D",
                      letterSpacing: "0.4px",
                      height: "24px",
                      "& .css-6od3lo-MuiChip-label": {
                        fontFamily: "Visby CF DemiBold, sans-serif",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box display="flex" gap="16px">
                <Kpi
                  value={
                    studentsHeaderDetail?.attendanceRate
                      ? studentsHeaderDetail?.attendanceRate
                      : 0
                  }
                  color="#4BA458"
                  tagTitle="Davamiyyət göstəricisi"
                  iconColor="#C7E3CB"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              height="32px"
              justifyContent="space-between"
              alignItems="center"
              alignSelf="stretch"
            >
              <Tabs value={studentsProgres} sx={{ height: "40px" }}>
                <Tab sx={sxTab} label="Tələbələr" onClick={()=>  setStudentProgres(0)} />
                <Tab sx={sxTab} label="Davamiyyət göstəricisi" onClick={()=>  setStudentProgres(1)} />
              </Tabs>
              <Box display="flex" alignItems="flex-start" gap="8px">
                <FileDownloadOutlinedIcon
                  sx={{ width: "24px", height: "24px", color: "#854693" }}
                />
                <Link
                  target="_blank"
                  href={`${API_URL}api/classes/${classId}/export/excel`}
                  sx={{
                    padding: "0",
                    color: "#000",
                    fontFamily: "Visby CF Bold, sans-serif",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    textDecorationLine: "underline",
                    textTransform: "initial",
                    marginRight: 3
                  }}
                >
                  Excel kimi yüklə
                </Link>
              </Box>
            </Box>
            {
              studentsProgres === 0 ? (
                <Box className="hideScrollBar" width="100%" sx={{overflowY: 'scroll'}}>
                  <DataGrid
                    data={
                      studentsTableDetail.map((row, index) => ({
                        ...row,
                        rowNumber: index + 1,
                      })) ?? []
                    }
                    columnsData={tabColumns}
                    pageSizeData={18}
                    setPageSize ={()=> {}}
                    disableColumnMenu={true}
                  />
                </Box>
              ) : studentsProgres === 1 ? 
                !isGraph ? (
                  <>
                    <Attendance classId={classId}/>
                  </>
              ) : <AttendanceGraph classId={classId}/> : null
            }
            
            <Box
              style={{
                display: "flex",
                gap: "16px",
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#ffffff",
                padding: "16px 0px",
              }}
            >
              <Button onClick={()=>handleModalState(ModalState.Program)} variant="contained" color="primary" sx={sxBtn}>
                Proqramı tənzimlə
              </Button>
              {/* <Button onClick={()=>handleModalState(ModalState.Session)} sx={[{ background: "#F3EDF4", color: "#0E0D0D" }, sxBtn]}>
                Seansı tənzimlə
              </Button> */}
              <Button onClick={onClose} sx={[{ color: "#0E0D0D" }, sxBtn]}>
                Bağla
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default ClassDetailModal;
