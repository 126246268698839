import React, { useCallback, useRef, useState } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

import "./OtpInput.css";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";
interface OtpInputProps {
  id: string;
  label: string;
  onChange: (value: string) => void;
}
function Otp() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const content = {
    mainTitle: " Yaxşı, gəl",
    boldTitle: "şifrəni dəyişdirək.",
    subtitle: "emailinə göndərilmiş 4 rəqəmli kod lazımdır.",
    boldSubtitle: " ulviyya.imamova@code.edu.az",
  };
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleChange = (index: number, value: string, e: any) => {
    if (/^\d*$/.test(value)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      if (value) {
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]!.focus();
        }
      } else if (e.keyCode === 8 || e.key === "Backspace") {
        if (inputRefs.current[index - 1]) {
          inputRefs.current[index - 1]!.focus();
        }
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="left"
        justifyContent="center"
        mx={10}
        sx={{ height: "100%" }}
        pr={isLargeScreen ? 20 : 0}
      >
        <Title content={content} />
        <Box py={2}>
          <div className="otp-group">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                pattern="\d{1}"
                placeholder="•"
                maxLength={1}
                className="otp-input"
                value={value}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleChange(index, e.target.value, e)}
                onKeyDown={(e) => handleChange(index, value, e)}
              />
            ))}
          </div>
        </Box>
        <Box display="flex" gap={2} py={6}>
          <Button
            sx={{
              borderRadius: "12px",
              backgroundColor: "#4BA458",
              textTransform: "none",
              marginTop: "20px",
              padding: "12px 24px",
            }}
            type="submit"
            variant="contained"
            onClick={() => {
              navigate("/password");
            }}
            color="success"
          >
            Davam et
          </Button>
          <Button
            sx={{
              borderRadius: "12px",
              padding: "12px 24px",
              backgroundColor: "#EDF6EE",
              color: "black",
              textTransform: "none",
              marginTop: "20px",
              "&:hover": {
                backgroundColor: "#EDF6EE",
              },
            }}
            type="submit"
            variant="contained"
          >
            Ləğv et
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Otp;
