export const sxTableCell: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
    color: "#000000",
    whiteSpace: 'nowrap',
    maxWidth: 0
}

export const sxGroupName: React.CSSProperties = {
    display: "flex",
    alignItems: "center"
}

export const sxTimePicker = {
    "& .MuiInputBase-root": {
        fontSize: "14px",
    }
}

export const sxSelect = {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
        padding: "0",
        fontSize: "14px"
    },
    '& .MuiOutlinedInput-notchedOutline':
        { border: "none" },
}

export const sxDeleteIcon={
    cursor:"pointer",
    transition: "0.5s ease all",
    "&:hover": {
      color: "primary.main",
    },
}