import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

interface PropsModel {
    size?: number
}

function Progress(props: PropsModel) {
    const { size } = props
    return (
        <>
            {size ? <CircularProgress
                sx={{ display: "flex", justifyContent: "center" }}
                size={size} /> : (
                <Box sx={{ display: "flex", justifyContent: "center", margin: "50px 0px" }}>
                    <CircularProgress />
                </Box>
            )}

        </>
    )
}

export default Progress