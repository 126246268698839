import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography, TextField } from "@mui/material";
import { User } from "../../user-list/models";
import AvatarChips from "../avatar-chips";
import { Worker } from "../../../models";
import { BaseService } from "../../../../../../network/services/base/BaseService";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
interface AddUserToGroupProps {
  users: Worker[];
  getGroupsService?: () => void;
  open: boolean;
  setAddShowAction: (value: boolean) => void;
  groupId: number;
  isFromDetail?: boolean;
  getGroupDetailsService?: () => void;
}
function AddUserToGroupModal(props: AddUserToGroupProps) {
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const { users,open,groupId, setAddShowAction,getGroupsService,getGroupDetailsService,isFromDetail } = props;
  const handleClose = (e: any) => {
    setAddShowAction(false);
    setSelectedUsers([]);
  };
  const AddUserToGroupsService = new BaseService(`api/PermissionGroups/${groupId}/add-worker`);
  const postAddUserToGroupHandler= () => {
    AddUserToGroupsService.add({
        workerIds: selectedUsers.map((user) => user.id),
    })
      .then((res) => {
        getGroupsService && getGroupsService();
        getGroupDetailsService && getGroupDetailsService();
        // isFromDetail && navigate(`${APP_PREFIX_PATH}/permissions`);
        setSelectedUsers([]);
        setAddShowAction(false)
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            display: "flex",
            width: "600px",
            padding: "32px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "32px",
            backgroundColor: "#FFF",
            borderRadius: "16px",
            boxShadow: " 0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiDialogTitle-root": {
            display: "flex",
            width: "536px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            padding: "0",
          },
          "& .MuiDialogTitle-root .header": {
            color: "#000",
            fontFamily: "Visby CF Bold",
            fontSize: "34px",
            lineHeight: "40px",
            letterSpacing: "0.4px",
          },
          "& .MuiDialogTitle-root .description": {
            color: "#131112",
            fontFamily: "Visby CF Regular, sans-serif",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
          },
          "& .MuiDialogContent-root": {
            padding: 0,
            width: "100%",
          },

          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--Natural-natural-200, #999898)",
            borderRadius: "12px",
          },
          "& .MuiInputBase-input": {
            padding: "14px 24px",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "var(--Natural-natural-300, #6B696A)",
            fontFamily: "Visby CF Medium, sans-serif",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
          },
        }}
      >
        <DialogTitle>
          <Typography className="header" variant="h1">
            Qrupa istifadəçi əlavə et
          </Typography>
          <Typography className="description">
            Qrupa əlavə etmək üçün istifadəçi adını daxil edin.
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Visby CF Bold, sans-serif",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                }}
              >
                İstifadəçilər
              </Typography>
              <Box
                display="flex"
                alignItems="flex-start"
                alignContent="flex-start"
                gap="12px"
                flexWrap="wrap"
              >
                {selectedUsers.map((user) => (
                  <AvatarChips
                    key={user.id}
                    label={`${user.name} ${user.surname}`}
                    onDelete={() => {
                      setSelectedUsers(
                        selectedUsers.filter(
                          (selectedUser) => selectedUser.id !== user.id
                        )
                      );
                    }}
                  />
                ))}
              </Box>
              <Autocomplete
                ListboxProps={{
                  style: {
                    maxHeight: "150px",
                  },
                }}
                fullWidth
                id="combo-box-demo"
                getOptionLabel={(option) => `${option.name} ${option.surname}`}
                options={users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="İstifadəçi axtar"
                    id="fullWidth"
                  />
                )}
                onChange={(e, value) => {
                  if (!value) return;
                  setSelectedUsers([...selectedUsers, value as User]);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}
            >
              <Button
                onClick={postAddUserToGroupHandler}
                className="saveBtn"
                sx={{
                  background: "#854693",
                  color: "#FFF",
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: "12px 24px",
                  borderRadius: "12px",
                  fontFamily: "Visby CF Bold, sans-serif",
                  textTransform: "none",
                  "&:hover": {
                    background: "#6e3d7d",
                  },
                }}
              >
                Yadda saxla
              </Button>
              <Button
                sx={{
                  background: "#F3EDF4",
                  color: "#0E0D0D",
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: "12px 24px",
                  borderRadius: "12px",
                  fontFamily: "Visby CF Bold, sans-serif",
                  textTransform: "none",
                  "&:hover": {
                    background: "#D9C6DE",
                  },
                }}
                onClick={handleClose}
              >
                Ləğv et
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddUserToGroupModal;
