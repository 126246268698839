import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

const MaterialAlertComponent = React.forwardRef<HTMLDivElement, AlertProps>(function MaterialAlertComponent(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PropsModel {
    text: string;
    severity: AlertColor;
    open: boolean;
    handleClose: () => void;
}

export default function Alert(props: PropsModel) {
    const { text, open, handleClose, severity } = props;

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
            >
                <MaterialAlertComponent
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}
                >
                    {text}
                </MaterialAlertComponent>
            </Snackbar>
        </Stack>
    );
}