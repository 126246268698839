import { Box } from '@mui/system'

function FormLayout({ children }: { children: React.ReactNode }) {
    return (
        <Box 
        sx={{
            background: "#F8F9FB",
            borderRadius: "16px",
            padding:"40px"
          }}
        >
            {children}
        </Box>
    )
}

export default FormLayout