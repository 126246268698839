import { Grid, Input, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/core-components/inputs/button";
import { Button as MUIbutton } from "@mui/material";
import { sxField } from "../../../MrpManagement/mrp/components/modal/style";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Alert from "../../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import CustomAutocomplete from "../../../../../components/custom-components/custom-autocomplete";
import { useForm, SubmitHandler} from "react-hook-form";
import {ISupportCreateModel} from '../../types';
import {supportCreateSchema} from '../../validations';
import { yupResolver } from "@hookform/resolvers/yup"; 
import { useSelector } from "react-redux";
import { AuthState } from "../../../../../components/layout-components/header";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from '../../style.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateSupportList } from "../../../../../api/supports";

function SupportForm() {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector((state: AuthState) => state.auth.user);
  
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    resetField,
    reset,
    setValue
  } = useForm<ISupportCreateModel>({
    resolver: yupResolver(supportCreateSchema),
  })

  const watchImages = watch("images");
  const categoryIdWatcher = watch("categoryId");
  const mutation = useCreateSupportList((oldData: any, newData: any) => newData)
  const onSubmit: SubmitHandler<ISupportCreateModel> = async (data) =>{
    try{
      setLoading(true);
      const form = new FormData();
      form.append('title', data.title);
      form.append('description', data.description);
      if((data.classId as any)?.id){
        form.append('classId', (data.classId as any)?.id);
      }
      form.append('categoryId', (data.categoryId as any).id);
      form.append('phoneNumber', data.phoneNumber);
      for(let img in data.images){
        form.append('images', img)
      }
      const res = await mutation.mutateAsync(form)
      if(res.status == 201){
        setAlertInside({text: "Sorğu göndərildi", severity: "success"});
        reset();
      }
    }
    catch(err){
      console.error(err)
      setAlertInside({text:"Xəta baş verdi", severity: "error"})
    }
    finally{
      setAlertOpen(true);
      setLoading(false);
    }
  }


  const handleCloseAlert = (
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  
  useEffect(()=> {
    if(categoryIdWatcher && (categoryIdWatcher as any).id == 1){
      resetField('classId')
    }
  }, [categoryIdWatcher]);

  const removeImage = (key: number) => {
    delete watchImages[key]
    setValue('images', watchImages)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
      <Grid container spacing={4}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Başlıq"
                  sx={{...sxField, width: '100%'}}
                  error={errors.title?.message !== undefined}
                  {...register("title")}
                />
                
              </Grid>
              <Grid item xs={3}>
                <CustomAutocomplete control={control} selectedProp="categoryId" label="Səbəb" url="Supports/Categories" propName='name' sx={{ "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    padding: "5px"
                }, ...sxField}}
                {...register("categoryId")}
                errors={errors.categoryId?.message !== undefined} />
              </Grid>
              <Grid item xs={3}>
                <CustomAutocomplete control={control} noOptionText={'Sizin qrupunuz yoxdu'} selectedProp="classId" label="Qrup" url={`workers/login/classes`} propName='name' sx={{ "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    padding: "5px"
                }, ...sxField}}
                {...register("classId")}
                disable={!!(categoryIdWatcher && (categoryIdWatcher as any).id == 1)}
                errors={errors.classId?.message !== undefined} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Əlaqə"
                  error={errors.phoneNumber?.message !== undefined}
                  sx={{...sxField, width: '100%'}}
                  {...register("phoneNumber")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Açıqlama"
                  multiline
                  sx={{...sxField, width: '100%', mt:3}}
                  error={errors.description?.message !== undefined}
                  rows={4}
                  {...register("description")}
                />
            </Grid>
        </Grid>
        <Grid xs={12} item>
          <div
            style={{
              gap: "15px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "start",
            }}
          >
            <div>
              {" "}
              <Input
                type="file"
                id="file"
                style={{ display: "none" }}
                inputProps={{ multiple: true, accept: "image/*" }}
                {...register("images",{
                  onChange: (e)=> {
                    const newImages = {...watchImages};
                    const count = Object.keys(watchImages).length;
                    let length = Object.keys(watchImages)[count-1] == undefined ? 0 : +Object.keys(watchImages)[count-1]
                    Array.from(e.target.files).forEach((elem, index)=> {
                      newImages[(++length) + index] = elem;
                    })
                    setValue("images", {...newImages})
                  } 
                })}
              />
              <label htmlFor="file">
                <MUIbutton
                  sx={{
                    fontFamily: 'Visby CF Bold, sans-serif',
                    padding: "8px 24px",
                    borderRadius: "12px",
                    fontSize: "14px",
                    textTransform: "none",
                    minWidth: "150px",
                    maxWidth: "200px",
                    boxShadow: "none",
                  }}
                  variant={"contained"}
                  component="span"
                  startIcon={<AddAPhotoIcon />}
                >
                  Fayl əlavə et
                </MUIbutton>
              </label>
              
            </div>
              <Button type="submit" disabled={loading}>Göndər</Button>
          </div>
          <div>
                {
                  watchImages && (
                    <ImageList sx={{ maxWidth: 1000, overflowY: 'hidden'}} cols={3}>
                      {
                        Object.keys(watchImages).map((key: any, index: number)=> (
                          <ImageListItem className={styles.imgWrapper} key={index} sx={{cursor: 'pointer', position: 'relative', mr: 2, height: '100px'}}>
                            <span className={styles.overlay}>
                              <CloseIcon sx={{color: 'white'}} onClick={()=> removeImage(key)}/>
                            </span>
                            <img
                              style={{borderRadius: '30px', height: '150px'}}
                              src={URL.createObjectURL(watchImages[key])}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))
                      }
                    </ImageList>
                  )
                }
              </div>
        </Grid>
      </Grid>
      </form>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default SupportForm;
