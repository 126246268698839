import { SxProps } from "@mui/material";

export const datagridSx: SxProps = {
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "700",
    fontFamily: "Visby CF Bold,sans-serif",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cellContent": {
    color: "#000000",
  },
  "& .MuiDataGrid-menuIcon": {
    left: "0 !important",
    right: "auto !important",
  },
};
