export const changeWeekdayEng = (weekDay: string): string => {
    switch (weekDay) {
        case "Bazar ertəsi":
            return "Monday";
        case "Çərşənbə axşamı":
            return "Tuesday";
        case "Çərşənbə":
            return "Wednesday";
        case "Cümə axşamı":
            return "Thursday";
        case "Cümə":
            return "Friday";
        case "Şənbə":
            return "Saturday";
        case "Bazar":
            return "Sunday";
        default:
            return weekDay;
    }
};