export const changeWeekday = (weekDay: string): string => {
    switch (weekDay) {
        case "Monday":
            return "Bazar ertəsi";
        case "Tuesday":
            return "Çərşənbə axşamı";
        case "Wednesday":
            return "Çərşənbə";
        case "Thursday":
            return "Cümə axşamı";
        case "Friday":
            return "Cümə";
        case "Saturday":
            return "Şənbə";
        case "Sunday":
            return "Bazar";
        default:
            return "-";
    }
};