import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Header from "./components/header";
import TabHeader from "./components/tab-haeder";
import TabPanel from "@mui/lab/TabPanel";
import GroupList from "./group-list";
import UserList from "./user-list";
import { BaseService } from "../../../../network/services/base/BaseService";
import { Group } from "./group-list/models";
import Progress from "../../../../components/custom-components/progress";
import { Worker } from "../models";
import Button from "../../../../components/core-components/inputs/button";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

function SetPermisions() {
  const [users, setUsers] = useState<Worker[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsLoading, setGroupsLoading] = useState<boolean>(true);
  const [usersLoading, setUsersLoading] = useState<boolean>(true);
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const GroupsService = new BaseService(`api/PermissionGroups`);
  const getGroupsService = async () => {
    await GroupsService.getAll()
      .then((res) => {
        setGroups(res.data);
        setGroupsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getGroupsService();
  }, []);

  const UsersService = new BaseService(`api/Users`);
  const getUsersService = async () => {
    setUsersLoading(true);
    await UsersService.getAll()
      .then((res) => {
        setUsers(res.data);
        setUsersLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUsersService();
  }, []);
  return (
    <>
      <Header>
        {value === "1" ? (
          <></>
        ) : (
          <Button>
            <PlusIcon style={{ marginRight: "8px" }} />
            İstifadəçi əlavə et
          </Button>
        )}
      </Header>
      <TabContext value={value}>
        <TabHeader handleChange={handleChange} value={value} />
        <TabPanel sx={{ padding: "30px 0px" }} value="1">
          {groupsLoading ? (
            <Progress />
          ) : (
            <GroupList
              users={users}
              groups={groups}
              getGroupsService={getGroupsService}
            />
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "30px 0px" }} value="2">
          {usersLoading ? <Progress /> : <UserList groups={groups} getUsersService={getUsersService} users={users} />}
        </TabPanel>
      </TabContext>
    </>
  );
}

export default SetPermisions;
