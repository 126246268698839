import React from 'react';

export const muiTooltipSx = {
    "& .MuiTooltip-tooltip": {
        fontSize: "10px",
        background: '#4F5259',
        padding: "4px 8px"
    }
}

export const detailStyles: React.CSSProperties = {
    height: "48px",
    display: "flex",
    alignItems: "center"
}

export const plusIcon: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "#E1E3EB",
    fontFamily: 'Visby CF Bold, sans-serif',
    cursor: "pointer",
    width: 32,
    height: 32,
    color: "#868A96"
}

export const indexNumber: React.CSSProperties = {
    fontFamily: 'Visby CF Bold, sans-serif',
    fontSize: "12px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    background: "#4F5259",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF",
    marginRight: "16px"
}

export const deleteIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "#E1E3EB",
    fontFamily: 'Visby CF Bold, sans-serif',
    width: "32px",
    height: "32px",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover .MuiSvgIcon-root": {
        color: "red !important"
    }
}

export const deleteOutlineIcon: React.CSSProperties = {
    color: "#868A96",
    width: "16px",
    transition: "0.3s ease all",
}