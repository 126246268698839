import React from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Logo from "../../../../components/layout-components/left-menu/images/Group-6.png";
// import "./../otp/Otpinput.css";
interface TitleProps {
  content: {
    mainTitle: string;
    boldTitle: string;
    subtitle: string;
    boldSubtitle: string;
  };
}
function Title({ content }: TitleProps) {
  return (
    <>
      {" "}
      {/* <Grid
        container
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="40px"
      ></Grid> */}
      <Grid container sx={{ fontSize: "48px" }}>
        <Grid mb={10} xs={12} item>
          <img src={Logo} alt="Logo" height={40} />
        </Grid>
        <Grid item>
          <Typography
            sx={{ fontSize: "48px", fontWeight: "300" }}
            component={"p"}
          >
            {content.mainTitle}
            <Typography
              sx={
                content.boldSubtitle.length !== 0
                  ? { fontSize: "48px", fontFamily: 'Visby CF Bold, sans-serif' }
                  : { fontSize: "48px", fontFamily: 'Visby CF Bold, sans-serif', display: "block" }
              }
              component="span"
            >
              {"   "}
              {content.boldTitle}
            </Typography>
          </Typography>
          {content.boldSubtitle.length > 3 ? (
            <Typography sx={{ fontWeight: "bolder" }} component="p">
              {content.boldSubtitle}
              <Typography
                sx={{ fontWeight: "400", color: "#221F20" }}
                component="span"
              >
                {"  "} {content.subtitle}
              </Typography>
            </Typography>
          ) : (
            <Typography
              sx={{ fontWeight: "400", color: "#221F20" }}
              component="span"
            >
              {content.subtitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Title;
