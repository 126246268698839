import { useEffect, useState } from "react";
import {
  AppModule,
  AppModulesGroup,
  GetPermissionAccesses,
} from "../../models";
import { ApiInstance } from "../../../../../network/services/core/apiInstance";
import { useNavigate, useParams } from "react-router-dom";
import AppModulesAccess from "../components/app-modules-access";
import { BaseService } from "../../../../../network/services/base/BaseService";

const initialAppModulesGroup: AppModulesGroup = {
  id: 0,
  name: "",
  appModules: [],
};
function GroupModuleAccess() {
  const { id } = useParams();
  const [appModulesGroup, setAppModulesGroup] = useState<AppModulesGroup>(
    initialAppModulesGroup
  );
  const [appModulessLoading, setAppModulesLoading] = useState<boolean>(true);
  const appModulesAccessService = new BaseService(`api/PermissionGroups/${id}/app-modules-access`);
  const getAppModulesAccessService = async () => {
    try {
      const res = await appModulesAccessService.getAll();
      setAppModulesGroup(res.data);
      setAppModulesLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setAppModulesLoading(false);
    }
  };
  useEffect(() => {
    getAppModulesAccessService();
  }, [id]);
  const transformData = (data: AppModulesGroup) => {
    const transform = (module: any) => ({
      id: module.id,
      accesses: module.permissionAccesses.map(
        (access: GetPermissionAccesses) => ({
          id: access.id,
          isAccess: access.isAccess,
        })
      ),
    });
    let result = data.appModules.map((module: AppModule) => {
      let transformedModules: any = [];
      if (module.subAppModules.length > 0) {
        transformedModules = module.subAppModules.map(transform);
      }
      return [transform(module), ...transformedModules];
    });

    return result.flat();
  };
  const handleClick = () => {
    setAppModulesLoading(true);
    const result = transformData(appModulesGroup);
    ApiInstance.put(`api/PermissionGroups/${id}/app-modules-access`, result)
      .then((res) => {
        console.log(res);
        getAppModulesAccessService();
        setAppModulesLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAppModulesLoading(false);
      });
  };
  return (
    <AppModulesAccess
      handleClick={handleClick}
      appModulesGroup={appModulesGroup}
      setAppModulesGroup={setAppModulesGroup}
      appModulessLoading={appModulessLoading}
    />
  );
}

export default GroupModuleAccess;
