import { LessonFormat } from "../../shared/continuity/models/LessonFormat";
export const lessonFormats = (
    hasTheoric: boolean,
    hasPratice: boolean,
    hasLab: boolean,
    arr?:any
  ) => {
    const formats = [
      { name: "Offline", value: 1 },
      { name: "Online", value: 2 }
    ];
    let selectedLessonFormats:LessonFormat={
      name: "",
      value: -1,
    }
    if (hasTheoric) {
      formats.push({ name: "Dərs ləğv edildi", value: 3 });
    }
    if (hasPratice) {
      formats.push({ name: "Praktika ləğv edildi", value: 3 });
    }
    if (hasLab) {
      formats.push({ name: "Lab ləğv edildi", value: 3 });
  }
    if (hasTheoric && hasPratice) {
      formats.push({ name: "Dərs/Praktika ləğv edildi", value: 3 });
    }
    if (hasTheoric && hasLab) {
      formats.push({ name: "Dərs/Lab ləğv edildi", value: 3 });
    }
    if (hasPratice && hasLab) {
      formats.push({ name: "Praktika/Lab ləğv edildi", value: 3 });
    }
    if (hasTheoric && hasPratice && hasLab) {
      formats.push({ name: "Dərs/Praktika/Lab ləğv edildi", value: 3 });
    }
    let count=0;
    arr && arr.map((item:any)=>{
      if (item.category==="Theoric") {
        if(item.status==="Cancelled"){
          count++
          selectedLessonFormats={ name: "Dərs ləğv edildi", value: 3 }
        }
      }
      if (item.category==="Practice") { 
        if(item.status==="Cancelled"){
          count++
          selectedLessonFormats={ name: "Praktika ləğv edildi", value: 3}
        }
      }
      if (hasTheoric && hasPratice && count===2) {
        selectedLessonFormats={ name: "Dərs/Praktika ləğv edildi", value: 3 }
      }
      if (hasLab) {
        if(item.status==="Cancelled"){
          selectedLessonFormats={ name: "Lab ləğv edildi", value: 3 }
        }
    }
      if (hasTheoric && hasLab) {
        if(item.status==="Cancelled"){
          selectedLessonFormats={ name: "Dərs/Lab ləğv edildi", value: 3 }
        }
      }
      if (hasPratice && hasLab) {
        if(item.status==="Cancelled"){
          selectedLessonFormats={ name: "Praktika/Lab ləğv edildi", value: 3 }
        }
      }
      if (hasTheoric && hasPratice && hasLab) {
        if(item.status==="Cancelled"){
          selectedLessonFormats={ name:"Dərs/Praktika/Lab ləğv edildi", value: 3 }
        }
      }
      if(selectedLessonFormats.value!==3&&item.status==="Online"){
        selectedLessonFormats={ name:item.status, value: 2 }
      }
      if(selectedLessonFormats.value!==3&&item.status==="Offline" || item.status===null){
        selectedLessonFormats={ name: "Offline", value: 1 }
      }
      
      
    })
    return {formats,selectedLessonFormats};
  };