import React from "react";
import { Box } from "@mui/material";
import Button from "../../../../../../../../components/core-components/inputs/button";
import PageTitle from "../../../../../../../../components/custom-components/page-title";

type Props = {
  complete: boolean;
};

function Header(props: Props) {
  const { complete } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={"40px"}
    >
      <PageTitle name="Tədris proqramı yarat" />
      <Box display={"flex"}>
        <Button
          type="submit"
          form="myform"
          sx={{
            color: complete ? "default" : "#ACB1C0",
            background: complete ? "default" : "#E1E3EB",
            pointerEvents: complete ? "auto" : "none",
            boxShadow: "none",
            textTransform: "none",
            marginRight: "16px",
          }}
        >
          Yadda saxla
        </Button>
        {/* <Button
          sx={{
            color: "#FFFFFF",
            background: "#4F5259",
            textTransform: "none",
            "&:hover": {
              background: "#4F5259",
            },
          }}
        >
          Şablon kimi yadda saxla
        </Button> */}
      </Box>
    </Box>
  );
}

export default Header;
