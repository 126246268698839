import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Worker } from '../../network/models/worker/Worker';
import { WorkerService } from '../../network/services/workerService/WorkerService';

let workerService = new WorkerService()

export const fetchWorkers = createAsyncThunk('workers/getWorkers', async () => {
    const { data } = await workerService.getAll();
    return data;
});

interface initialStateModel {
    workers: Worker[];
    status: string;
    error: any;
}

const initialState: initialStateModel = {
    workers: [],
    status: "idle",
    error: null,
};

const workersSlice = createSlice({
    name: 'workers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workers = action.payload;
            })
            .addCase(fetchWorkers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default workersSlice.reducer;