import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button
} from '@mui/material';
import { useRef, useState } from 'react';

export default function AddHoursInput({value, onChange}: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {
        isOpen ? (
          <TextField
          type="number"
          label=""
          value={value}
          onChange={(e)=> onChange('hours', +e.target.value)}  
          onBlur={()=> setIsOpen(false)}  
          sx={{ width: '100%', height: '100%', backgroundColor: 'white' }}
        />
        ) : (
          <Typography onClick={()=> setIsOpen(true)}> {value ? `${value} saat` : 'Saat seçin'} </Typography>
        )
      }
     
    </>
    
  )
}
