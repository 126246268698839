export const localeText = {
  // Root
  rootGridLabel: "Grid",
  noRowsLabel: "Sətir yoxdur",

  // Pagination
  paginationPreviousLabel: "Əvvəlki səhifə",
  paginationNextLabel: "Növbəti səhifə",
  paginationLabelRowsPerPage: "Səhifədə sətir sayı:",
  paginationLabelDisplayedRows: "{from}-{to} sətir üzərindən {count}",

  // Columns tool panel
  columnsPanelTextFieldLabel: "Sütun axtarışı",
  columnsPanelTextFieldPlaceholder: "Sütun adı",
  columnsPanelDragIconLabel: "Sütun sıralamasını dəyişmək üçün tutun",
  columnsPanelShowAllButton: "Hamısını göstər",
  columnsPanelHideAllButton: "Hamısını gizlət",
  columnsPanelRestoreDefaultsButton: "Varsayılanlara bərpa",

  // Filter tool panel
  filterPanelAddFilter: "Filtər əlavə et",
  filterPanelDeleteIconLabel: "Sil",
  filterPanelOperators: "Operatorlar",
  filterPanelOperatorAnd: "Və",
  filterPanelOperatorOr: "Və ya",
  filterPanelColumns: "Sütunlar",

  // Column menu
  columnMenuLabel: "Menyu",
  columnMenuShowColumns: "Sütunları göstər",
  columnMenuFilter: "Filtrlər",
  columnMenuHideColumn: "Gizlət",
  columnMenuUnsort: "Sıralamadan çıxar",
  columnMenuSortAsc: "Artan sırala",
  columnMenuSortDesc: "Azalan sırala",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: any) =>
    `${count} aktiv filter ${count > 1 ? "lər" : ""}`,
  columnHeaderFiltersLabel: "Filter",

  // Rows selected footer text
  footerRowSelected: (count: any) =>
    count !== 1 ? `${count} sətir seçildi` : `${count} sətir seçildi`,

  // Pagination footer text
  footerPageSelected: (page: any, count: any) =>
    `${page}. səhifə, ${count} sətir`,
  footerPage: (page: any) => `${page}. səhifə`,
  footerRowsPerPage: "Səhifədəki sətir sayı:",

  // MUI Text
  muiTablePagination: {
    labelDisplayedRows: "{from}-{to} sətir, cəmi {count}",
    labelRowsPerPage: "Səhifə başı sətir sayı",
  },
  // Filter values
  filterValueAny: "Hər hansı bir",
  filterValueTrue: "Doğrudur",
  filterValueFalse: "Yanlışdır",
  filterOperatorContains: "Mövcuddur",
  filterOperatorEquals: "Bərabərdir",
  filterOperatorNotEquals: "Bərabər deyil",
  filterOperatorStartsWith: "Başlayır",
  filterOperatorEndsWith: "Bitirir",
  filterOperatorIs: "dir",
  filterOperatorNot: "deyil",
  filterOperatorAfter: "Sonra",
  filterOperatorOnOrAfter: "və ya sonra",
  filterOperatorBefore: "Əvvəl",
  filterOperatorOnOrBefore: "və ya əvvəl",
  valuePlaceHolder: "Axtarın",

  columnMenuValueSortLabel: "Sıralama",
  columnMenuValueFilterLabel: "Filtr",
  columnMenuValueMenuLabel: "Seçim",
  columnMenuValueColumnLabel: "Sütun",
  columnMenuValueLabel: "Dəyər",
  filterPanelHeaderText: "Filtr",
  filterValue: "Dəyər",
  filterPanelClearButtonLabel: "Təmizlə",
  filterPanelPlaceHolder: "Axtar",
  filterValuePlaceholder: "Dəyər", // <--- Add this line

  filterPanelOperator: "Operatör",

  filterPanelInputLabel: "Dəyər",
  filterPanelInputPlaceholder: "Filtr dəyəri",

  // Column menu context
  columnMenuContextHide: "Gizlə",

  filterOperatorIsEmpty: "Boş",
  filterOperatorIsNotEmpty: "Dolu",
  filterOperatorIsAnyOf: "Hər hansı biri",
};
