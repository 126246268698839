import { TableCell, Box } from '@mui/material/'
import React from 'react'
import { sxTableCellInside } from './style'

interface PropsModel {
  children: React.ReactNode,
  width: string,
  disabled?: boolean
  sx?: any
}

function TableCellBody(props: PropsModel) {
  const { children, width, disabled, sx } = props
  return (
    <>
      <TableCell 
      sx={{
        width: width,
        maxWidth: 0,
        whiteSpace: "nowrap",
        transition: "0.3s ease all",
        padding:"12px 16px",
        "&:hover": {
          background: disabled ? "transparent" : "#F3F3F3" 
        },
        ...sx
      }} component="th" scope="row">
      <Box sx={sxTableCellInside}>{children}</Box>
    </TableCell >
    </>
  )
}

export default TableCellBody