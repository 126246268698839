import React, { useEffect, useState } from "react";
import Filter from "./components/filter";
import Header from "./components/header";
import TabHeader from "./components/tab-header";
import DataGrid from "../../../../components/custom-components/data-grid";
import TabPanel from "./components/tab-panel";
import TableCellBody from "./components/tablebody";
import {
  Box,
  Typography,
  Dialog,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { BaseService } from "../../../../network/services/base/BaseService";
import { sxColumnHeader, sxSearchIcon, sxSelect } from "./style";
import { Class } from "../../shared/continuity/models/Class";
import axios from "axios";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Modal from "./components/modal";
import Progress from "../../../../components/custom-components/progress";
import { Program } from "../../../../network/models/program/Program";
import { StudentAttendance } from "./models/StudentAttendance";
import { ModalData } from "./models/ModalData";
import { StudentAbsents } from "./models/StudentAbsents";
import { ROLES } from "../../../../constants/auth/roles";
import { AuthState } from "../../../../components/layout-components/header";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ContinuityResultStudents() {
  let user = useSelector((state: AuthState) => state.auth.user);
  const userId = user?.id
  const roles = user?.roleName
  const [open, setOpen] = useState<boolean>(false);
  const [studentDetails, setStudentDetails] = useState<any[]>();
  const [selectedStudentDetail, setSelectedStudentDetail] = useState([]);
  const [classesByProgram, setClassesByProgram] = useState<Class[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [classes, setClasses] = useState<Class[]>([]);
  const [value, setValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [filteredProgram, setFilteredProgram] = useState<string>("");
  const [filteredClass, setFilteredClass] = useState<string>("");
  const [modalData, setModalData] = useState<ModalData[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [studentAttendance, setStudentAttendance] = useState<StudentAttendance>(
    {
      id: "",
      name: "",
      surname: "",
      phone: "",
      eMail: "",
      attendancePercent: 0,
      attendances: [],
    }
  );
  const [studentAbsentsDetail, setStudentAbsentsDetail] = useState<
    StudentAbsents[]
  >([]);
  const [userRole, setUserRole] = useState("admin");
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const classesByWorkerIdService = new BaseService(
    `/api/workers/${userId}/classes`
  );
  const getClassesById = async (id: string) => {
    const newService = new BaseService(`/api/Classes/${id}/class-by-program/`);
    newService.getAll().then((res) => setClassesByProgram(res.data));
  };
  const programsService = new BaseService(`/api/Programs`);
  const getPrograms = async () => {
    const res = await programsService.getAll();
    const sortedData = res.data.sort((a: Program, b: Program) =>
      a.name.localeCompare(b.name)
    );
    setPrograms(sortedData);
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const getStudentDetails = async (classId: string) => {
    if (classId) {
      setIsLoading(true);
      const studentDetailsService = new BaseService(
        `/api/Classes/${classId}/students-details`
      );
      studentDetailsService
        .getAll()
        .then((res) => {
          setStudentDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getClassesByWorkerId = async () => {
    try {
      setIsLoading(true);
      const classesRes = await classesByWorkerIdService.getAll();
      setIsLoading(false);
      const classesData = classesRes?.data;

      await getStudentDetails(classesData[0]?.id);
      await getStudentAbsentsDetails(classesData[0]?.id);

      setClasses(classesData);
    } catch (errr) {
      console.log(errr);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const role = roles?.find((x: string) => x === ROLES.ADMIN)
    if (role) {
      setIsAdmin(true)
    }

    getClassesByWorkerId();
    getPrograms();
  }, []);

  const getStudentAbsentsDetails = async (classId: string) => {
    if (classId) {
      setIsLoading(true);
      let studentsAbsentService = await new BaseService(
        `/api/Classes/${classId}/students-absent`
      );

      studentsAbsentService.getAll().then((res) => {
        setStudentAbsentsDetail(res.data);

        setIsLoading(false);
      });
    }
  };

  const studentAbsentsTabColumns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "A.S.A",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.name} {params.row.surname}
          </Typography>
        );
      },
      valueGetter: (params) => {
        return params.row.name;
      },
    },
    {
      field: "group",
      flex: 0.4,
      sortable: false,
      renderHeader: (params) => {
        return (
          <FormControl sx={{ width: "200px" }}>
            <Select
              sx={sxSelect}
              labelId="class-select-label"
              id="class-select"
              value={"all"}
              onChange={(event: any) => {
                let id = event.target.value as string;
                getStudentAbsentsDetails(id);
                getStudentDetails(id);
              }}
            >
              <MenuItem sx={{ display: "none" }} value="all">
                Qrup
              </MenuItem>
              {React.Children.toArray(
                classes.map((classItem: any) => (
                  <MenuItem key={classItem?.id} value={classItem?.id}>
                    {classItem?.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        );
      },
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.class.name}
          </Typography>
        );
      },
    },

    {
      field: "absentCount",
      headerName: "Qayıb sayı",
      flex: 0.4,
    },
  ];

  const getStudentAttendance = (studentId: string, id: string) => {
    setModalLoading(true);
    const studdentAttendanceService = new BaseService(
      `/api/Students/${studentId}/attendances?classId=${id}`
    );
    studdentAttendanceService
      .getAll()
      .then((res) => {
        setStudentAttendance(res.data);
        setModalData(
          res.data.attendances.map((attendance: ModalData, index: number) => ({
            ...attendance,
            id: index,
          }))
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoading(false));
  };

  return (
    <>
        <Header userRole={userRole} setOpen={setOpen} isAdmin={isAdmin}/>

        {
          isAdmin ?

            <Filter
              setFilteredClass={setFilteredClass}
              filteredClass={filteredClass}
              getClassesById={getClassesById}
              setIsSubmitted={setIsSubmitted}
              classesByProgram={classesByProgram}
              setClassesByProgram={setClassesByProgram}
              setFilteredProgram={setFilteredProgram}
              filteredProgram={filteredProgram}
              programs={programs}
              open={open}
              setOpen={setOpen}
              getStudentDetails={getStudentDetails}
            />

            :
            <></>

        }

        {/* <Filter
          setFilteredClass={setFilteredClass}
          filteredClass={filteredClass}
          getClassesById={getClassesById}
          setIsSubmitted={setIsSubmitted}
          classesByProgram={classesByProgram}
          setClassesByProgram={setClassesByProgram}
          setFilteredProgram={setFilteredProgram}
          filteredProgram={filteredProgram}
          programs={programs}
          open={open}
          setOpen={setOpen}
          getStudentDetails={getStudentDetails}
        /> */}
        <TabHeader
          studentDetails={studentDetails || []}
          value={value}
          handleChange={handleChange}
        />
        <TabPanel value={value} index={0}>
          <Box sx={{ width: "100%", height: "auto" }}>
            {isLoading ? (
              <Progress />
            ) : (
              <TableCellBody
                isSubmitted={isSubmitted}
                classesByProgram={classesByProgram}
                setSelectedStudentDetail={setSelectedStudentDetail}
                getStudentAbsentsDetails={getStudentAbsentsDetails}
                classes={classes}
                getStudentDetails={getStudentDetails}
                studentDetails={studentDetails || []}
                handleClickOpen={handleClickOpen}
                getStudentAttendance={getStudentAttendance}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ width: "100%", height: "auto" }}>
            {isLoading ? (
              <Progress />
            ) : (
              <DataGrid
                data={studentAbsentsDetail}
                columnsData={studentAbsentsTabColumns}
                pageSizeData={pageSize}
                setPageSize={setPageSize}
                disableColumnMenu={true}
                onRowClick={(rows: { id: string; row: any }) => {
                  const filtered: any = classes.filter(
                    (item) => item.name === rows.row.className
                  );
                  getStudentAttendance(rows.id, filtered[0].studentId);
                  handleClickOpen();
                  setSelectedStudentDetail(rows.row);
                }}
              />
            )}
          </Box>
        </TabPanel>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        <Modal
          selectedStudentDetail={selectedStudentDetail}
          studentAttendance={studentAttendance}
          modalData={modalData}
          modalLoading={modalLoading}
        />
      </Dialog>
    </>
  );
}

export default ContinuityResultStudents;
