import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { sxBtn } from "../style";
import Button from "@mui/material/Button";
import ModalHeader from "../components/modal-header";
import { SelectChangeEvent } from "@mui/material/Select";
import TableSetProgram from "../set-program-detail/components/table-set-program";
import { ClassService } from "../../../../../network/services/classService/ClassService";
import { BaseService } from "../../../../../network/services/base/BaseService";
import {
  StudentHeaderDetail,
} from "../models/StudentDetail";
const Tittle = "Qrup tənzimləməsi";
interface PropsModel {
  onClose: () => void;
  handlePostClick: any;
  classId: any;
  selectedSeans: string;
  handleChangeSeans: (event: SelectChangeEvent) => void;
  step: number;
  setStep: any;
  studentsHeaderDetail: StudentHeaderDetail | undefined;
}

const classService = new ClassService();
const baseService = new BaseService("api");

function ClassSession(props: PropsModel) {
  const {
    classId,
    selectedSeans,
    onClose,
    handlePostClick,
    studentsHeaderDetail,
  } = props;
  const [modules, setModules] = useState<any>([]);
  useEffect(() => {
    async function getData() {
      let teacherId: any;
      let mentorId: any;
      async function getRoles() {
        const res = await baseService.getAll(`api/Roles`);
        mentorId = res.data.find((x: any) => x.name === "mentor").id;
        teacherId = res.data.find((x: any) => x.name === "muellim").id;
      }
      await getRoles();
      classService
        .getById(`${classId}/modules-workers?sessionId=${selectedSeans}`)
        .then((res: any) => {
          res.data.modules = res.data.modules.sort(
            (a: any, b: any) => parseInt(b?.version) - parseInt(a?.version)
          );
          res.data.modules = res.data.modules.map((item: any) => {
            item.subModules.sort((a: any, b: any) => {
              return (
                parseInt(b?.version.split(".")[1]) -
                parseInt(a?.version.split(".")[1])
              );
            });
            if (item.workers.length !== 2) {
              if (!item.workers.some((x: any) => x.role === "muellim")) {
                item.workers.push({ role: "muellim", roleId: teacherId });
              }

              if (!item.workers.some((x: any) => x.role === "mentor")) {
                item.workers.push({ role: "mentor", roleId: mentorId });
              }
            }
            item.subModules = item.subModules?.map((elem: any) => {
              if (elem.workers.length !== 2) {
                if (!elem.workers.some((x: any) => x.role === "muellim")) {
                  elem.workers.push({ role: "muellim", roleId: teacherId });
                }

                if (!elem.workers.some((x: any) => x.role === "mentor")) {
                  elem.workers.push({ role: "mentor", roleId: mentorId });
                }
              }
              return { ...elem, isChecked: false };
            });
            item.isChecked = false;
            return item;
          });

          setModules(res.data.modules);
        })
        .catch((err: any) => console.log(err));
    }

    getData();
  }, [classId]);

  return (
    <>
      <Box display="inline-flex" height="696" flexDirection="column" gap="24px">
        <ModalHeader
          studentsHeaderDetail={studentsHeaderDetail}
          tittle={`${studentsHeaderDetail?.name} ${Tittle}`}
        >
          <Button
            onClick={() => handlePostClick(modules)}
            variant="contained"
            color="primary"
            sx={sxBtn}
          >
            Yadda Saxla
          </Button>
          <Button
            onClick={onClose}
            sx={[{ background: "#F3EDF4", color: "#0E0D0D" }, sxBtn]}
          >
            İndi yox
          </Button>
        </ModalHeader>
        <TableSetProgram
          modules={modules}
          setModules={setModules}
          isTableAdd={false}
        />
      </Box>
    </>
  );
}
export default ClassSession;
