import React from 'react'
import { Box, Stack } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import { theme } from '../../../assets/themes';
import BaseIcon from '../base-icon';

interface PropsModel{
    text:string
}

function InformativeText(props:PropsModel) {
    const {text} = props
    return (
        <>
            <Stack
                direction={"row"}
                spacing={"16px"}
                sx={{
                    background: theme.palette.primary.light,
                    borderRadius: "4px",
                    alignItems: "center",
                    marginTop: "20px"
                }}
                padding={"14px 16px"}
            >
                <BaseIcon>
                    <WarningAmberIcon sx={{
                        color: theme.palette.primary.main,
                        width: "20px",
                        height: "20px"
                    }} />
                </BaseIcon>
                <Typography
                    paragraph={true}
                    sx={{
                        color: "#4F5259",
                        fontSize: "14px"
                    }}
                >{text}</Typography>
            </Stack>
        </>
    )
}

export default InformativeText