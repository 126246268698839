import createTheme from '@mui/material/styles/createTheme';
import "../style/font.css"

export const theme = createTheme({
  palette: {
    primary: {
      main: '#854693',
      light: "#F3EDF4"
    },
    secondary: {
      main: "#BABABA",
      light: "#F3F3F3"
    }
  },
  typography: {
    fontFamily: 'Visby CF Medium, sans-serif',
  },
  components: {
    // MuiDataGrid: {
    //   styleOverrides: {
    //     columnHeader: {
    //       fontFamily: 'Visby CF Bold, sans-serif',
    //     }
    //   }
    // },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Visby CF Bold, sans-serif',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding:"10px",
          maxHeight:"100% !important",
          "& .MuiList-root.MuiMenu-list ": {
            maxHeight:"250px",
            overflowY:"scroll",
            overflowX:"hidden",
            "::-webkit-scrollbar": {
              width: "4px", 
              height: "2px", 
              backgroundColor: "#edf4f8",
              borderRadius:"10px"
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor:"#468CBC", 
              borderRadius: "4px" 
            },
          },
        },
      },
    },
  }
});