import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface IImageDetailModel{
    imgSrc: string,
    handleClose: any;
}

export default function ImageDetail({imgSrc, handleClose}: IImageDetailModel) {
  return (
    <div onClick={handleClose} style={{backgroundColor: 'rgba(0, 0, 0, 0.49)', width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '100'}}>
        <IconButton 
            onClick={handleClose}
            sx={{position: 'absolute', top: '20px', right: '20px'}}
            aria-label="delete">
            <CloseIcon/>
        </IconButton>
        <img 
            style={{width: '50%', height: '50%', objectFit: 'cover', objectPosition: 'center'}}
            src={imgSrc} 
            loading="lazy"/>
    </div>
  )
}
