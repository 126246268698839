import { Drawer, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material/'
import React from 'react'
import { sxDrawer, sxFilter, sxFilterPart, sxFooterFilter } from './style';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../../../../assets/themes';
import Button from '../../../../../../components/core-components/inputs/button';

interface PropsModel {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Filter(props: PropsModel) {
    const { open, setOpen } = props
    return (
        <>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                anchor="right"
                sx={sxDrawer}
            >
                <Box sx={sxFilter}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant={"h3"} sx={{ fontSize: "24px", fontFamily: 'Visby CF Bold, sans-serif',}}>Filter</Typography>
                        <Box sx={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: theme.palette.secondary.light,
                            cursor: "pointer"
                        }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box component={"form"}>
                        <Box sx={sxFilterPart}>
                            <Typography sx={{ fontSize: "12px", fontFamily: 'Visby CF Bold, sans-serif', marginBottom: "16px" }} variant='h5'>Vəzifə</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Vəzifə seçin</InputLabel>
                                <Select
                                    sx={{
                                        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
                                            fontFamily: 'Visby CF Bold, sans-serif',
                                            fontSize: "14px"
                                        },
                                        borderRadius: "12px"
                                    }}
                                    label="Vəzifə seçin">
                                    <MenuItem value={10}>Muellim</MenuItem>
                                </Select>
                                <Box sx={sxFooterFilter}>
                                    <Button>Tətbiq et</Button>
                                    <Button style={{ color: "#0E0D0D", background: theme.palette.primary.light }}>Filteri təmizlə</Button>
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}

export default Filter