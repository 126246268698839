// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-group {
  display: flex;
  width: 100%;
  margin-top: 68px;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 48px;

  background-color: #f3f3f3;
  border-radius: 16px;
  border: none;
  text-align: center;
  font-size: 20px;
  outline: none;
  font-weight: bold;
  line-height: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth-views/login/otp/OtpInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".otp-group {\n  display: flex;\n  width: 100%;\n  margin-top: 68px;\n  max-width: 360px;\n  column-gap: 10px;\n}\n\n.otp-input {\n  width: 100%;\n  height: 48px;\n\n  background-color: #f3f3f3;\n  border-radius: 16px;\n  border: none;\n  text-align: center;\n  font-size: 20px;\n  outline: none;\n  font-weight: bold;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
