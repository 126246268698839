import styles from './card.module.css';

interface IProgramModel{
    id: number,
    name: string
}

export interface IUnMarkedAttendancyCardModel{
    program: IProgramModel | null;
    totalAttendancePercentage: number | null;
    totalUnMarkedAttendancesCount: number | null;
    unMarkedAttendancesCount: number | null;
    isActive: boolean;
    onClick?: any;
}

export default function Card({program, totalUnMarkedAttendancesCount, totalAttendancePercentage, unMarkedAttendancesCount, isActive, onClick} : IUnMarkedAttendancyCardModel) {

  return (
    <div onClick={onClick} className={`${styles.card} ${unMarkedAttendancesCount === 0 ? null : styles.warning} `}>
        <span className={styles.badge}><span>{totalUnMarkedAttendancesCount}</span></span>
        <h4 className={styles.title}>{program?.name}</h4>
        <p className={styles.text}>{totalAttendancePercentage}% total davamiyyət</p>
        <p className={styles.text}>{unMarkedAttendancesCount} yazılmamış davamiyyət</p>
        <span style={{display: isActive ? 'block' : 'none'}} className={`${styles.caret} ${unMarkedAttendancesCount === 0 ? null : styles.warning} `}></span>
    </div>
  )
}
