import { Box, Tab, Tabs } from "@mui/material";
import { sxTab } from "./style";

interface TabPanelProps {
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  value:string
}
function TabHeader(props: TabPanelProps) {
  const { handleChange,value } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
        
          value={value}
          onChange={handleChange}
          sx={{ minHeight: "48px" }}
        >
          <Tab disableRipple sx={sxTab} label="İstifadəçi qrupları"  value="1" />
          <Tab disableRipple sx={sxTab} label="Bütün istifadəçilər" value="2" />
        </Tabs>
      </Box>
    </Box>
  );
}

export default TabHeader;
