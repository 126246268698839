import { combineReducers } from "redux";
import authReducer from "./authReducer";
import classReducer from "./classReducer";
import programReducer from "./programReducer";
import workerReducer from "./workerReducer";
import groupFilterReducer from "./groupFilterReducer";

const rootReducer = combineReducers({
  program: programReducer,
  class: classReducer,
  worker: workerReducer,
  auth: authReducer,
  groupFilter: groupFilterReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
