import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IErrorDialogueModel{
    isOpen: boolean;
    changeOpen: any;
}

export default function ErrorDialogue({isOpen, changeOpen} : IErrorDialogueModel) {
  return (
    <Dialog
        open={isOpen}
        onClose={changeOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Diqqət!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Boş filter tətbiq edilə bilməz!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={changeOpen}>Bağla</Button>
        </DialogActions>
    </Dialog>
  )
}
