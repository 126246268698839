export const sxContainer: React.CSSProperties={
 display:"flex",
 flexDirection:"column",
 alignItems:"flex-start",
 width:"100%",
}
export const sxRow:React.CSSProperties={
    display:"flex",
    justifyContent:"space-between",
    padding:"12px 0",
    alignItems:"center",
    gap:"25px",
    width:"100%",
    borderBottom: "1px solid var(--Natural-natural-50, #E9E9E9)"
}