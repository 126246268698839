import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box';
import React from 'react'
import { theme } from '../../../assets/themes';

function BaseIcon(props: BoxProps) {
    return (
        <>
            <Box sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.light,
                cursor: "pointer"
            }}
                {...props}
            >
                {props.children}
            </Box>
        </>
    )
}

export default BaseIcon