import React, { useEffect, useState } from "react";
import Header from "./components/header";

import { Box, IconButton, TextField, Typography } from "@mui/material";
import DataGrid from "../../../../../components/custom-components/data-grid";
import { Session } from "./models/Session";
import { BaseService } from "../../../../../network/services/base/BaseService";
import { Link, useParams } from "react-router-dom";
import Progress from "../../../../../components/custom-components/progress";
import { GridColDef } from "@mui/x-data-grid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import "moment/locale/az";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../../../../components/core-components/inputs/button";

function ClassSessions() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const params = useParams();
  const [pageSize, setPageSize] = useState<number>(10);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const sessionService = new BaseService(
    `api/classes/${params.id}/class-sessions`
  );

  const getSessions = async () => {
    try {
      const res = await sessionService.getAll();

      setSessions(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  const clearFilter = () => {
    setSelectedDate(null);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getSessions()])
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  }, []);

  const columns: GridColDef[] = [
    {
      field: "classSessionDate",
      headerName: "Tarix",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format("dddd, D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "classSessionStatus",
      headerName: "Status",
      flex: 0.5,
      renderCell(params) {
        return params.row.classSessionStatus || "Boş";
      },
    },
    {
      field: "operation",
      headerName: "",
      flex: 0.5,
      align: "right",
      headerAlign: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Link to={`by-day/${params.id.toString().split("T")[0]}`}>
              {" "}
              <Button
                variant="contained"
                sx={{
                  padding: "0px 10px",
                  marginRight: "10px",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                Sessiya təyin et
              </Button>
            </Link>
            <Link to={`${params.id.toString().split("T")[0]}`}>
              <Button
                variant="contained"
                sx={{
                  padding: "0px 10px",
                  marginRight: "10px",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                Davamiyyətə düzəliş et
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  if (loading) {
    return <Progress />;
  }
  return (
    <>
        <Header sessions={sessions} />
        <Box sx={{ width: "100%", height: "auto" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "14px",
                  height: "40px",
                  width: "180px",
                },
              }}
            />
            {selectedDate && (
              <IconButton
                onClick={clearFilter}
                size="small"
                sx={{ marginLeft: "-1px", marginTop: "3px" }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </LocalizationProvider>
          {selectedDate ? (
            <DataGrid
              data={sessions.filter(
                (session) =>
                  dayjs(session.classSessionDate).format("YYYY-MM-DD") ===
                  dayjs(selectedDate).format("YYYY-MM-DD")
              )}
              columnsData={columns}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              disableColumnMenu={true}
              field={"classSessionDate"}
            />
          ) : (
            <DataGrid
              data={sessions.slice().sort((a, b) => {
                const dateA = new Date(a.classSessionDate);
                const dateB = new Date(b.classSessionDate);
                return dateA.getTime() - dateB.getTime();
              })}
              columnsData={columns}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              disableColumnMenu={true}
              field={"classSessionDate"}
            />
          )}
        </Box>
    </>
  );
}

export default ClassSessions;
