import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
interface DatePickerProps {
  date: string;
  isSubmodule: boolean;
  id: string;
  type: string;
  handleDateChange: (
    date: string,
    id: string,
    isSubmodule: boolean,
    type: string
  ) => void;
}
export default function DatePickerCustom(props: DatePickerProps) {
  const { date, id, isSubmodule, type, handleDateChange } = props;
  const [openDatePicker, setOpenDatePicker] = useState(false);
  return (
    <div style={{position: 'relative'}}>
      <TextField
        sx={{
          padding:"16px",
          "& .MuiOutlinedInput-input": {
            padding: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            "&.Mui-focused": {
              border: "none",
            },
          },
          "& .MuiInputBase-root": {
            fontSize: "14px",
          },
        }}
       
        onClick={() => setOpenDatePicker(true)}
        value={date ? dayjs(date).format("DD MMMM YYYY") : 'Tarixi seç'}
      />
      {openDatePicker && (
        <LocalizationProvider dateAdapter={AdapterDayjs}
        >
          <DatePicker
            sx={{visibility: 'hidden', position: 'absolute', left: '0'}}
              open={openDatePicker}
              onClose={() => setOpenDatePicker(false)}
              onChange={(value) =>
                {
                  handleDateChange(`${(value as any).$y}-${(value as any).$M < 10 ? `0${(value as any).$M + 1 }` : ((value as any).$M + 1)}-${(value as any).$D < 10 ? `0${(value as any).$D}` : (value as any).$D}`, id, isSubmodule, type)
                }
              }
              value={date ? dayjs(date) : undefined}
          />
        </LocalizationProvider>
        
      )}
    </div>
  );
}
