export const sxSelect = {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
        padding: "0",
        fontFamily: 'Visby CF Bold, sans-serif',
        fontSize: "24px",
    },
    '& .MuiOutlinedInput-notchedOutline':
        { border: "none" },
    '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon':{
        top: "calc(50% - 0.8em)",
        right:"35%"
    },
    '& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon':{
        top: "calc(50% - 0.8em)",
        right:"35%"
    }
}