import { theme } from "../../../../../assets/themes"

export const sxMenuElementParent: React.CSSProperties = {
    marginTop: "16px",
    padding: "0px 8px 0px 16px",
}

export const sxMenuElement = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    borderRadius: "12px",
    padding: "0px 8px 0px 12px",
    height: "52px",
    transition: "0.5s ease all",
    "& .MuiBox-root.css-n5qobb": {
        transition: "0.5s ease all",
    },
    "&:hover": {
        background: theme.palette.primary.light,
        ".MuiBox-root.css-n5qobb": {
            backgroundColor: "white !important"
        }
    },
}

export const sxElementLeftSide: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    whiteSpace:"nowrap"
}

export const sxRenctangle: React.CSSProperties = {
    position: "absolute",
    height: "calc(100% - 16px)",
    background: theme.palette.primary.main,
    left: "0",
    top:"16px",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    transition:"0.5s ease all"
}

export const sxElementName: React.CSSProperties = {
    marginLeft: "8px",
    fontWeight: "500",
    fontSize: "14px"
}

export const sxArrow: React.CSSProperties = {
    width: "16px",
    transform: "rotate(90deg)"
}