import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    position: "absolute",
    top: "calc(21.1% + 10px)", // Filter bileşeninin altında olacak şekilde ayarlayın
    left: "52.5%",
    transform: "translateX(-50%)",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "transparent",
  },
  "& .MuiPaper-root": {
    display: "flex",
    width: "600px",
    padding: "32px",
    margin: "0",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "32px",
    borderRadius: "16px",
    background: "#FFF",
    boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
  },
  "& .MuiDialogContent-root": {
    margin: 0,
    padding: 0,
    border: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    overflow: "auto"
  },
  "& #customized-dialog-title": {
    padding: "0",
    margin: "0",
    fontSize: "28px",
    color: "#000000",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: "0.4px",
    lineHeight: "24px",
    alignSelf: "stretch",
    fontFamily: "Visby CF Bold",
  },
  "& .MuiTypography-root": {
    color: "#6B696A",
    fontFamily: "Visby CF sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  "& .addModule": {
    fontFamily: "Visby CF Bold",
    fontSize: "14px",
    textDecorationLine: "underline",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    color: "#299AE8",
    cursor: "pointer",
  },
  "& .lesson": {
    fontWeight: 700,
    fontFamily: "Visby CF Bold",
  },
  "& .MuiDialogActions-root": {
    padding:"0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderRadius: "12px",
  },

  "& .closeBtn": {
    color: "#468CBC",
    background: "#EDF4F8",
    position: "absolute",
    right: "12px",
    top: "12px",
    fill: "#EDF4F8",
  },
  "& .MuiAutocomplete-inputRoot": {
    padding: "6px 24px",
    fontSize: "14px",
  },
  // "& .MuiAutocomplete-root": {
  //   borderRadius: "8px", // Burada borderRadius ekleniyor
  //   border: "1px solid var(--Natural-natural-50, #E9E9E9)"
  // },
  "& .MuiFormControl-root": {
    borderRadius: "8px", // Burada borderRadius ekleniyor
    border: "1px solid var(--Natural-natural-50, #E9E9E9)",
    background: "#F7F7F7",
  },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
}));
