import { useParams, useNavigate } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import Header from "../components/header";
import { BaseService } from "../../../../../network/services/base/BaseService";
import { useEffect, useState } from "react";
import { GroupDetail, Worker } from "../../models";
import UserList from "../user-list";
import Progress from "../../../../../components/custom-components/progress";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-primary.svg";
import { ReactComponent as SettingIcon } from "../../../../../assets/icons/settings-secondary.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/icons/add-outlined.svg";
import { ReactComponent as RemoveTrashIcon } from "../../../../../assets/icons/trash-24.svg";
import RemoveGroupModal from "../components/remove-group";
import AddUserToGroupModal from "../components/add-user-groups";
import { ApiInstance } from "../../../../../network/services/core/apiInstance";
import EditGroupModal from "../components/edit-group";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
const sxActionBox = {
  height: "40px",
  width: "40px",
  borderRadius: "40px",
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
  padding: "10px",
  cursor: "pointer",
};
function GroupDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showActionDelete, setShowActionDelete] = useState(false);
  const [addShowAction, setAddShowAction] = useState(false);
  const [groupDetails, setGroupDetails] = useState<GroupDetail>();
  const [showActionEdit, setShowActionEdit] = useState(false);
  const [groupsDetailLoading, setGroupsDetailLoading] = useState<boolean>(true);
  const PermissionGroupsService = new BaseService(`api/PermissionGroups`);
  const getGroupDetailsService = async () => {
    try {
      setGroupsDetailLoading(true);
      const res = await PermissionGroupsService.getById(`${id}/with-users`);
      setGroupDetails(res.data);
      setGroupsDetailLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setGroupsDetailLoading(false);
    }
  };
  useEffect(() => {
    getGroupDetailsService();
  }, [id]);

  const [users, setUsers] = useState<Worker[]>([]);
  const UsersService = new BaseService(`api/Users`);
  const getUsersService = async () => {
    await UsersService.getAll()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUsersService();
  }, []);
  return groupsDetailLoading ? (
    <Progress />
  ) : (
    <>
      <Header title={groupDetails?.name!}>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <Box
            sx={{ ...sxActionBox, backgroundColor: "#854693" }}
            onClick={() => setAddShowAction(true)}
          >
            <Tooltip title="İstifadəçi əlavə et">
              <AddIcon />
            </Tooltip>
          </Box>
          <Box
            onClick={(event) => {
              navigate(`${APP_PREFIX_PATH}/permissions/group-access/${id}`,);
            }}
            sx={{ ...sxActionBox, backgroundColor: "#EDF4F8" }}
          >
            <Tooltip title="İcazələr">
              <SettingIcon />
            </Tooltip>
          </Box>
          <Box
            sx={{ ...sxActionBox, backgroundColor: "#F3EDF4" }}
            onClick={() => setShowActionEdit(true)}
          >
            <Tooltip title="Redaktə et">
              <EditIcon />
            </Tooltip>
          </Box>
          <Box
            sx={{ ...sxActionBox, backgroundColor: "#FBEDEB" }}
            onClick={() => setShowActionDelete(true)}
          >
            <Tooltip title="Sil">
              <RemoveTrashIcon />
            </Tooltip>
          </Box>
        </Box>
      </Header>
      <UserList getUsersService={getGroupDetailsService} users={groupDetails?.workers ?? []} />
      <RemoveGroupModal
        open={showActionDelete}
        setShowActionDelete={setShowActionDelete}
        isFromDetail={true}
        id={+id!}
      />
      <AddUserToGroupModal
        groupId={+id!}
        users={users}
        open={addShowAction}
        setAddShowAction={setAddShowAction}
        getGroupDetailsService={getGroupDetailsService}
      />
      <EditGroupModal
        open={showActionEdit}
        setOpen={setShowActionEdit}
        users={users}
        groupId={+id!}
        getGroupDetailsService={getGroupDetailsService}
      />
    </>
  );
}

export default GroupDetails;
