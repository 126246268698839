import React from "react";
import { Box, Typography } from "@mui/material";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PageHeader from "../../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";

function Header() {
  return (
    <>
      <PageHeader>
        <Box>
          {/* <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Admin Əməliyyatları" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Rol Təyin Et" />
          </Breadcrumbs> */}
          <Box>
            <PageTitle name="Rol Təyin Et" />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}></Box>
      </PageHeader>
    </>
  );
}

export default Header;
