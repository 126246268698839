import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Tooltip,
  Switch,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import uuid from "react-uuid";
import { Module } from "../models/Module";
import {
  muiTooltipSx,
  plusIcon,
  indexNumber,
  deleteIcon,
  deleteOutlineIcon,
} from "./styles";
import ConfirmModal from "../../../../../../components/custom-components/confirm-modal";
import Alert from "../../../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import FormLayout from "../../../../../../components/layout-components/form/form-layout";
import { ModuleService } from "../../../../../../network/services/moduleService/ModuleService";
import Header from "./components/header";
import Footer from "./components/footer";
import Progress from "../../../../../../components/custom-components/progress";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPrograms } from "../../../../../../store/reducers/programReducer";
import { RootState } from "../../../../../../store/reducers";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

function AddModule() {
  const [module, setModule] = useState<Module>({
    id: "",
    name: "",
    value: 0,
    subModules: [],
  });
  const [active, setActive] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const param = useParams();
  const [deletedElement, setDeletedElement] = useState<Module>({
    id: "",
    name: "",
    value: 0,
    subModules: [],
  });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);

  let moduleService = new ModuleService();
  const location = useLocation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const allPrograms = useSelector((state: RootState) => state.program.programs);
  const statusPrograms = useSelector(
    (state: RootState) => state.program.status
  );

  const getModules = () => {
    
  }

  useEffect(() => {
    dispatch(fetchPrograms());
    if(param!.id){
      getProgram(+param!.id);
    }
    // if (location.state) {
    // }
  }, [param]);

  useEffect(() => {
    if (statusPrograms === "loading") setStatus(true);
    else setStatus(false);
  }, [statusPrograms]);

  useEffect(() => {
    const modulesSum: number = module.subModules.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
    const subModulesSum = module.subModules.map((item) => {
      if (item.subModules.length > 0) {
        const itemSubmoduleSum: number = item.subModules.reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
        if (item.value !== itemSubmoduleSum) {
          return false;
        }
      }
    });
    const isComplete: boolean = module.name
      ? module.value === modulesSum && !subModulesSum.includes(false)
      : false;
    setComplete(isComplete);
  }, [module]);

  const handleChange = (event: SelectChangeEvent): void => {
    const selectedValue: string = event.target.value;
    // getProgram(selectedValue);
  };

  const getProgram = (selectedValue: number) => {
    const selectedProgram = allPrograms.find(
      (module) => module.id == selectedValue
    );
    if (selectedProgram) {
      const selectedModule = {
        id: selectedProgram.id!.toString(),
        name: selectedProgram.name,
        value: selectedProgram?.totalHours,
        subModules: selectedProgram.modules.map((module) => ({
          id: uuid(),
          name: module.name,
          value: module.hours,
          subModules: module.subModules.map((subModule) => ({
            id: uuid(),
            name: subModule.name,
            value: subModule.hours,
          })),
        })),
      };
      setModule(selectedModule as Module);
    }
  };

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setActive((prev) => !prev);
  };

  const handleClick = (item: Module): void => {
    if (module.name) {
      if (item.id === module.id) {
        setModule({
          ...module,
          subModules: [
            ...module.subModules,
            { id: uuid(), name: "", value: 0, subModules: [] },
          ],
        });
      } else {
        if (!item.name) {
          setAlertOpen(true);
          setAlertInside({
            text: "İlk öncə modul adını yazın",
            severity: "warning" as AlertColor,
          });
        } else {
          const subModuleToAdd: Module = {
            id: uuid(),
            name: "",
            value: 0,
            subModules: [],
          };
          const subModulesCopy: Module[] = [...module.subModules];
          const indexToUpdate: number = subModulesCopy.findIndex(
            (subModule) => subModule.id === item.id
          );
          subModulesCopy[indexToUpdate].subModules.push(subModuleToAdd);
          setModule({ ...module, subModules: subModulesCopy });
        }
      }
    } else {
      setAlertOpen(true);
      setAlertInside({
        text: "İlk öncə proqram adını seçin",
        severity: "warning" as AlertColor,
      });
    }
  };

  const handleChangeFields = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: Module
  ) => {
    const updatedSubModules = module.subModules.map((subModule) => {
      if (subModule.id === item.id) {
        return { ...subModule, name: e.target.value };
      } else {
        subModule.subModules.forEach((subModuleItem) => {
          if (subModuleItem.id === item.id) {
            subModuleItem.name = e.target.value;
          }
        });
        return subModule;
      }
    });
    setModule({ ...module, subModules: updatedSubModules });
  };

  const handleChangeFieldsValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: Module
  ) => {
    const regex: RegExp = /[^\d]/g;
    const newValue: string = e.target.value.replace(regex, "");

    const updatedSubModules: Module[] = module.subModules.map((subModule) => {
      if (subModule.id === item.id) {
        return { ...subModule, value: Number(newValue) };
      } else {
        subModule.subModules.forEach((subModuleItem) => {
          if (subModuleItem.id === item.id) {
            subModuleItem.value = Number(newValue);
          }
        });
        return subModule;
      }
    });
    setModule({ ...module, subModules: updatedSubModules });
  };

  const handleDelete = (item: Module): void => {
    if (module.subModules.includes(item)) {
      setModule({
        ...module,
        subModules: module.subModules.filter((q) => q.id !== item.id),
      });
    } else {
      setModule({
        ...module,
        subModules: [
          ...module.subModules.map((q) => {
            return {
              ...q,
              subModules: q.subModules.filter((el) => {
                return el.id !== item.id;
              }),
            };
          }),
        ],
      });
    }
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  
    let postModel = {
      programId: module.id.toString(),
      modules: module.subModules.map((subModule, moduleIndex) => {
        return {
          name: subModule.name,
          version: `${moduleIndex + 1}`,
          hours: subModule.value,
          subModules: subModule.subModules.map((sub, subIndex) => {
            return {
              name: sub.name,
              hours: sub.value,
              version: `${moduleIndex + 1}.${subIndex + 1}`,
            };
          }),
        };
      }),
    };

    setStatus(true);
    moduleService
      .add(postModel)
      .then((res) => {
        if (res.status) {
          setAlertOpen(true);
          setAlertInside({
            text: "Program yadda saxlanıldı",
            severity: "success" as AlertColor,
          });
          setModule({ id: "", name: "", value: 0, subModules: [] });
          dispatch(fetchPrograms());
        } else {
          setAlertOpen(true);
          setAlertInside({
            text: "Nə isə düz getmədi!",
            severity: "error" as AlertColor,
          });
        }
      })
      .catch((err) => {

        setAlertOpen(true);
        setAlertInside({
          text: "Nə isə düz getmədi!",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setStatus(false);
      });
  };
  return (
    <>
        <FormLayout>
          <Header complete={complete} />

          {status ? (
            <Progress />
          ) : (
            <>
              <Box
                component={"form"}
                id="myform"
                onSubmit={handleSubmit}
                maxWidth={"680px"}
              >
                <Box
                  marginBottom={"24px"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Box marginRight={"16px"} sx={{ minWidth: 120 }}>
                    <FormControl sx={{ width: 308 }}>
                      <InputLabel id="demo-simple-select-label">
                        Program adı
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={module.name}
                        label="Program adı"
                        onChange={handleChange}
                      >
                        {React.Children.toArray(
                          allPrograms.length > 0 &&
                            allPrograms.map((option) => (
                              <MenuItem value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <TextField
                    sx={{ width: 308, marginRight: "16px" }}
                    value={module.name && module.value}
                    disabled
                    id="outlined-basic"
                    label="Ümumi saat"
                    variant="outlined"
                  />

                  <Tooltip
                    placement="right"
                    arrow
                    title="Modul əlavə et"
                    PopperProps={{ sx: { muiTooltipSx } }}
                  >
                    <Box onClick={() => handleClick(module)} sx={plusIcon}>
                      +
                    </Box>
                  </Tooltip>
                </Box>

                {module.subModules.length > 0 &&
                  React.Children.toArray(
                    module.subModules.map((item, index) => (
                      <>
                        <Box
                          marginBottom={"24px"}
                          alignItems={"center"}
                          display={"flex"}
                        >
                          <Box sx={indexNumber}>{index + 1}</Box>
                          <TextField
                            sx={{ width: 260, marginRight: "16px" }}
                            required
                            defaultValue={item.name}
                            key={`${item.id}`}
                            id="outlined-basic"
                            label={`Modul ${index + 1}`}
                            variant="outlined"
                            onBlur={(e: any) => handleChangeFields(e, item)}
                          />

                          <TextField
                            sx={{ width: 260, marginRight: "16px" }}
                            required
                            defaultValue={item.value}
                            key={`${item.value}`}
                            id="outlined-basic"
                            label="Saat"
                            variant="outlined"
                            onKeyUp={(e: any) =>
                              handleChangeFieldsValue(e, item)
                            }
                            autoFocus
                            type="number"
                            inputProps={{ min: 1 }}
                          />

                          <Tooltip
                            placement="right"
                            arrow
                            title="Modul əlavə et"
                            PopperProps={{ sx: { muiTooltipSx } }}
                          >
                            <Box
                              onClick={() => handleClick(item)}
                              sx={[plusIcon, { marginRight: "16px" }]}
                            >
                              +
                            </Box>
                          </Tooltip>

                          <Tooltip
                            placement="right"
                            arrow
                            title="Modulu sil"
                            PopperProps={{ sx: { muiTooltipSx } }}
                          >
                            <Box
                              sx={deleteIcon}
                              onClick={() => {
                                handleDeleteConfirmationOpen();
                                setDeletedElement(item);
                              }}
                            >
                              <DeleteOutlineIcon sx={deleteOutlineIcon} />
                            </Box>
                          </Tooltip>
                        </Box>
                        {item.subModules.length > 0 &&
                          React.Children.toArray(
                            item.subModules.map((subItem, subItemIndex) => (
                              <Box
                                justifyContent={"center"}
                                marginBottom={"24px"}
                                alignItems={"center"}
                                display={"flex"}
                              >
                                <Box sx={indexNumber}>
                                  {index + 1}.{subItemIndex + 1}
                                </Box>
                                <TextField
                                  sx={{ width: 236, marginRight: "16px" }}
                                  required
                                  defaultValue={subItem.name}
                                  key={`${subItem.id}`}
                                  id="outlined-basic"
                                  label="Alt modul"
                                  variant="outlined"
                                  onBlur={(e: any) =>
                                    handleChangeFields(e, subItem)
                                  }
                                />

                                <TextField
                                  sx={{ width: 236, marginRight: "16px" }}
                                  required
                                  defaultValue={subItem.value}
                                  key={`${subItem.value}`}
                                  id="outlined-basic"
                                  label="Saat"
                                  variant="outlined"
                                  onKeyUp={(e: any) =>
                                    handleChangeFieldsValue(e, subItem)
                                  }
                                  autoFocus
                                  type="number"
                                  inputProps={{ min: 1 }}
                                />

                                <Tooltip
                                  placement="right"
                                  arrow
                                  title="Modulu sil"
                                  PopperProps={{ sx: { muiTooltipSx } }}
                                >
                                  <Box
                                    sx={deleteIcon}
                                    onClick={() => {
                                      handleDeleteConfirmationOpen();
                                      setDeletedElement(subItem);
                                    }}
                                  >
                                    <DeleteOutlineIcon sx={deleteOutlineIcon} />
                                  </Box>
                                </Tooltip>
                              </Box>
                            ))
                          )}
                      </>
                    ))
                  )}
              </Box>

              <FormControlLabel
                sx={{
                  marginLeft: "9px",
                  marginTop: "25px",
                  marginBottom: "44px",
                }}
                control={
                  <Switch checked={active} onChange={handleChangeSwitch} />
                }
                label="Aktivdir"
              />
            </>
          )}
          <Footer />
        </FormLayout>

      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}

      {deleteConfirmationOpen && (
        <ConfirmModal
          open={deleteConfirmationOpen}
          handleClose={handleDeleteConfirmationClose}
          handleAgree={() => handleDelete(deletedElement)}
          text={"Seçilmiş modulu silmək istədiyinizə əminsiniz?"}
          isWarning={false}
          sendBtnText="Sil"
        />
      )}
    </>
  );
}

export default AddModule;
