import Chip from '@mui/material/Chip';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Months } from '../../../../../../types';


export interface IUnmarkedAttendanceChip{
    date: string,
    url: string,
    classId: string
}

export default function UnmarkedAttendanceChip({date, url, classId} : IUnmarkedAttendanceChip) {
    const navigate = useNavigate();
    const handleUnMarked = () => {
        navigate(url);
    }
    const [innerDate, setInnerDate] = useState('');

    useEffect(()=> {
      const year = date.split('-')[0];
      const month = +(date.split('-')[1]);
      const day = date.split('-')[2];
      setInnerDate(`${day} ${Object.keys(Months)[month-1] as (keyof typeof Months)} ${year}`)
    }, [date])

  return (
    <Link to={`/admin/app/classes/${classId}/class-sessions/${date}`}>
      <Chip 
          onDelete={handleUnMarked}
          deleteIcon={<ModeEditOutlineSharpIcon fontSize='small'/>}
          label={innerDate}
          style={{backgroundColor:'#fbecea', color: 'black', fontWeight: '700', borderRadius: '12px', marginRight: '10px', cursor: 'pointer'}} />
    
    </Link>
  )
}
