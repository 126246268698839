export const sxDrawerHeader: React.CSSProperties = {
  height: "76px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 28px 0px 28px",
  marginBottom: "16px",
};

export const sxDrawer = {
  "& .MuiDrawer-paper": {
    position: "sticky",
    border: "none",
    padding: 0,
    borderRight: "1px solid #E9E9E9",
    height: "100vh",
    visibility: "visible !important",
    cursor: "pointer",
    // overflow: "hidden !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};
