import React from 'react'
import { Box } from '@mui/material';
import PageHeader from '../../../../../../../components/custom-components/page-header';
import Breadcrumbs from '../../../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import PageTitle from '../../../../../../../components/custom-components/page-title';
import { APP_PREFIX_PATH } from '../../../../../../../configs/Appconfig';
import Select from '../../../../../../../components/core-components/inputs/select';

interface PropsModel {
    programs: string[],
    program:string,
    setProgram:React.Dispatch<React.SetStateAction<string>>
}

function Header(props: PropsModel) {

    const { programs,program,setProgram } = props

    return (
        <>
            <PageHeader>
                <Box>
                    {/* <Breadcrumbs>
                        <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
                        <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
                        <BreadcrumbsTypography name="Proqram təyin et" />
                    </Breadcrumbs> */}
                    <PageTitle name="Proqram təyin et" />
                </Box>
                <Box>
                    <Select
                        name="program"
                        selectElement={program}
                        setSelectElement={setProgram}
                        selectElements={programs.map((item:string , index) => ({
                            id: index.toString(),
                            label: item,
                          }))}
                        disabled={false}
                    />
                </Box>
            </PageHeader>
        </>
    )
}

export default Header