import React from "react";
import { Box } from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";

function Errors({ message }: any) {
  return (
    <>
      {" "}
      <Box
        my={1}
        sx={{
          backgroundColor: " #FBEDEB",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          color: "#D54939",
          gap: "10px",
        }}
      >
        {" "}
        <WarningIcon sx={{ height: "20px" }} />
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="subtitle1"
        >
          {message}
        </Typography>
      </Box>
    </>
  );
}

export default Errors;
