import React, { useContext, useState } from "react";
import {
  AppBar,
  Avatar,
  Typography,
  TextField,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  sxHeader,
  sxSearchIcon,
  sxSearch,
  sxUser,
  sxAvatar,
  sxUserName,
  sxAccount,
  sxVideo,
} from "./style";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import BaseIcon from "../../custom-components/base-icon";
import { useSelector } from "react-redux";
import { logoutAuth } from "../../../store/reducers/authReducer";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Progress from "../../custom-components/progress";
import { useDispatch } from "react-redux";
import axios from "axios";
import { BaseService } from "../../../network/services/base/BaseService";
import { API_URL } from "../../../configs/Appconfig";
import { useBanner } from "../../../contexts/BannerContext";
import NotificationsDropdown from "./notificationsDropdown";
export interface AuthState {
  auth: {
    isLoggedIn: boolean;
    isInitialized: boolean;
    user: {
      id: number;
      name: string;
      surname: string;
      roleName: string[];
    } | null;
    isLoading: boolean;
  };
}

function HeaderNav() {
  const authContext = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationsCount, setNotificationsCount] = useState<number | null>(null);
  const { logout } = authContext ?? {};
  const navigate = useNavigate();
  const user = useSelector((state: AuthState) => state.auth.user);
  const dispatch = useDispatch();
  const logoutService = new BaseService("api/Auth/logout");
  const isLoggedIn = useSelector((state: AuthState) => state.auth.isLoggedIn);
  const isLoading = useSelector((state: AuthState) => state.auth.isLoading);
  const handleClick = () => {
    if (logout !== undefined) {
      // logoutService.add({});
      try {
        axios
          .post(API_URL + "api/Auth/logout", undefined, {
            withCredentials: true,
          })
          .then((res) => {
            logout();
            navigate("/auth/login", { state: { rerender: true } });
          });
      } catch (err) {
        console.error(err);
        dispatch(logoutAuth());
      } finally {
      }
    }
  };
  const handleVideoClick = () => {
    window.open(
      "https://drive.google.com/file/d/10pN2-9tBpdeS8KNHvoWT-4fkjj0h1YN7/view?usp=sharing",
      "_blank"
    );
  };

  const handleNotificationOpen = (event: any) => {
    if(notificationsCount != null){
      setNotificationAnchorEl(event.currentTarget);
    }
  }

  return (
    <>
      <AppBar sx={sxHeader}>
        <Box sx={sxSearch}>
          <SearchIcon color={"primary"} sx={sxSearchIcon} />
          <TextField
            id="standard-basic"
            variant="standard"
            placeholder="Search..."
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "60px",
          }}
        >
          <Box onClick={handleVideoClick} sx={{...sxVideo,
             '&:hover': {
              background: 'linear-gradient(95deg,#6F30FF 0%,#30A5FF 100%)',
              '& .typography': { 
                color:"var(--Natural-natural-15, #F7F7F7)",
              },
            }
          }}>
            <img src="/icons/video.svg" />
            <Typography
              className="typography"
              sx={{
                fontSize: "14px",
                fontFamily: "Visby CF Bold, sans-serif",
                color: "#0E0D0D",
                lineHeight: "20px",
                letterSpacing: "0.4px",           
              }}
            >
              Videonu izlə
            </Typography>
          </Box>
          <Box sx={{...sxUser, position: 'relative'}}>
            <BaseIcon onClick={(event: any)=> handleNotificationOpen(event)}>
              {
                (notificationsCount != null && notificationsCount !== 0) && (
                  <span 
                  style={{
                    position: 'absolute', 
                    width: '20px', 
                    height: '20px', 
                    borderRadius: '50%', 
                    backgroundColor: '#d65345', 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '-5px',
                    left: '-7px'
                  }}>{notificationsCount}</span>
                )
              }
             
              <NotificationsNoneIcon color={"primary"} />
            </BaseIcon>
            <Box
              sx={sxAccount}
              onClick={(event: any) => setAnchorEl(event.currentTarget)}
            >
              <Avatar
                sx={sxAvatar}
                src=""
                alt={`${user?.name} ${user?.surname}`}
              />
              {isLoggedIn && (
                <Typography variant="h2" sx={sxUserName}>
                  {user?.name} {user?.surname}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </AppBar>
      <NotificationsDropdown notificationAnchorEl={notificationAnchorEl} setNotificationAnchorEl={setNotificationAnchorEl} setCount={setNotificationsCount}/>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          sx={{
            minWidth: 240,
            maxWidth: 360,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <PersonOutlineOutlinedIcon
                sx={{ width: "20px", height: "20px" }}
                color={"primary"}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: "12px" }}
              primary="Profil məlumatları"
            />
          </ListItemButton>
          <Box sx={{ padding: "0px 16px" }}>
            <Divider />
          </Box>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <LogoutOutlinedIcon
                sx={{ width: "20px", height: "20px", color: "#D75445" }}
              />
            </ListItemIcon>
            <ListItemText sx={{ fontSize: "12px" }} primary="Çıxış" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}

export default HeaderNav;
