import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Class, Student } from "./models/StudentModels";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";
import { GridColDef } from "@mui/x-data-grid";
import { localeText } from "../../../../configs/Locales";
import { StudentAttendance } from "../continuity-result-students/models/StudentAttendance";
import { ModalData } from "../continuity-result-students/models/ModalData";
import { Dialog, Slide, TextField } from "@mui/material";
import Modal from "../continuity-result-students/components/modal";
import { TransitionProps } from "@mui/material/transitions";
import { StudentAbsents } from "../continuity-result-students/models/StudentAbsents";
import DataGrid from "../../../../components/custom-components/data-grid";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StudentsList() {
  const [selectedStudentDetail, setSelectedStudentDetail] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [studentAbsentsDetail, setStudentAbsentsDetail] = useState<
    StudentAbsents[]
  >([]);

  const [students, setStudents] = useState<Student[]>([]);
  const studentService = new BaseService("/api/Students");
  const [modalData, setModalData] = useState<ModalData[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [studentAttendance, setStudentAttendance] = useState<StudentAttendance>(
    {
      id: "",
      name: "",
      surname: "",
      phone: "",
      eMail: "",
      attendancePercent: 0,
      attendances: [],
    }
  );

  const getStudentDetails = async (classId: string, studentId: string) => {
    setIsLoading(true);
    const studentDetailsService = new BaseService(
      `/api/Classes/${classId}/students-details`
    );
    studentDetailsService.getAll().then((res) => {
      const selectedStudent = res.data.find(
        (item: any) => item.studentId === studentId
      );
      setSelectedStudentDetail(selectedStudent);
      setIsLoading(false);
    });
  };

  const getStudentAttendance = (studentId: string, id: string) => {
    setModalLoading(true);
    const studdentAttendanceService = new BaseService(
      `/api/Students/${studentId}/attendances?classId=${id}`
    );
    studdentAttendanceService
      .getAll()
      .then((res) => {
        setStudentAttendance(res.data);

        setModalData(
          res.data.attendances.map((attendance: ModalData, index: number) => ({
            ...attendance,
            id: index,
          }))
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoading(false));
  };

  const getStudents = async () => {
    setLoading(true);
    try {
      const res = await studentService.getAll();
      setStudents(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getStudents();
  }, []);
  if (loading) {
    return <Progress />;
  }
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Ad Soyad",
      flex: 1,
      renderCell: (params) => `${params.value} ${params.row.surname}`,
    },

    {
      field: "eMail",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefon",
      flex: 1,
    },

    {
      field: "classes",
      headerName: "Qrup",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleClick = (classItem: Class) => {
          getStudentAttendance(params.row.id, classItem.id);
          setOpenModal(true);
          getStudentDetails(classItem.id, params.row.id);
        };
        //  onRowClick={(rows: { id: string; row: any }) => {
        //   const filtered: any = classes.filter(
        //     (item) => item.name === rows.row.className
        //   );
        //   getStudentAttendance(rows.id, filtered[0].id);
        //   handleClickOpen();
        //   setSelectedStudentDetail(rows.row);
        // }}
        const { classes } = params.row;
        return (
          <>
            {classes.map((classItem: Class) => {
              return (
                <React.Fragment key={classItem.id}>
                  <p
                    onClick={() => handleClick(classItem)}
                    style={{
                      margin: "0 5px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    {classItem.name}
                  </p>
                </React.Fragment>
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <>
        <Header />
        <DataGrid
          data={students.filter((row) =>
            `${row.name} ${row.surname}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )}
          columnsData={columns}
          pageSizeData={pageSize}
          setPageSize={setPageSize}
          disableColumnMenu={false}
          localeText={localeText}
          components={{
            Toolbar: () => (
              <TextField
                autoFocus
                variant="standard"
                placeholder="Tələbə axtar.."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.trimStart())}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              labelRowsPerPage: "Səhifədəki sətir sayı:",
            },
          }}
        />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        <Modal
          selectedStudentDetail={selectedStudentDetail}
          studentAttendance={studentAttendance}
          modalData={modalData}
          modalLoading={modalLoading}
        />
      </Dialog>
    </>
  );
}

export default StudentsList;
