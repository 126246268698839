import React from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";

function Header() {
  return (
    <>
      <Box
        marginBottom={"24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          {/* <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Tələbələr sırala" />
          </Breadcrumbs> */}
          <PageTitle name="Tələbələr" />
        </Box>
      </Box>
    </>
  );
}

export default Header;
