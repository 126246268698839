import React from 'react'
import { Box, Typography } from '@mui/material';
import { APP_PREFIX_PATH } from '../../../../../../configs/Appconfig';
import Breadcrumbs from '../../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsTypography from '../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import BreadcrumbsLink from '../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import PageTitle from '../../../../../../components/custom-components/page-title';
import Button from '../../../../../../components/core-components/inputs/button';
import PageHeader from '../../../../../../components/custom-components/page-header';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { sxFilter } from './style';

interface PropsModel {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function Header(props: PropsModel) {
    const { setOpen } = props
    return (
        <>
            <PageHeader>
                <Box>
                    {/* <Breadcrumbs>
                        <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
                        <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
                        <BreadcrumbsTypography name="Davamiyyət göstəriciləri" />
                    </Breadcrumbs> */}
                    <Box>
                        <PageTitle name="Davamiyyət göstəriciləri" />
                        <Typography sx={{ fontSize: "14px" }} component={"span"}>
                            Göstərilir: <Typography sx={{ fontSize: "14px", fontFamily: 'Visby CF Bold, sans-serif',}} component={"span"}>Mart</Typography>
                        </Typography>
                    </Box>

                </Box>
                <Box sx={{ display: "flex", gap: "16px" }}>
                    <Button>
                        Excel fayl kimi yüklə
                    </Button>
                    <Button
                        style={sxFilter}
                        onClick={() => setOpen(true)}
                    >
                        <FilterAltOutlinedIcon
                            sx={{
                                width: "20px",
                                height: "20px"
                            }}
                            color="primary"
                        />
                        Filter
                    </Button>
                </Box>
            </PageHeader>
        </>
    )
}

export default Header