import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { BaseService } from "../../../../../network/services/base/BaseService";
import { WorkerDetails, WorkerClassDetails } from "./models/workerDetails";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { localeText } from "../../../../../configs/Locales";
import Progress from "../../../../../components/custom-components/progress";
import { Box, Dialog, Slide, TextField, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Modal from "./components/Modal";
import { TeacherDetails } from "./models/ReportModels";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { WorkerByClassDetails } from "./models/ClassModels";
import ClassDetailsModal from "./components/ClassDetailsModal";
import DataGrid from "../../../../../components/custom-components/data-grid";
import { sxEditIcon } from "../../groups/style";
import { sxBoxCenter } from "../../../HumanResourcesManagement/Holidays/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmModal from "../../../../../components/custom-components/confirm-modal";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function List() {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [classOpen, setClassOpen] = useState<boolean>(false);
  const [workerId, setWorkerId] = useState<string>("");
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [classData, setClassData] = useState<WorkerByClassDetails>({
    classId: "",
    className: "",
    offlineHours: 0,
    onlineHours: 0,
    cancaledHours: 0,
    workerClassSessions: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [workerServices, setWorkerServices] = useState<WorkerDetails[]>([]);
  const [modalData, setModalData] = useState<TeacherDetails>({
    name: "",
    surname: "",
    eMail: "",
    attendancePercent: 0,
    totalHours: 0,
    canceledHours: 0,
    completedHours: 0,
    totalClasses: 0,
    completedClasses: [],
    unCompletedClasses: [],
  });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const workerDetailsService = new BaseService("/api/Workers/with-details");
  const workerDeleteService = new BaseService(`/api/Workers`);

  const handleClose = () => setOpen(false);
  const handleOpen = (params: any) => {
    setOpen(true);
    setWorkerId(params.id);
    getTeacherReport(params.id);
  };
  const getWorkerService = async () => {
    setLoading(true);
    const res = await workerDetailsService.getAll();
    setWorkerServices(res.data);
    setLoading(false);
  };

  const getClassesDetails = async (id: string) => {
    setModalLoading(true);
    const ClassDetailsService = new BaseService(
      `/api/Workers/${id}/worker-class-sessions-by-class`
    );
    try {
      const res = await ClassDetailsService.getAll();

      setClassData(res.data);
    } catch (error) {
      console.error(error);
      setClassData({
        classId: "",
        className: "",
        offlineHours: 0,
        onlineHours: 0,
        cancaledHours: 0,
        workerClassSessions: [],
      });
    } finally {
      setModalLoading(false);
    }
  };
  const getTeacherReport = async (id: string) => {
    setModalLoading(true);
    const reportService = new BaseService(
      `/api/Workers/${id}/get-worker-general-report`
    );
    try {
      const res = await reportService.getAll();
      setModalData(res.data);
    } catch (error) {
      console.error(error);
      setModalData({
        name: "",
        surname: "",
        eMail: "",
        attendancePercent: 0,
        totalHours: 0,
        canceledHours: 0,
        completedHours: 0,
        totalClasses: 0,
        completedClasses: [],
        unCompletedClasses: [],
      });
    } finally {
      setModalLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await workerDeleteService.delete(id);
      getWorkerService();
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteConfirmationOpen(false);
      setLoading(false);
    }
  };
  const handleCloseConfirmModal = () => {
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    getWorkerService();
  }, []);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Ad Soyad",
      flex: 1,
      renderCell: (params) => {
        return (
          <p
            style={{
              margin: "5px",
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
            onClick={() => handleOpen(params)}
          >
            {params.row.name} {params.row.surname}
          </p>
        )
      },
    },
    {
      field: "eMail",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "activeClasses",
      headerName: "Aktiv siniflər",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const { workerClasses } = params.row;
        const activeClasses = workerClasses.filter(
          (c: WorkerClassDetails) => c.isOpen
        );
        return activeClasses.map((c: WorkerClassDetails) => (
          <p
            style={{
              margin: "5px",
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
            key={c.className}
            onClick={() => {
              setClassOpen(true);
              getClassesDetails(c.classId);
            }}
          >
            {c.className}
          </p>
        ));
      },
    },
    {
      field: "operations",
      headerName: "Əməliyyatlar",
      flex: 0.4,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box sx={sxBoxCenter}>
            <Tooltip title="Düzəliş et">
              <Link to={`${APP_PREFIX_PATH}/worker-classes/${params.row.id}`}>
              <EditOutlinedIcon color={"secondary"} sx={sxEditIcon} />
              </Link>
            </Tooltip>
            <Tooltip title="Sil">
              <Box
                onClick={() => {
                  setDeleteConfirmationOpen(true);
                  setId(params.row.id);
                }}
              >
                <DeleteOutlineIcon color={"secondary"} sx={sxEditIcon} />
              </Box>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  if (loading) {
    return <Progress />;
  }

  return (
    <>
        <Header />
        <DataGrid
          data={workerServices.filter((row) =>
            `${row.name} ${row.surname}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )}
          columnsData={columns}
          pageSizeData={pageSize}
          setPageSize={setPageSize}
          disableColumnMenu={false}
          localeText={localeText}
          components={{
            Toolbar: () => (
              <TextField
                autoFocus
                variant="standard"
                placeholder="İşçi axtar.."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.trimStart())}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              labelRowsPerPage: "Səhifədəki sətir sayı:",
            },
          }}
        />
        {deleteConfirmationOpen && (
          <ConfirmModal
            open={deleteConfirmationOpen}
            handleClose={handleCloseConfirmModal}
            handleAgree={() => handleDelete()}
            text={"Seçilmiş işçini silmək istədiyinizə əminsiniz?"}
            isWarning={false}
            sendBtnText="Sil"
          />
        )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        <Modal modalData={modalData} modalLoading={modalLoading} />
      </Dialog>
      <Dialog
        open={classOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        <ClassDetailsModal modalData={classData} modalLoading={modalLoading} />
      </Dialog>
    </>
  );
}

export default List;
