import React, { createContext, useState, useEffect, useContext, FC } from 'react';
type BannerVisibility = boolean;

interface IBannerContext {
  bannerVisible: BannerVisibility;
  toggleBannerVisibility: () => void;
}
const BannerContext = createContext<IBannerContext | null>(null);

const BannerProvider= ({ children }: { children: React.ReactNode }) => {
  const [bannerVisible, setBannerVisible] = useState<BannerVisibility>(() => {
    const storedValue = localStorage.getItem('bannerVisible');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    localStorage.setItem('bannerVisible', JSON.stringify(bannerVisible));
  }, [bannerVisible]);

  const toggleBannerVisibility = () => {
    setBannerVisible(true);
  };

  const contextValue = {
    bannerVisible,
    toggleBannerVisibility
  };

  return (
    <BannerContext.Provider value={contextValue}>
      {children}
    </BannerContext.Provider>
  );
};

const useBanner = (): IBannerContext => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error('useBanner must be used within a BannerProvider');
  }
  return context;
};

export { BannerProvider, useBanner };
