import Breadcrumbs from "../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageHeader from "../../../../../../components/custom-components/page-header";
import { Box } from "@mui/material";
import PageTitle from "../../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import Button from "../../../../../../components/core-components/inputs/button";
import { ChangeableStatus } from "../../../models";

interface HeaderProps {
  setIsChangeable: React.Dispatch<React.SetStateAction<ChangeableStatus>>;
  isChangeable: ChangeableStatus;
}
function Header(props: HeaderProps) {
  const { setIsChangeable, isChangeable } = props;
  return (
    <>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Permision levels" />
          </Breadcrumbs>
          <PageTitle name="Permision levels" />
          <Box></Box>
        </Box>
        {(!isChangeable.isEditPermissionLevel && !isChangeable.isCreatePermissionLevel)? (
          <Button
            onClick={() =>
              setIsChangeable((oldValue) => ({
                ...oldValue,
                isEditPermissionLevel: true,
              }))
            }
          >
            <img
              src="/icons/edit-pencil.svg"
              alt="edit Icon"
              style={{ marginRight: "8px" }}
            />
            Redaktə et
          </Button>
        ) : (
          <></>
        )}
      </PageHeader>
    </>
  );
}

export default Header;
