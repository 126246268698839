import {
  Toolbar,
  Grid,
  Typography,
  AppBar,
  Box,
  IconButton,
  TextField,
  Stack,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocomplete from './custom-autocomplete';
import { sxField } from '../../../../MrpManagement/mrp/components/modal/style';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FilterSlider from './filterSlider';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux';
import {change} from '../../../../../../store/reducers/groupFilterReducer';
import {filterHeaderStyle, groupStatusSx, groupStatusCircle} from '../../style';
import {IFilterModel, groupStatuses, studentCount} from './type';
import ErrorDialogue from './error-dialogue';
import { useState } from 'react';

export default function Index({ setFilter }: any) {
  const dispatch = useDispatch()
  const defaultFilters = useSelector((state: any) => state.groupFilter.filters)
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
  } = useForm<IFilterModel>()
  const onSubmit: SubmitHandler<IFilterModel> = (data) => {
    let isEmpty = true;
    for(let key in data){
      if((data as any)[key] !== undefined && (data as any)[key] !== '' && (data as any)[key] !== null){
        isEmpty = false;
        break;
      }
    }
    if(isEmpty){
      setDialogueOpen(true)
    }
    else{
      dispatch(change(data))
    }
  }

  const onFilterReset = () => {
    dispatch(change({}));
  }

  const closeDialogue = () => {
    setDialogueOpen(false);
  }

  return (
    <Box component='form' sx={{ width: '480px', position: 'relative', pt: 0 }} onSubmit={handleSubmit(onSubmit)}>
      <AppBar
        position="sticky"
        color="transparent"
        sx={{ boxShadow: 'none', backgroundColor: 'white', top: '-10px' }}
      >
        <Toolbar sx={{ pl: 0, pr: 0 }}>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              flexGrow: 1,
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            Filter
          </Typography>
          <IconButton aria-label="delete" onClick={() => setFilter(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container sx={{ px: 4 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Proqram
          </Typography>
          <CustomAutocomplete label="Proqram seçin" url="Programs" control={control} name='programId' defaultValue={defaultFilters.programId} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Tələbə sayı
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Buna bərabər"
                customData={studentCount}
                control={control}
                name='studyCountStatus'
                defaultValue={defaultFilters.studyCountStatus}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField {...register("studyCount")} label="Sayı" sx={sxField} type="number" defaultValue={defaultFilters.studyCount} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Tarix aralığı
          </Typography>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction={'row'} spacing={'28px'}>
              <Controller
                defaultValue={defaultFilters.startDate}
                control={control}
                name="startDate"
                render={({ field }) => (
                  <DatePicker
                    defaultValue={defaultFilters.startDate}
                    label={'Tarixdən'}
                    sx={sxField}
                    format="DD MMMM YYYY hh:mm "
                    components={{
                      OpenPickerIcon: CalendarTodayOutlinedIcon,
                    }}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                  />
                )}
                />
                <Controller
                defaultValue={defaultFilters.endDate}
                 control={control}
                 name="endDate"
                 render={({ field }) => (
                  <DatePicker
                    components={{
                      OpenPickerIcon: CalendarTodayOutlinedIcon,
                    }}
                    defaultValue={defaultFilters.endDate}
                    format="DD MMMM YYYY hh:mm "
                    sx={sxField}
                    label="Tarixə"
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                  />
                )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Qrupun statusu
          </Typography>
          <Controller
          defaultValue={defaultFilters.status ? defaultFilters.status : ''}
          control={control}
          name="status"
          render={({ field }) =>(
            <RadioGroup
              {...field}
              defaultValue={defaultFilters.status}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              {
                groupStatuses.map((item, key)=> (
                  <Box key={key} sx={groupStatusSx}>
                      <FormControlLabel control={<Radio />} value={item.value} label='' sx={{borderRadius: '0'}} />
                      <span style={{...groupStatusCircle, backgroundColor: item.bgColor}}></span>
                      <Typography component="p">
                        {item.name}
                      </Typography>

                  </Box>
                ))
              }
              </RadioGroup>

            )}
            />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Davamiyyət saatı
          </Typography>
          <Box>
          <Controller
            defaultValue={defaultFilters.endAttendancePercentage}
            control={control}
            name="startAttendancePercentage"
            render={() => (
              <FilterSlider
                onChange={(_, value) => {
                  setValue("startAttendancePercentage",(value as any)[0])
                  setValue("endAttendancePercentage",(value as any)[1])
                }}
                defaultValue={[defaultFilters.startAttendancePercentage, defaultFilters.endAttendancePercentage]}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                getAriaLabel={() => 'attendanceHour'}
              />
            )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Müəllim
          </Typography>
          <CustomAutocomplete label="Müəllimi seçin" url="Workers?role=muellim" control={control} name='teacherId' defaultValue={defaultFilters.teacherId} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography component="p" sx={filterHeaderStyle}>
            Mentor/ Müəllim köməkçisi
          </Typography>
          <CustomAutocomplete
            label="Mentor/ Müəllim köməkçisi seçin"
            url="Workers?role=mentor"
            control={control}
            name='mentorId'
            defaultValue={defaultFilters.mentorId}
          />
        </Grid>
      </Grid>
      <Box sx={{position: 'sticky', bottom: '-10px', backgroundColor: 'white', width: '100%', zIndex: '100'}}>
        <Grid container sx={{ px: 4, py: '32px', backgroundColor: 'white', width: '100%' }}>
            <Button type="submit" color='primary' variant='contained' sx={{fontFamily: "Visby CF Bold, sans-serif",fontSize: '16px', fontWeight: '700', lineHeight: '24px', px: '24px', py: '12px', borderRadius: '12px', mr: 3, textTransform: "none",}}>Tətbiq et</Button>
            <Button onClick={() => onFilterReset()} color='primary' sx={{fontFamily: "Visby CF Bold, sans-serif",background:'#F3EDF4',fontSize: '16px', fontWeight: '700', lineHeight: '24px', px: '24px', py: '12px', borderRadius: '12px',textTransform: "none",color:"#0E0D0D"}}>Filteri təmizlə</Button>
        </Grid>
      </Box>
      <ErrorDialogue isOpen={dialogueOpen} changeOpen={closeDialogue}/>
    </Box>
  );
}
