export const sxContainer={
    width:"100%",
    height:"127px",
    padding:"16px 32px",
    borderRadius:"16px",
    display: "flex",
    justifyContent:"space-between",
    alignItems:"center",
    background:"var(--Additional-Blue-blue-50, #EAF6FF)",
    position:"relative",
    marginBottom: "20px",
    
}
export const sxLabTitle={
    fontFamily: 'Visby CF Bold, sans-serif',
    fontSize:"34px",
    lineHeight:"34px",
    letterSpacing:"0.4px",
    backgroundImage: 'linear-gradient(74deg,#30A5FF 22.3%,#6F32FF 76.14%)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    color: 'transparent', 
  
}