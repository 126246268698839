import { Box} from "@mui/material";
import { sxBox } from "./style";
import Button from "@mui/material/Button";
interface FooterProps {
    handleClose: () => void;
    handleClick:()=>void;
  }
function Footer(FooterProps:FooterProps) {
    const {handleClose,handleClick}=FooterProps;
    return ( <Box sx={sxBox}>
       <Button
            className="saveBtn"
            sx={{
              background: "#854693",
              color: "#FFF",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "12px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
            onClick={handleClick}
          >
            Yadda saxla
          </Button>
          <Button
            sx={{
              background: "#F3EDF4",
              color: "#0E0D0D",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "12px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              "&:hover": {
                background: "#D9C6DE",
              },
            }}
            onClick={handleClose}
          >
            Ləğv et
          </Button>
    </Box> );
}
export default Footer;