import { useState, forwardRef } from 'react';
import {Autocomplete, Chip, TextField} from '@mui/material';
import { BaseService } from '../../../../../../network/services/base/BaseService';
import { Controller } from "react-hook-form";

export interface ICustomAutocompleteModel{
    label: string;
    url?: string;
    customData?: any;
    control: any;
    name: string;
    defaultValue: any;
    sx?: any;
}

const CustomAutocomplete = forwardRef(function CustomAutocomplete({label, url, customData, control, name, defaultValue, sx} : ICustomAutocompleteModel, ref: React.Ref<unknown>) {
    const [data, setData] = useState(customData ? customData : []);

    const fetchData = async () => {
        try {
          const service = new BaseService(`api/`);
          const res = await service.getAll(
            `api/${url}`
          );
          if (res.statusCode === 200) {
            setData(res.data)
          }
        } catch (err) {
          console.error(err);
        } finally {
        }
    }

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      render={({field: {onChange, value}, fieldState: {error}, formState}) => (
        <Autocomplete
              defaultValue={defaultValue}
              loading
              disablePortal
              id="combo-box-demo"
              options={data}
              onChange={(e, v) => onChange((v as any))}
              onOpen={()=> url != null ? fetchData() : null}
              getOptionLabel={(option)=> (option as any)?.name}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option?.name}
                  </li>
                );
              }}
              fullWidth
              sx={sx ? {...sx} : {'& fieldset': {borderRadius: '12px', border: '1px solid #BABABA'}}}
              renderInput={(params) => <TextField {...params} sx={{fontSize: '14px'}} label={label} />}
          />
      )}
      control={control}
      />
  )
})

export default CustomAutocomplete;