import { Box, Typography } from '@mui/material'
import React from 'react'

interface FilterModel {
    children: React.ReactNode,
    sx?: React.CSSProperties,
    borderColor?:string,
    disabled?:boolean
    onClick?: () => void;
    open?: boolean;
    modalContainerRef?:any
}

function Filter(props: FilterModel) {
    const { children, sx,borderColor,disabled,onClick,open,modalContainerRef} = props;
    const handleClick = () => {
        if (!disabled && onClick) {
          onClick();
        }
      };
    return (
        <Box 
            ref={modalContainerRef}
            sx={{
                position:"relative",
                padding: "16px",
                border: open?'none':`1px solid ${borderColor?borderColor:"#D4D4D4"}`,
                boxShadow: open?"0px 23px 39px rgba(0, 0, 0, 0.08)":"none",
                borderRadius:open?"16px 16px 0px 0px":"16px",
                transition:"0.5s ease all",
                whiteSpace: 'nowrap',
                "&:hover": {
                    boxShadow: "0px 23px 39px rgba(0, 0, 0, 0.08)",
                    border:'none',
                    ".schoolOutlinedIconBox": {
                        background: "#468CBC",
                        transition:"0.5s ease all",
                      },
                    ".schoolOutlinedIcon":{
                      color:'#fff',
                      transition:"0.5s ease all",
                    },
                },
                ...(disabled && {
                    opacity: 0.5, // Örnek bir devre dışı bırakma efekti, isteğinize göre ayarlayabilirsiniz.
                    pointerEvents: 'none',
                }),
                ...sx
            }}
            onClick={handleClick} 
        >
         
            {children}
        </Box>
    )
}

export default Filter