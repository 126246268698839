import React, { useEffect, useState } from "react";
import {  Box, Typography, Select, MenuItem, FormControl  } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { Module } from "../../../../../shared/continuity/models/Module";
import { LessonFormat } from "../../../../../shared/continuity/models/LessonFormat";
import { Student } from "../../../../../shared/continuity/models/Student";
import { Class } from "../../../../../shared/continuity/models/Class";
import PageHeader from "../../../../../../../components/custom-components/page-header";
import Button from "../../../../../../../components/core-components/inputs/button";
import { BaseService } from "../../../../../../../network/services/base/BaseService";
import { useParams } from "react-router-dom";
import { Worker } from "../../../../../shared/continuity/models/Worker";
import { CategoryHours } from "../../../../../shared/continuity/models/CategoryHours";
import { Teachers } from "../../../../../shared/continuity/models/Teachers";
import { Dialog } from "../../../../../../../components/custom-components/dialog";
import { sxSelect } from "./style";
import Filters from "../../../../../EducationProcessesManagement/continuity/components/filters";
import { HeldModule } from "../../../../../shared/continuity/models/HeldModule";

interface PropsModel {
  hasTheoric: boolean;
  hasPratice: boolean;
  hasLab: boolean;
  categoryHours: CategoryHours[];
  workers: Worker[];
  theoricWorker: string;
  practiceWorker: string;
  labWorker: string;
  setTheoricWorker: React.Dispatch<React.SetStateAction<string>>;
  setPracticeWorker: React.Dispatch<React.SetStateAction<string>>;
  setLabWorker: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertInside: any;
  modules: Module[];
  index: number;
  lessonFormat: LessonFormat;
  students: Student[];
  selectedClass: Class;
  getStudents: () => void;
  getTotalHours: () => void;
  getTeacherByClassId: () => void;
  isMentor?: boolean;
  today: string;
  setTeachers?: any;
  teachers: Teachers[];
  classes?: Class[];
  setSelectedWorker?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedClass: React.Dispatch<React.SetStateAction<Class>>;
  setTeacherChecks: any;
  teacherChecks?: any;
  format: string;
  setLessonFormat: React.Dispatch<React.SetStateAction<LessonFormat>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  totalHours: any;
  heldModules:HeldModule[]
  setHeldModules:React.Dispatch<React.SetStateAction<HeldModule[]>>;
  isGreatherThanCategoryHour: (roleName: string, totalHours: number) => number | boolean | undefined;
}

function Header(props: PropsModel) {
  const {
    hasPratice,
    hasTheoric,
    hasLab,
    modules,
    index,
    today,
    lessonFormat,
    students,
    selectedClass,
    setSelectedClass,
    setLoading,
    setAlertInside,
    setAlertOpen,
    getStudents,
    getTotalHours,
    theoricWorker,
    practiceWorker,
    labWorker,
    setTheoricWorker,
    setPracticeWorker,
    setLabWorker,
    workers,
    getTeacherByClassId,
    setSelectedWorker,
    isMentor,
    setTeachers,
    teachers,
    setTeacherChecks,
    teacherChecks,
    format,
    categoryHours,
    setLessonFormat,
    setIndex,
    classes,
    totalHours,
    heldModules,
    setHeldModules,
    isGreatherThanCategoryHour
  } = props;
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
  interface DialogState{
    open:boolean,
    text:string
  }
  const initialDialogState={
    open:false,
    text:""
  }
  
  const [dialogState,setDialogState]=useState<DialogState>(initialDialogState)
  const handleClose = () => {
    setDialogState(oldState=>({...oldState,open:false}));
  };
  useEffect(() => {
    if(lessonFormat.name === "Dərs ləğv edildi" || lessonFormat.name === "Lab ləğv edildi" ){
      setDisabledBtn(false);
    }
    else if(hasLab && students.some((x) => x.sessions.some((y) => y.hour !== 0))){
      if (teachers.some((x) => x.attendanceStatus === "0")) {
        setDisabledBtn(true);
      } else if (
        teachers.some(
          (x) =>
            x.totalHours === null ||
            x.totalMinutes === null ||
            isGreatherThanCategoryHour(x.roleName, x.totalHours)
        )
      ) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    }
    else if (
      students.some((x) => x.sessions.some((y) => y.hour !== 0)) &&
      Object.keys(heldModules[0]).length !== 0
    ) {
      if (teachers.some((x) => x.attendanceStatus === "0")) {
        setDisabledBtn(true);
      } else if (
        teachers.some(
          (x) =>
            x.totalHours === null ||
            x.totalMinutes === null ||
            isGreatherThanCategoryHour(x.roleName, x.totalHours)
        )
      ) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    } else {
      setDisabledBtn(true);
    }
  }, [students, heldModules, teachers, lessonFormat]);

  const params = useParams();
  const classesSessionsService = new BaseService(`api/classes/${params.id}/attendances`);
  const cancelClassService = new BaseService(`api/classes/${params.id}/session-cancel`);
  const handleAtendancesWorkers: any = (category: string) => {
    const atendancesWorkersArr: any = [];
    teachers.map((teacher, index) => {
      if (hasTheoric && hasLab) {
        if (category === "Theoric") {
          if (teacher.roleName === "muellim") {
            return atendancesWorkersArr.push({
              workerId: teacher.workerId,
              roleId: teacher.roleId,
              totalHours: teacher.totalHours,
              totalMinutes:teacher.totalMinutes,
              attendanceStatus:teacher.attendanceStatus
            });
          }
        }
        if (category === "Lab") {
          if (teacher.roleName === "mentor") {
            return atendancesWorkersArr.push({
              workerId: teacher.workerId,
              roleId: teacher.roleId,
              totalHours: teacher.totalHours,
              totalMinutes:teacher.totalMinutes,
              attendanceStatus:teacher.attendanceStatus
            });
          }
        }
      } else {
        return atendancesWorkersArr.push({
          workerId: teacher.workerId,
          roleId: teacher.roleId,
          totalHours: teacher.totalHours,
          totalMinutes:teacher.totalMinutes,
          attendanceStatus:teacher.attendanceStatus
        });
      }
    });
    return atendancesWorkersArr;
  };
  const updateSessions = (category: string) => {
    return {
      category: category,
      status: (() => {
        if (lessonFormat.value === 3) {
          if (lessonFormat.name === "Dərs ləğv edildi") {
            if (category === "Theoric") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else if (lessonFormat.name === "Praktika ləğv edildi") {
            if (category === "Practice") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else if (lessonFormat.name === "Lab ləğv edildi") {
            if (category === "Lab") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else {
            return lessonFormat.value;
          }
        } else {
          return lessonFormat.value;
        }
      })(),
      attendancesWorkers: handleAtendancesWorkers(category),
      attendances: students.map((student) => {
        const session = student.sessions.find(
          (session) => session.classSessionCategory === category
        );
        return {
          studentId: student.studentId,
          note: session?.note,
          totalAttendanceHours: session?.hour,
        };
      }),
    };
  };
  const handleUpdateSessions = (
    hasTheoric: boolean,
    hasPratice: boolean,
    hasLab: boolean
  ) => {
    if (hasTheoric && hasLab) {
      return [updateSessions("Theoric"),updateSessions("Lab")];
    }
    if (hasTheoric) {
      return [updateSessions("Theoric")];
    }
    if (hasLab) {
      return [updateSessions("Lab")]
    }
    
  };
  const updatedHeldModules = heldModules.map((module) => {
    // Module objesinin kopyasını oluşturarak, üzerinde değişiklik yapabilirsiniz
    const updatedModule = { ...module };
  
    // moduleName özelliğini kaldırın
    delete updatedModule.moduleName;
  
    return updatedModule;
  });
  const handleClick = () => {
    setLoading(true);
    const data = {
      date: today,
      // sessions: [updateSessions("Theoric"), updateSessions("Practice")],
      sessions: handleUpdateSessions(hasTheoric, hasPratice, hasLab) || [],
      heldModules: hasLab&&!hasTheoric?null:updatedHeldModules,
    };

    if(lessonFormat.name === "Dərs ləğv edildi" || lessonFormat.name === "Lab ləğv edildi" ){
      cancelClassService
      .cancel(data, `api/classes/${params.id}/session-cancel/admin?date=${data.date}`)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
        getStudents();
        return getTeacherByClassId();
      })
      .then((teacherData) => {
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    }else{
      classesSessionsService
      .add(data)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
        getStudents();
        return getTeacherByClassId();
      })
      .then((teacherData) => {
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    }

    
  };
  // const disabled =
  //   categoryHours?.length === 0
  //     ? true
  //     : lessonFormat.value === 3 &&
  //       lessonFormat.name === "Dərs/Praktika ləğv edildi"
  //     ? false
  //     : conditionalOptions
  //     ? true
  //     : false;

  return (
    <>
      <PageHeader>
      <Box display='flex' alignItems='center'  gap='56px'>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {
              classes && classes?.length > 0?(
                <FormControl 
                sx={{ "& .MuiSelect-select": { padding: "0" } }}
                 fullWidth >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={sxSelect}
                    value={selectedClass?.name ?? ""}
                    onChange={(event) => {
                      const selectedClassName = event.target.value;
                      const selectedClass = classes.find(
                        (item: Class) => item.name === selectedClassName
                      );
                      setSelectedClass && setSelectedClass(selectedClass!);
                    }}
                  >
                    {React.Children.toArray(
                      classes.map((item: Class) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              ):(
               <Box sx={{fontFamily:"Visby CF Bold, sans-serif", fontSize:"24px"}}>
                {selectedClass.name}
               </Box>
              )
            }
          
            <Box
              display="flex"
              color="#6B696A"
              alignItems="center"
              fontSize="12px"
              fontFamily="Visby CF, sans-serif"
              letterSpacing="0.4px"
            >
              Keçirilib:
              <Typography
                sx={{
                  fontFamily: "Visby CF Bold, sans-serif",
                  color: "#0E0D0D",
                  fontSize: "12px",
                }}
              >
                {`${selectedClass.attendanceHours}/${selectedClass.totalHours} Saat`}
              </Typography>
            </Box>
          </Box>
          <Filters
            hasCalendar={true}
            heldModules={ heldModules}
            setHeldModules={setHeldModules}
            totalHours={totalHours}
            modules={modules}
            index={index}
            setIndex={setIndex}
            lessonFormat={lessonFormat}
            setLessonFormat={setLessonFormat}
            categoryHours={categoryHours}
            hasTheoric={hasTheoric}
            hasPratice={hasPratice}
            hasLab={hasLab}
            classId={selectedClass.id}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Button disabled={disabledBtn} onClick={handleClick}>
              Yadda saxla
            </Button>
          </Box>
      </PageHeader>
      {
          dialogState.open && <Dialog text={dialogState.text} open={dialogState.open} handleClose={handleClose}/>
      }
    </>
  );
}

export default Header;
