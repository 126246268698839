import {
  Box,
  TableBody as MaterialTableBody,
  TextField,
} from "@mui/material";
import { sxtimeSelect } from "../style";
import { Teachers } from "../../../../models/Teachers";
import { ROLES } from "../../../../../../../../constants/auth/roles";
import { CategoryHours } from "../../../../models/CategoryHours";
import { useState } from "react";
interface TimeInputModel {
 disabled:boolean,
 totalHours:number | null,
 totalMinutes:Number | null,
 workerId:number,
 roleName:string
 setTeachers: React.Dispatch<React.SetStateAction<Teachers[]>>;
 categoryHours: CategoryHours[];
 isGreatherThanCategoryHour: (roleName: string, totalHours: number) => number | boolean | undefined;
}
export const TimeInput=(props: TimeInputModel)=>{
  const { disabled,totalHours,totalMinutes,workerId,categoryHours,setTeachers,roleName,isGreatherThanCategoryHour} = props;
  const [disabledMinuties,setDisabledMinutes]=useState(false)
  const [isValid,setIsValid]=useState<boolean>(true)
  const handleTeacherTimeChange = (
    event: any,
    workerId: number,
    key: string
  ) => {
    const value = parseInt(event.target.value, 10);
    setTeachers((prevTeachers) => {
      setIsValid(true)
      setDisabledMinutes(false)
      const updatedTeachers = prevTeachers.map((teacher) => {
        if (teacher.workerId === workerId) {
          const role = teacher.roleName;
          if (role === ROLES.MENTOR) {
            const practiceHour =
              categoryHours.find((item) => item.category === "Practice")
                ?.hour ||
              categoryHours.find((item) => item.category === "Lab")?.hour ||
              categoryHours.find((item) => item.category === "Theoric")?.hour!;
            if (key === "totalHours" && (value==practiceHour)) {
              setDisabledMinutes(true)
              return { ...teacher, [key]: practiceHour,totalMinutes:0 };
            }
            if((value< 0 || value > practiceHour)){
              setIsValid(false)
            }
            if (key === "totalMinutes" && (isNaN(value) || value<0 || value > 59)) {
              return teacher;
            }
          } else if (role === ROLES.MUELLIM) {
            const theoricHour =
              categoryHours.find((item) => item.category === "Theoric")?.hour ||
              0;
              if (key === "totalHours" && value === theoricHour) {
                setDisabledMinutes(true)
                return { ...teacher, [key]: theoricHour,totalMinutes:0 };
              }
            if (key === "totalHours" && (isNaN(value) || value<0 || value > theoricHour)) {
              setIsValid(false)
            }
            if (key === "totalMinutes" && (value<0 || value > 59)) {
              return teacher;
            } 
          }
          return { ...teacher, [key]: value ?? null };
        } else {
          return teacher;
        }
      });

      return updatedTeachers;
    });
  };
    return(
        <Box display="flex" alignItems="center" justifyContent="center">
        <TextField
          disabled={disabled}
          placeholder="Saat"
          sx={[
            sxtimeSelect,
            {
              "& .MuiInputBase-input": {
                padding: 0,
                textAlign: "start",
                height:"47px",
                color:isGreatherThanCategoryHour(roleName,totalHours!)?"red":""
              },
            },
          ]}
          type="number"
          variant="outlined"
          value={totalHours}
          onChange={(event) =>
            handleTeacherTimeChange(
              event,
              workerId,
              "totalHours"
            )
          }
        />
        <TextField
          disabled={disabled || disabledMinuties}
          placeholder="Dəq"
          sx={[
            sxtimeSelect,
            {
              "& .MuiInputBase-input": {
                padding: 0,
                textAlign: "start",
                height:"47px",
              },
            },
          ]}
          type="number"
          variant="outlined"
          value={totalMinutes}
          onChange={(event) =>
            handleTeacherTimeChange(
              event,
              workerId,
              "totalMinutes"
            )
          }
        />
      </Box>
    )
}