import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import LoginForm from "./LoginForm";
import Title from "../components/Title";
import ReCAPTCHA from "react-google-recaptcha";

interface LoginValues {
  email: string;
  password: string;
}
function LeftPanel() {
  const content = {
    mainTitle: "  Hey! Studioya",
    boldTitle: "xoş gəldin.",
    subtitle: "  Başlamaq üçün email və şifrəni daxil et.",
    boldSubtitle: " ",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValues>();
  const onSubmit = (data: LoginValues) => {
  };

  const handleFormSubmit = async (data: LoginValues) => {
    try {
      onSubmit(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Box
        display="flex"
        // sx={{ height: "90vh", overflow: "hidden" }}
        sx={{ height: "100%" }}
        flexDirection={"column"}
        alignItems="left"
        justifyContent="center"
        mx={10}
        // my={2}
        pr={isLargeScreen ? 20 : 0}
      >
        <Title content={content} />
        <Box py={1}>
          <LoginForm />
        </Box>
      </Box>
    </>
  );
}

export default LeftPanel;
