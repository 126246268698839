import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select as MaterialSelectComponent,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { theme } from "../../../../assets/themes";
import { SelectElementModel } from "./models/SelectElementModel";
import { useParams } from "react-router";
import { BaseService } from "../../../../network/services/base/BaseService";
import { ROLES } from "../../../../constants/auth/roles";

interface PropsModel {
  selectElement: string;
  setSelectElement: React.Dispatch<React.SetStateAction<string>>;
  selectElements: SelectElementModel[];
  name: string;
  hiddenValue?: string;
  disabled?: boolean;
  setSelectedWorker?: any;
  workers?: any;
  setTeachers?:any;
  teachers?:any;
  setTeacherChecks?:any
}

function Select(props: PropsModel) {
  const {
    selectElement,
    setSelectElement,
    selectElements,
    name,
    hiddenValue,
    disabled,
    setSelectedWorker,
    workers,
    setTeachers,
    teachers,
    setTeacherChecks
  } = props;
  const [placeholder, setPlaceHolder] = useState<string>("");
  const params = useParams();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setSelectElement(selectedValue);
    const selectedItem = selectElements.find((item) => item.label === selectedValue);
    if (selectedItem) {
      const selectedId = selectedItem.id;
      const roles = workers?.find((item: any) => item.id === selectedId).roles;
      if (name === 'teacher') {
        const roleId = roles.find((item: any) => item.name == ROLES.MUELLIM)?.id
        if(roleId){
          getClasses(selectedId,roleId)
        }
      } 
      if (name === 'mentor') {
        const roleId = roles.find((item: any) => item.name == ROLES.MENTOR)?.id
        if(roleId){
          getClasses(selectedId,roleId)

        }
      }
      
    }

  };

  const getClasses = async (selectedId:any,roleId:any) => {
    const teachersBySelect = new BaseService(`/api/Classes/${params.id!}/workers/${selectedId!}?date=${params.date}&roleId=${roleId!}`);

    try {
      await teachersBySelect.getAll()
        .then(res => {
          const updatedTeachers = [...teachers];
          const index = updatedTeachers.findIndex(
            (teacher) => teacher.roleName === res.data.roleName
          );
          let initialTeacherChecksObj: any = {};
          updatedTeachers.forEach((item: any) => {
            initialTeacherChecksObj[item.roleName] = item.isAttendance;
          });
          setTeacherChecks(initialTeacherChecksObj)
          if (index !== -1) {
            updatedTeachers[index] = res.data;
            setTeachers(updatedTeachers) 
          }
        })
    } catch (err) {
      
    }
  };

  useEffect(() => {
    setPlaceHolder(name);
  }, [selectElement]);

  // useEffect(() => {
  //     if (disabled) {
  //         setSelectElement(name)
  //     }
  // }, [disabled])

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
        {
          right: "20px",
        },
        ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
          right: "20px",
        },
        display: "flex",
      }}
    >
      {/* <InputLabel>{name}</InputLabel> */}

      <MaterialSelectComponent
        placeholder={name}
        value={selectElement ? selectElement : name}
        label={name}
        onChange={handleChange}
        disabled={disabled ?? !disabled}
        sx={{
          "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            padding: "8px 52px 8px 24px",
            borderRadius: "12px",
            background: theme.palette.primary.light,
            fontFamily: 'Visby CF Bold, sans-serif',
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          minWidth: "200px",
          maxWidth: "250px",
          color: selectElement ? "inherit" : "secondary.main",
        }}
      >
        <MenuItem value={name} sx={{ display: "none" }}>
          {name}
        </MenuItem>
        <MenuItem value={" "} sx={{ display: "none" }}>
          {name}
        </MenuItem>
        {hiddenValue && (
          <MenuItem value={hiddenValue} sx={{ display: "none" }}>
            {hiddenValue}
          </MenuItem>
        )}
        {selectElements.map((item) => (
          <MenuItem key={item.id} value={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </MaterialSelectComponent>
    </FormControl>
  );
}

export default Select;
