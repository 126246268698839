import {
    createBrowserRouter
} from "react-router-dom";
import { RoleProvider } from "./utils/auth/RoleProvider";
import { APP_PREFIX_PATH } from "./configs/Appconfig";
import App from './App';
import AuthLayout from "./layouts/auth-layout";
import Continuity from "./pages/app-views/EducationProcessesManagement/continuity";
import List from './pages/app-views/EducationSystemManagement/program/module/list';
import AddModule from './pages/app-views/EducationSystemManagement/program/module/add-module';
import SetProgramDetail from './pages/app-views/EducationSystemManagement/program/set-program/set-program-detail';
import RoleAssignee from "./pages/app-views/HumanResourcesManagement/RoleAssignee";
import WorkersAdd from "./pages/app-views/HumanResourcesManagement/Workers";
import ClassList from "./pages/app-views/EducationSystemManagement/set-session/class-list";
import Detail from "./pages/app-views/EducationSystemManagement/set-session/detail";
import ClassSessions from "./pages/app-views/EducationSystemManagement/set-session/class-sessions";
import ClassContinuity from "./pages/app-views/EducationSystemManagement/set-session/continuity";
import SetSessionByDay from "./pages/app-views/EducationSystemManagement/set-session/set-session-by-day";
import WorkerList from './pages/app-views/EducationSystemManagement/workerClasses/list';
import WorkerUpdate from './pages/app-views/EducationSystemManagement/workerClasses/update';
import Groups from "./pages/app-views/EducationSystemManagement/groups";
import StudentsList from "./pages/app-views/EducationProcessesManagement/students-listing";
import UnmarkedAttendancies from "./pages/app-views/EducationSystemManagement/unmarked-attendancies";
import Holidays from "./pages/app-views/HumanResourcesManagement/Holidays";
import SupportList from "./pages/app-views/SupportManagement/Support";
import EditHoliday from "./pages/app-views/HumanResourcesManagement/Holidays/Edit";
import Support from "./pages/app-views/SupportManagement/supportCreate";
import AddHoliday from "./pages/app-views/HumanResourcesManagement/Holidays/Add";
import ContinuityResult from "./pages/app-views/EducationProcessesManagement/continuity-result";
import ContinuityResultStudents from "./pages/app-views/EducationProcessesManagement/continuity-result-students";
import Mrp from "./pages/app-views/MrpManagement/mrp";
import Otp from "./pages/auth-views/login/otp/Otp";
import ChangePassword from "./pages/auth-views/login/changepassword/ChangePassword";
import ForgotPassword from "./pages/auth-views/login/forgotpassword/ForgotPassword";
import Login from "./pages/auth-views/login/login";
import SetPermisions from "./pages/app-views/PermissionsManagement/set-permissons";
import PermissionLevels from "./pages/app-views/PermissionsManagement/permissions-levels";
import GroupDetails from "./pages/app-views/PermissionsManagement/set-permissons/group-detail";
import GroupModuleAccess from "./pages/app-views/PermissionsManagement/set-permissons/group-access";
import UserModuleAccess from "./pages/app-views/PermissionsManagement/set-permissons/user-access";
import AuthPage from "./403";
import { 
    attendanceView, 
    programsView, 
    programsCreate, 
    rolesView, 
    workersCreate,
    groupsView,
    groupsEdit,
    workersView,
    mrpView,
    permissionLevelView,
    supportCreate,
    supportView,
    unmarkedAttendanciesView,
    calendarView,
    studentsView,
    calendarCreate,
    workersEdit,
    setPermissionsView,
    setPermissionsCreate,
    setPermissionsEdit,
    attendanceCreate,
    supportsListView
 } from "./permissions";

const router = createBrowserRouter([
    
    {
        path: `/auth`,
        element: <AuthLayout />,
        children: [
            {
                path: 'login',
                element: <Login/>
            },
            {
                path: "otp",
                element: <Otp />
            },
            {
                path: "confirmpassword",
                element: <ChangePassword />
            },
            {
                path: "confirmpassword/:key",
                element: <ChangePassword />
            },
            {
                path: `forgotpassword`,
                element: <ForgotPassword />
            },
        ]
    },
    {
      path: "/",
      element: <App/>,
      children: [
        {
            path: "/",
            element: <RoleProvider permission={attendanceCreate}>
                        <Continuity />
                    </RoleProvider> 
        },
        {
            path: '/403',
            element: <AuthPage/>
        },
        {
            path: `${APP_PREFIX_PATH}/program`,
            element: <RoleProvider permission={programsView}>
                        <List />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/program/add`,
            element: <RoleProvider permission={programsCreate}>
                        <AddModule />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/program/:id`,
            element: <RoleProvider permission={programsView}>
                        <AddModule />
                    </RoleProvider>
        },
        {
            path: "program/set/:id",
            element: <RoleProvider permission={programsCreate}>
                        <SetProgramDetail />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/roles/set`,
            element: <RoleProvider permission={rolesView}>
                        <RoleAssignee />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/workers/add`,
            element: <RoleProvider permission={workersCreate}>
                        <WorkersAdd />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/classes`,
            element: <RoleProvider permission={groupsView}>
                        <ClassList />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/classes/:id`,
            element: <RoleProvider permission={groupsView}>
                        <Detail />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/classes/:id/class-sessions`,
            element: <RoleProvider permission={groupsEdit}>
                        <ClassSessions />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/classes/:id/class-sessions/:date`,
            element: <RoleProvider permission={groupsEdit}>
                        <ClassContinuity />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/classes/:id/class-sessions/by-day/:date`,
            element: <RoleProvider permission={groupsEdit}>
                        <SetSessionByDay />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/continuity`,
            element: <RoleProvider permission={attendanceCreate}>
                        <Continuity />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/worker-classes`,
            element: <RoleProvider permission={workersView}>
                        <WorkerList />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/worker-classes/:id`,
            element: <RoleProvider permission={workersEdit}>
                        <WorkerUpdate />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/groups`,
            element: <RoleProvider permission={groupsView}>
                        <Groups />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/students-lists`,
            element: <RoleProvider permission={studentsView}>
                        <StudentsList />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/unmarked-attendancies`,
            element: <RoleProvider permission={unmarkedAttendanciesView}>
                        <UnmarkedAttendancies />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/holidays`,
            element: <RoleProvider permission={calendarView}>
                        <Holidays />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/supports`,
            element: <RoleProvider permission={supportsListView}>
                        <SupportList />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/holidays/:id`,
            element: <RoleProvider permission={calendarView}>
                        <EditHoliday />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/Dəstək`,
            element: <RoleProvider permission={supportCreate}>
                        <Support />
                    </RoleProvider>
        },
        {
            path: "holidays/new",
            element: <RoleProvider permission={calendarCreate}>
                        <AddHoliday />
                    </RoleProvider>
        },
        {
            path: "continuity-result",
            element: <RoleProvider permission={attendanceView}>
                        <ContinuityResult />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/continuity-result-student`,
            element: <RoleProvider permission={attendanceView}>
                        <ContinuityResultStudents />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/permissions`,
            element: <RoleProvider permission={setPermissionsView}>
                        <SetPermisions />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/permissionlevels`,
            element: <RoleProvider permission={permissionLevelView}>
                        <PermissionLevels />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/permissions/group-access/:id`,
            element: <RoleProvider permission={setPermissionsCreate}>
                       <GroupModuleAccess/>
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/permissions/user-access/:id`,
            element: <RoleProvider permission={setPermissionsEdit}>
                       <UserModuleAccess/>
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/permissions/:id`,
            element: <RoleProvider permission={setPermissionsView}>
                        <GroupDetails />
                    </RoleProvider>
        },
        {
            path: `${APP_PREFIX_PATH}/mrp`,
            element: <RoleProvider permission={mrpView}>
                        <Mrp />
                    </RoleProvider>
        },
      ]
    },
   
  ]);

export default router;