import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import Logo from "./images/Group-6.png";
import LogoClose from "./images/Group.png";
import { sxDrawerHeader, sxDrawer } from "./style";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import BaseIcon from "../../custom-components/base-icon";
import MenuElements from "./components/menu-elements";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../configs/Appconfig";

function LeftMenu() {
  const [open, setOpen] = useState<boolean>(true);

  const handleOpen = (): void => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Drawer
        sx={[
          sxDrawer,
          {
            "& .MuiDrawer-paper": {
              transform: "none !important",
            },
            width: open ? "248px" : "80px",
            transition: "0.5s ease all",
          },
        ]}
        open={open}
        onClick={handleOpen}
        variant="persistent"
        anchor="left"
      >
        <Box>
          <Box sx={sxDrawerHeader}>
            <Link style={{ display: "flex" }} to='/'>
              <Box component={"img"} src={open ? Logo : LogoClose}></Box>
            </Link>
            {open && (
              <BaseIcon onClick={handleClose}>
                <KeyboardDoubleArrowLeftIcon color="primary" />
              </BaseIcon>
            )}
          </Box>
        </Box>

        <MenuElements open={open} />
      </Drawer>
    </>
  );
}


export default LeftMenu;
