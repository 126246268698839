import React from "react";

export const sxTableCell: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
    color: "#000000",
    padding:"12px 16px",
}



