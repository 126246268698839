import { Typography } from "@mui/material";
import React, { Fragment,useState } from "react";
import {useNavigate } from "react-router-dom";
import { Link, } from "react-router-dom";
import Row from "./row";
import { ReactComponent as BackIcon } from "../../../../../../assets/icons/back.svg";
import { AppModulesGroup} from "../../../models";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import Progress from "../../../../../../components/custom-components/progress";
import Header from "./header";
import Footer from "../../../components/footer";
interface AppModulesAccessProps {
  appModulesGroup: AppModulesGroup;
  setAppModulesGroup: React.Dispatch<React.SetStateAction<AppModulesGroup>>;
  appModulessLoading: boolean;
  handleClick: () => void;
}
function AppModulesAccess(props:AppModulesAccessProps) {
  const { appModulesGroup, setAppModulesGroup, appModulessLoading,handleClick } = props;  
  const navigate = useNavigate();
  const [openModule, setOpenModule] = useState<number | null>(null);
  const handleClose = () => {
    navigate(`${APP_PREFIX_PATH}/permissions`);
  };
  console.log(appModulesGroup,'appModules');
  
  return (
    <>
      <Link
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          gap: "16px",
          alignSelf: "stretch",
        }}
        to={`${APP_PREFIX_PATH}/permissions`}
      >
        <BackIcon />{" "}
        <Typography
          sx={{
            fontSize: "24px",
            color: "#000000",
            fontWeight: "700",
            fontFamily: "Visby CF Bold",
          }}
        >
          İcazələri formalaşdırır - {appModulesGroup.name}
        </Typography>
      </Link>
      <Header
        appModulesGroup={appModulesGroup}
        setAppModulesGroup={setAppModulesGroup}
      />
      {appModulessLoading ? (
        <Progress />
      ) : (
        appModulesGroup.appModules &&
        appModulesGroup.appModules.map((appModule, index) => (
          <Fragment key={index}>
            <Row
              appModuleId={appModule.id}
              setAppModulesGroup={setAppModulesGroup}
              isArrowClicked={openModule === appModule.id}
              key={index}
              topAppModule={appModule}
              onArrowClick={() => {
                if (openModule === appModule.id) {
                  setOpenModule(null);
                } else {
                  setOpenModule(appModule.id);
                }
              }}
            />
            {openModule === appModule.id &&
              appModule.subAppModules &&
              appModule.subAppModules.map((subAppModules, index) => (
                <Row
                  appModuleId={appModule.id}
                  setAppModulesGroup={setAppModulesGroup}
                  key={index}
                  subAppModule={subAppModules}
                />
              ))}
          </Fragment>
        ))
      )}
      <Footer handleClick={handleClick} handleClose={handleClose} />
    </>
  );
}

export default AppModulesAccess;
