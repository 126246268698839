import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { sxTableCell } from "../style";
import { Class } from "../../../../models/Class";
import { Student } from "../../../../models/Student";
import { CategoryHours } from "../../../../models/CategoryHours";
import { CustomSelectModal } from "../../../../../../EducationProcessesManagement/continuity/components/CustomSelectModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Teachers } from "../../../../models/Teachers";

interface TableHeaderProps {
  hasPratice: any;
  hasTheoric: any;
  setHasTheoric: React.Dispatch<React.SetStateAction<boolean>>;
  hasLab: any;
  setHasLab: React.Dispatch<React.SetStateAction<boolean>>;
  classes?: Class[];
  setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  students: Student[];
  categoryHours: CategoryHours[];
  setCategoryHours: React.Dispatch<React.SetStateAction<CategoryHours[]>>;
  selectedClass?: Class;
  setSelectedClass?: React.Dispatch<React.SetStateAction<Class>>;
  disableTheoric: boolean;
  disablePractice: boolean;
  disableLab: boolean;
  format: string;
  setTeachers: React.Dispatch<React.SetStateAction<Teachers[]>>;
  isLessonsModalOpen:boolean;
  setIsLessonsModalOpen:React.Dispatch<React.SetStateAction<boolean>>;
}
const data = [{ name: "Dərs" }, { name: "Lab günü" }];
function StudentTableHeader(props: TableHeaderProps) {
  const {
    hasPratice,
    hasTheoric,
    hasLab,
    setStudents,
    students,
    categoryHours,
    setCategoryHours,
    selectedClass,
    disableTheoric,
    disablePractice,
    disableLab,
    setSelectedClass,
    format,
    setHasTheoric,
    setHasLab,
    setTeachers,
    isLessonsModalOpen,
    setIsLessonsModalOpen,
  } = props;

  

  const lessonsHandleChange = (value: string) => {
    const selectedLessonName = value;
    if (selectedLessonName === "Dərs") {
      setHasLab(false);
      setHasTheoric(true);
      setCategoryHours(
        categoryHours.map((categoryHour) => ({
          ...categoryHour,
          category: categoryHour.category ="Theoric",
        }))
      );
      const updateStudentSessions = (student: Student): Student => {
        const updatedStudent = { ...student };
        updatedStudent.sessions = updatedStudent.sessions.map((session) => {
          session.classSessionCategory =
            session.classSessionCategory = "Theoric"
          return session;
        });
        return updatedStudent;
      };
      const updatedStudents = students.map(updateStudentSessions);
      setStudents(updatedStudents);
    } else if (selectedLessonName === "Lab günü") {
      setHasTheoric(false);
      setHasLab(true);
      setCategoryHours(
        categoryHours.map((categoryHour) => ({
          ...categoryHour,
          category: categoryHour.category ="Lab"
        }))
      );
      const updateStudentSessions = (student: Student): Student => {
        const updatedStudent = { ...student };
        updatedStudent.sessions = updatedStudent.sessions.map((session) => {
          session.classSessionCategory =
            session.classSessionCategory ="Lab"
          return session;
        });
        return updatedStudent;
      };
      const updatedStudents = students.map(updateStudentSessions);
      setStudents(updatedStudents);
    }
    setTeachers((teachers) =>
      teachers.map((teacher) => ({
        ...teacher,
        totalHours: null,
        totalMinutes: null,
        attendanceStatus: null,
      }))
    );
    setIsLessonsModalOpen(false);
  };
  const maxHourCheck = categoryHours.filter(
    (item) => item.category === "Practice"
  )[0]?.hour;
  const maxHourTheoricCheck = categoryHours.filter(
    (item) => item.category === "Theoric"
  )[0]?.hour;
  const maxHourLabCheck = categoryHours.filter(
    (item) => item.category === "Lab"
  )[0]?.hour;
  const handleChange = (e: any, category: string) => {
    const isChecked = e.target.checked;
    setStudents((prev: Student[]) => {
      if (isChecked) {
        const maxHour =
          categoryHours.find((item) => item.category === category)?.hour || 0;
        return prev.map((student: Student) => {
          return {
            ...student,
            sessions: student.sessions.map((session) => {
              if (session.classSessionCategory === category) {
                return {
                  ...session,
                  hour: maxHour,
                };
              } else {
                return session;
              }
            }),
          };
        });
      } else {
        return prev.map((student: Student) => {
          return {
            ...student,
            sessions: student.sessions.map((session) => {
              if (session.classSessionCategory === category) {
                return {
                  ...session,
                  hour: 0,
                };
              } else {
                return session;
              }
            }),
          };
        });
      }
    });
  };
  const areAllHoursEqualeSession = (category: string): boolean => {
    const maxHour =
      categoryHours.find((item) => item.category === category)?.hour || 0;
    return students.every((student) => {
      return student.sessions.some((session) => {
        return (
          session.classSessionCategory === category && session.hour === maxHour
        );
      });
    });
  };
  return (
    <>
      {categoryHours.length > 0 ? (
        <TableHead>
          <TableRow>
            <TableCell sx={[sxTableCell, { fontWeight: "700" }]} align="left">
              №
            </TableCell>
            <TableCell sx={sxTableCell} align="left">
              Tələbə ad və Soyad
            </TableCell>
            {hasTheoric ? (
              <>
                <TableCell
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disableTheoric ? "none" : "",
                      opacity: disableTheoric ? "0.4" : "1",
                    },
                  ]}
                  align="left"
                >
                  <Checkbox
                    checked={areAllHoursEqualeSession("Theoric")}
                    disabled={maxHourTheoricCheck ? false : true}
                    onChange={(e) => handleChange(e, "Theoric")}
                    sx={{ padding: "0" }}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    if (!(hasTheoric && hasLab)) {
                      setIsLessonsModalOpen(!isLessonsModalOpen);
                    }
                  }}
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disableTheoric ? "none" : "",
                      opacity: disableTheoric ? "0.4" : "1",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer",
                    },
                  ]}
                  align="left"
                >
                  Dərs
                  {hasTheoric && hasLab ? (
                    ""
                  ) : (
                    <>
                      <ArrowDropDownIcon />
                      <CustomSelectModal
                        isOpen={isLessonsModalOpen}
                        data={data}
                        handleChange={lessonsHandleChange}
                      />
                    </>
                  )}
                </TableCell>
              </>
            ) : (
              <></>
            )}

            {hasPratice ? (
              <>
                <TableCell
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disablePractice ? "none" : "",
                      opacity: disablePractice ? "0.4" : "1",
                    },
                  ]}
                  align="left"
                >
                  <Checkbox
                    checked={areAllHoursEqualeSession("Practice")}
                    disabled={maxHourCheck ? false : true}
                    onChange={(e) => handleChange(e, "Practice")}
                    sx={{ padding: "0" }}
                  />
                </TableCell>
                <TableCell
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disablePractice ? "none" : "",
                      opacity: disablePractice ? "0.4" : "1",
                    },
                  ]}
                  align="left"
                >
                  Praktika
                </TableCell>
              </>
            ) : (
              <></>
            )}

            {hasLab ? (
              <>
                <TableCell
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disableLab ? "none" : "",
                      opacity: disableLab ? "0.4" : "1",
                    },
                  ]}
                  align="left"
                >
                  <Checkbox
                    checked={areAllHoursEqualeSession("Lab")}
                    disabled={maxHourLabCheck ? false : true}
                    onChange={(e) => handleChange(e, "Lab")}
                    sx={{ padding: "0" }}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    if (!(hasTheoric && hasLab)) {
                      setIsLessonsModalOpen(!isLessonsModalOpen);
                    }
                  }}
                  sx={[
                    sxTableCell,
                    {
                      pointerEvents: disableLab ? "none" : "",
                      opacity: disableLab ? "0.4" : "1",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer",
                    },
                  ]}
                  align="left"
                >
                  Lab
                  {hasTheoric && hasLab ? (
                    ""
                  ) : (
                    <>
                      <ArrowDropDownIcon />
                      <CustomSelectModal
                        isOpen={isLessonsModalOpen}
                        data={data}
                        handleChange={lessonsHandleChange}
                      />
                    </>
                  )}
                </TableCell>
              </>
            ) : (
              <></>
            )}

            <TableCell width={"10%"} sx={sxTableCell} align="left">
              D.F.
            </TableCell>
          </TableRow>
        </TableHead>
      ) : (
        <></>
      )}
    </>
  );
}

export default StudentTableHeader;
