import { Checkbox, TableCell, TableRow, Box, Typography } from "@mui/material";
import TableCellBody from "../../../../../../../../components/custom-components/table-cell-body";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface ISetProgramCellModel{
    key: string,
    text?: any,
    isCustomelement?: boolean,
    customElement?: any,
    isFirst?: boolean,
    version?: string,
}

interface ISetProgramRowModel{
    cells: ISetProgramCellModel[],
    hasCheckBox: boolean,
    onCheckBoxChecked?: any,
    areAllSelected?: boolean,
    indeterminate?: boolean,
    onArrowClick?: any,
    isArrowClicked?: boolean,
    hasSubModules?: boolean,
    isChecked?: boolean,
    isDeletable?: boolean,
    onDelete?: any
}

export default function Index({cells, hasCheckBox, isChecked = false, onCheckBoxChecked, areAllSelected = false, indeterminate = false, onArrowClick, hasSubModules= false, isArrowClicked = false, isDeletable, onDelete} : ISetProgramRowModel) {
  return (
    <TableRow>
        {
            hasCheckBox ? (
              <TableCell align="left" width={'3%'}>
              <Checkbox
                sx={{ padding: "0" }}
                checked={isChecked}
                indeterminate={indeterminate}
                onChange={onCheckBoxChecked}
              />
              </TableCell>
            ) : null
        }
        {
            cells?.map((elem, index)=> (
                <TableCellBody sx={{py: 0}} key={index} width="13%">
                    {
                        (elem?.isFirst && hasSubModules) ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <ArrowRightIcon
                                    onClick={onArrowClick}
                                    sx={{
                                      color: "#999898",
                                      cursor: "pointer",
                                      transition: "0.5s ease all",
                                      transform: `${
                                        isArrowClicked ? "rotate(90deg)" : "none"
                                      }`,
                                      "&:hover": {
                                        color: "primary.main",
                                      },
                                    }}
                                  />
                                <Typography sx={{ fontSize: "14px" }} component={"p"}>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontFamily: "Visby CF Bold, sans-serif",
                                    }}
                                    component={"span"}
                                  >
                                    {elem?.version}.{" "}
                                  </Typography>
                                  {elem?.text}
                                </Typography>
                              </Box>
                        ) : !elem?.isCustomelement ? elem?.text?.toString() : elem?.customElement
                    }
                </TableCellBody>
            ))
        }
        {
          isDeletable && (
            <TableCellBody sx={{py: 0}} width="5%">
              <DeleteOutlineOutlinedIcon color="error" onClick={()=> onDelete()}/>
            </TableCellBody>
          )
        }
    </TableRow>
  )
}
