import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/edit.svg";
import { ReactComponent as SettingIcon } from "../../../../../../assets/icons/settings.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/icons/add-circled-outline.svg";
import { ReactComponent as RemoveTrashIcon } from "../../../../../../assets/icons/trash copy.svg";
import { sxContainer } from "./style";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";

interface ActionsProps {
  isLast: boolean;
  setShowActions: (value: boolean) => void;
  setShowActionDelete: (value: boolean) => void;
  setAddShowAction: (value: boolean) => void;
  setShowActionEdit: (value: boolean) => void;
  groupId: string;
  groupName: string;
}
function Actions(props: ActionsProps) {
  const navigate = useNavigate();
  const {
    groupName,
    isLast,
    setShowActionDelete,
    setAddShowAction,
    setShowActions,
    setShowActionEdit,
    groupId,
  } = props;
  return (
    <Box sx={{ ...sxContainer, ...(isLast && { right: "-50px" }) }}>
      <Box
        onClick={(event) => {
          setShowActions(false);
          event.stopPropagation();
          setShowActionEdit(true);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "14px",
          gap: "10px",
          alignSelf: "stretch",
          borderBottom: "1px solid #E9E9E9",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
          }}
        >
          <EditIcon />
          <Typography
            sx={{
              fontFamily: "Visby CF Medium, sans-serif",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "var(--Natural-natural-300, #6B696A)",
            }}
          >
            Redaktə et
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={(event) => {
          setShowActions(false);
          event.stopPropagation();
          navigate(`${APP_PREFIX_PATH}/permissions/group-access/${groupId}`, {
            state: { name: groupName },
          });
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "14px",
          gap: "10px",
          alignSelf: "stretch",
          borderBottom: "1px solid #E9E9E9",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
          }}
        >
          <SettingIcon />
          <Typography
            sx={{
              fontFamily: "Visby CF Medium, sans-serif",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "var(--Natural-natural-300, #6B696A)",
            }}
          >
            İcazələr
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={(event) => {
          setShowActions(false);
          event.stopPropagation();
          setAddShowAction(true);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "14px",
          gap: "10px",
          alignSelf: "stretch",
          borderBottom: "1px solid #E9E9E9",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
          }}
        >
          <AddIcon />
          <Typography
            sx={{
              fontFamily: "Visby CF Medium, sans-serif",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "var(--Natural-natural-300, #6B696A)",
            }}
          >
            İstifadəçi əlavə et
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={(event) => {
          setShowActions(false);
          event.stopPropagation();
          setShowActionDelete(true);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "14px",
          alignSelf: "stretch",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
          }}
        >
          <RemoveTrashIcon />
          <Typography
            sx={{
              fontFamily: "Visby CF Medium, sans-serif",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "var(--Natural-natural-300, #6B696A)",
            }}
          >
            Sil
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Actions;
