import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PageTitleModel } from './models/FormTitleModel';
import { Box } from '@mui/material';

function PageTitle(props: PageTitleModel) {
    const { name } = props

    const theme = createTheme();

    theme.typography.h1 = {
        fontSize: "24px",
        color: '#000000',
        fontWeight: '700',
        fontFamily:"Visby CF Bold",
    };

    return (
        <ThemeProvider theme={theme}>
            <Box >
                <Typography variant="h1">
                    {name}
                </Typography>
            </Box>
        </ThemeProvider>
    )
}

export default PageTitle