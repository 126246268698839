import { Box, Typography } from '@mui/material';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import React, { useState } from 'react'
import DataGrid from '../../../../components/custom-components/data-grid';
import Header from './components/header'
import Filter from './components/filter';


function ContinuityResult() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [open, setOpen] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'A.S.A',
      flex: 1,
    },
    {
      field: 'position',
      headerName: 'Vəzifəsi',
      flex: 0.5,
    },
    {
      field: 'inClass',
      headerName: 'Dərsdə',
      flex: 0.7,
    },
    {
      field: 'permission',
      headerName: 'İcazə',
      flex: 0.7,
    },
    {
      field: 'notExist',
      headerName: 'Yoxdur',
      flex: 0.7,
    },
    {
      field: 'attendancePercentage',
      headerName: 'Davamiyyət faizi',
      flex: 0.7,
    },
  ];
  return (
    <>
        <Header setOpen={setOpen}/>
        <Filter open={open} setOpen={setOpen} />
        <Box sx={{ width: '100%', height: "auto" }}>
          <DataGrid
            data={[]}
            columnsData={columns}
            pageSizeData={pageSize}
            setPageSize={setPageSize}
            disableColumnMenu={true}
          />
        </Box>
    </>
  )
}

export default ContinuityResult