import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Modules, Program, SubModules } from '../../network/models/program/Program';
import { ProgramService } from '../../network/services/programService/ProgramService';

let programService = new ProgramService()

export const fetchPrograms = createAsyncThunk('programs/getPrograms', async () => {
    const { data } = await programService.getAll();
    data.forEach((item:any) => {
        item.modules.sort((a:any, b:any) => parseInt(a.version) - parseInt(b.version));
        item.modules.forEach((module:any) => {
            module.subModules.sort((a:any, b:any) => parseInt(a.version) - parseInt(b.version));
        });
    });
    return data;
});
interface initialStateModel {
    programs: Program[];
    status: string;
    error: any;
}

const initialState: initialStateModel = {
    programs: [],
    status: "idle",
    error: null,
};

const programsSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrograms.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPrograms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.programs = action.payload;
            })
            .addCase(fetchPrograms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default programsSlice.reducer;