export const sxModal: React.CSSProperties = {
  padding: "32px",
  // height: "700px",
};
export const sxModalClass: React.CSSProperties = {
  padding: "32px",
  height: "700px",
};

export const sxAvatar: React.CSSProperties = {
  width: "48px",
  height: "48px",
};

export const sxStudentName: React.CSSProperties = {
  fontSize: "16px",
  fontFamily: 'Visby CF Bold, sans-serif',
};

export const sxClassName: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  color: "#999898",
};

export const sxDayRange: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
};

export const sxDay: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  color: "#DD6D61",
};

export const sxFooterModal: React.CSSProperties = {
  display: "flex",
  gap: "16px",
  position: "absolute",
  bottom: "0",
};

export const sxPieChart: React.CSSProperties={
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  marginTop:"20px"
}
