import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Class } from '../../network/models/class/Class';
import { ClassService } from '../../network/services/classService/ClassService';

let classService = new ClassService()

export const fetchClasses = createAsyncThunk('classes/getClasses', async () => {
    const { data } = await classService.getAll();
    return data;
});

interface initialStateModel {
    classes: Class[];
    status: string;
    error: any;
}

const initialState: initialStateModel = {
    classes: [],
    status: "idle",
    error: null,
};

const classesSlice = createSlice({
    name: 'classes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClasses.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchClasses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.classes = action.payload;
            })
            .addCase(fetchClasses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default classesSlice.reducer;