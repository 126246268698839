import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { sxInstructors, sxTab } from "./style";

type StudentDetailsItem = {
  id: string;
  name: string;
  surname: string;
  className: string;
  arrivalHours: number;
  absentHours: number;
  attendance: number;
};
interface TabHeaderModel {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  studentDetails: Array<StudentDetailsItem>;
}

function TabHeader(props: TabHeaderModel) {
  const { value, handleChange } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "12px",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "fit-content",
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab sx={sxTab} label="Ümumi siyahı" />
          <Tab sx={sxTab} label="3+ gün dərsdə olmayanlar" />
        </Tabs>
      </Box>
    </Box>
  );
}

export default TabHeader;
