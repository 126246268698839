import AppLayout from "./layouts/app-layout";
import { useSelector } from "react-redux";
import { RootState } from "./store/reducers";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {
  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const loading = useSelector((state: RootState) => state.auth.isLoading);
  const navigate = useNavigate();

  return (
    <QueryClientProvider client={queryClient}>
    <>
      {loading ? (
          <div className="loader"></div>
          ) : (
              <>
                {isLoggedIn ? <AppLayout/> : navigate("/auth/login")}
              </>)
      }
    </>
    </QueryClientProvider>
  );
}

export default App;
