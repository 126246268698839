import { Box } from '@mui/system'
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { BaseService } from '../../../../../network/services/base/BaseService';

interface IAttendanceGraphModel {
  classId: string;
}

export default function AttendanceGraph({classId} : IAttendanceGraphModel) {
   const state = {
          
    series: [{
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }],
    options: {
      dataLabels: {
        enabled: false
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      }
    },
  }

  useEffect(()=> {
    async function getData() {
      try {
        const classService = new BaseService(`api/Classes`);
        const res = await classService.getAll(
          `api/Classes/${classId}/attendance-rate`
        );
        if (res.statusCode === 200) {
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    }
    getData();
  }, [])

  return (
    <Box sx={{pt: '24px', width: '100%', minHeight: '100%'}}>
        <Chart options={state.options} series={state.series} type="line" width={'100%'} height={'70%'} />
    </Box>
  )
}
