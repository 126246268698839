export const apiRoutes = {
    attendances: {
        getAll: 'Attendances'
    },
    auth: {
        base: 'Auth',
        get logout(){
            return `${this.base}/logout`;
        } ,
        get login(){
            return `${this.base}/login`;
        },
        get register(){
            return `${this.base}/register`;
        },
        get confirm(){
            return `${this.base}/confirm`;
        },
        get refreshPassword(){
            return `${this.base}/refresh-password`;
        },
        get updatePassword(){
            return `${this.base}/update-password`;
        }
    },
    classes: {
        base: 'api/Classes',
        get getAll(){
            return this.base;
        },
        get create(){
            return this.base;
        },
        getById(id: number){
            return `${this.base}/${id}`;
        },
        update(id: number){
            return `${this.base}/${id}`;
        },
        delete(id: number){
            return `${this.base}/${id}`;
        },
        cancelSession(id: number){
            return `${this.base}/${id}/session-cancel`;
        },
        get count(){
            return `${this.base}/count`;
        },
        sessions(id: number){
            return `${this.base}/${id}/sessions`;
        },
        addSessions(id: number){
            return `${this.base}/${id}/sessions`;
        },
        updateSessions(id: number){
            return `${this.base}/${id}/sessions`;
        },
        modules(id: number){
            return `${this.base}/${id}/modules`;
        },
        heldModules(id: number){
            return `${this.base}/${id}/held-modules`;
        },
        heldModulesAdmin(id: number){
            return `${this.base}/${id}/held-modules/admin`;
        },
        modulesWorkers(id: number){
            return `${this.base}/${id}/modules-workers`;
        },
        updateModulesWorkers(id: number){
            return `${this.base}/${id}/modules-workers`;
        },
        createModulesWorkers(id: number){
            return `${this.base}/${id}/modules-workers`;
        },
        students(id: number){
            return `${this.base}/${id}/students`;
        },
        addStudents(id: number){
            return `${this.base}/${id}/students`;
        },
    },

    supports: {
        base: 'api/Supports',
        get getAll(){
            return this.base;
        },
        get create(){
            return this.base;
        },
        update(id: number){
            return `${this.base}/${id}`;
        },
        delete(id: number){
            return `${this.base}/${id}`;
        },
        getById(id: number){
            return `${this.base}/${id}`;
        },
        get getCategories(){
            return `${this.base}/categories`;
        }
    }
}