import React, { useContext, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Email from "@mui/icons-material/EmailOutlined";
import Alert from "../../../../components/custom-components/alert";
import Title from "../components/Title";
import { Controller, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Errors from "../components/Errors";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Progress from "../../../../components/custom-components/progress";

interface LoginValues {
  email: string;
}

function ForgotPassword() {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = useState("success" as AlertColor);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const [alertText, setAlertText] = useState<string>("");
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const content = {
    mainTitle: " Yaxşı, gəl",
    boldTitle: "şifrəni dəyişdirək.",
    subtitle: "Emailini daxil et.",
    boldSubtitle: " ",
  };
  const [activeInput, setActiveInput] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginValues>();
  const [clicked, setClicked] = useState(false);
  const handleFormSubmit = async (data: LoginValues) => {
    setSubmitStatus(true);
    // if (clicked) {
    const res = await authContext?.forgotPassword(data);
    if (res.status == 204) {
      setError("");

      setIsDialogOpen(true);
    } else if (res?.response?.data?.Time) {
      const duration = moment.duration(res?.response?.data?.Time);
      const minutes = Math.floor(duration.asMinutes());
      const seconds = Math.floor(duration.asSeconds()) % 60;
      setError(
        `Xəta baş verdi. ${minutes} dəqiqə ${seconds} saniyə sonra yenidən cəhd edin`
      );
    } else if (res.response.status === 404) {
      setError("Daxil etdiyiniz mailə uyğun Istifadəçi tapılmadı.");
    } else {
      setError("Xəta baş verdi. Zəhmət olmasa daha sonra yenidən cəhd edin.");
    }
    setSubmitStatus(false);
  };
  const handlecapt = () => {
    setClicked(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    navigate("/auth/login", { replace: true });
  };

  const handleInputFocus = (id: string) => {
    setActiveInput(id);
  };

  const handleInputBlur = () => {
    setActiveInput("");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="left"
        justifyContent="center"
        mx={10}
        sx={{ height: "100%" }}
        pr={isLargeScreen ? 20 : 0}
      >
        <Title content={content} />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box py={2}>
            {" "}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Emailini qeyd et",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  InputLabelProps={{
                    style: { color: "#0E0D0D" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email
                          color="disabled"
                          sx={{
                            color:
                              activeInput === "email" ? "#468CBC" : undefined,
                          }}
                        />
                      </InputAdornment>
                    ),
                    onFocus: () => handleInputFocus("email"),
                    onBlur: handleInputBlur,
                    sx: {
                      borderRadius: 3,
                      "& fieldset": {
                        borderColor: "grey.500",
                      },
                      "&:hover fieldset": {
                        borderColor: "#468CBC !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#468CBC !important",
                        borderWidth: 1,
                        backgroundColor: "transparent",
                      },
                    },
                    value: field.value.trim().toLowerCase(),
                    onChange: (e) => {
                      setError("");
                      field.onChange(e.target.value.trim().toLowerCase());
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box py={1}>
            {/* for deploy */}
            {/* <ReCAPTCHA
              onChange={handlecapt}
              sitekey={"6LfEWyAlAAAAADDTwtPs_sBjf3liA2g09vflDXz3"}
            /> */}
            {/* for local host */}
            {/* <ReCAPTCHA
              onChange={handlecapt}
              size="invisible"
              sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
            /> */}
            {open && (
              <Alert
                open={open}
                text={alertText}
                severity={severity}
                handleClose={handleClose}
              />
            )}
          </Box>
          <Box display="flex" gap={2} py={2}>
            <Button
              sx={{
                borderRadius: "12px",
                backgroundColor: "#4BA458",
                textTransform: "none",
                marginTop: "20px",
                height: "40px",
                minWidth: "150px",
              }}
              type="submit"
              variant="contained"
              color="success"
              disabled={submitStatus}
            >
              {submitStatus ? <Progress size={20} /> : "Davam et"}
            </Button>
            <Button
              sx={{
                borderRadius: "12px",
                height: "40px",
                backgroundColor: "#EDF6EE",
                color: "black",
                textTransform: "none",
                marginTop: "20px",
                minWidth: "150px",
                "&:hover": {
                  backgroundColor: "#EDF6EE",
                },
              }}
              type="button"
              variant="contained"
              onClick={() => navigate("/auth/login", { replace: true })}
            >
              Ləğv et
            </Button>
          </Box>
          {errors.email?.message && <Errors message={errors.email?.message} />}
          {error && <Errors message={error} />}
        </form>
      </Box>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <p>
            Şifrə yeniləmə linki emailinizə göndərilmiştir. Poçt qutunuzu
            yoxlayın.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Bağla</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ForgotPassword;
