import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PageHeader from "../../../../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../../../configs/Appconfig";
import Button from "../../../../../../../components/core-components/inputs/button";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { ApiInstance } from "../../../../../../../network/services/core/apiInstance";
import { AlertColor } from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { sxDate } from "../../../../../MrpManagement/mrp/filter/style";
import dayjs, { Dayjs } from "dayjs";
// import { PostSession } from "../../models/PostSession";

interface PropsModel {
  sessions: any[];
  sessionsData: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertInside: any;
}

function Header(props: PropsModel) {
  const {
    sessions,
    sessionsData,
    setLoading,
    setAlertOpen,
    setAlertInside,
  } = props;
  const params = useParams();
  const [status, setStatus] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    dayjs(params.date)
  );
  let navigate = useNavigate();

  function DateIcon() {
    return <CalendarTodayOutlinedIcon color="primary" />;
  }
  useEffect(() => {
    let totalHours = sessionsData.totalHours;

    let sessionHoursDifference = 0;
    const theoricStart = sessionsData.theoricStart
      ? moment(sessionsData.theoricStart, "HH:mm")
      : null;
    const theoricEnd = sessionsData.theoricEnd
      ? moment(sessionsData.theoricEnd, "HH:mm")
      : null;
    const practicStart = sessionsData.practicStart
      ? moment(sessionsData.practicStart, "HH:mm")
      : null;
    const practicEnd = sessionsData.practicEnd
      ? moment(sessionsData.practicEnd, "HH:mm")
      : null;
    const labStart = sessionsData.labStart
      ? moment(sessionsData.labStart, "HH:mm")
      : null;
    const labEnd = sessionsData.labEnd
      ? moment(sessionsData.labEnd, "HH:mm")
      : null;
    const theoricHoursDifference =
      theoricStart && theoricEnd
        ? timeDifferences(theoricEnd, theoricStart) / 60
        : 0;
    const practicHoursDifference =
      practicStart && practicEnd
        ? timeDifferences(practicEnd, practicStart) / 60
        : 0;
    const labHoursDifference =
      labStart && labEnd
        ? timeDifferences(labEnd, labStart) / 60
        : 0;

    sessionHoursDifference += theoricHoursDifference + practicHoursDifference + labHoursDifference;

    //  let totalHoursDifference += sessionHoursDifference;
    if (sessionHoursDifference === totalHours) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [sessionsData]);

  const timeDifferences = (end: moment.Moment, start: moment.Moment) => {
    return moment.duration(end.diff(start)).asMinutes();
  };

  const handleClick = () => {
    setLoading(true);

    const newData: any = {
      classId: sessionsData.classId,
      oldDate: params.date,
      newDate: selectedStartDate?.format("YYYY-MM-DD"),
      sessions: [],
    };
    if (sessionsData.theoricStart && sessionsData.theoricEnd) {
      newData.sessions.push({
        category: "Theoric",
        startTime: sessionsData.theoricStart,
        endTime: sessionsData.theoricEnd,
      });
    }
    if (sessionsData.practicStart && sessionsData.practicEnd) {
      newData.sessions.push({
        category: "Practice",
        startTime: sessionsData.practicStart,
        endTime: sessionsData.practicEnd,
      });
    }
      if (sessionsData.labStart && sessionsData.labEnd) {
        newData.sessions.push({
          category: "Lab",
          startTime: sessionsData.labStart,
          endTime: sessionsData.labEnd,
        });
    }

    ApiInstance.put(`/api/class-sessions/`, newData)
      .then((res) => {
        navigate(
          `/admin/app/classes/${sessionsData.classId}/class-sessions/by-day/${newData.newDate}`
        );
        setAlertOpen(true);
        setAlertInside({
          text: "Sessiya təyin edildi",
          severity: "success" as AlertColor,
        });
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: err?.response?.data?.title,
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {sessions.length > 0 && (
        <PageHeader>
          <Box>
            {/* <Breadcrumbs>
              <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
              <BreadcrumbsLink
                name="Sessiya təyin et"
                url={`${APP_PREFIX_PATH}/classes`}
              />
              <BreadcrumbsTypography name={sessions[0].className} />
            </Breadcrumbs> */}
            <h4>
              {sessions[0].className} -
              {moment(params?.date).format(" D MMMM YYYY")}
            </h4>
            {/* <PageTitle name={params?.date} /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={"Tarix seçin"}
                format="DD MMMM YYYY"
                value={selectedStartDate}
                onChange={(date) => date && setSelectedStartDate(date)}
                sx={sxDate}
                components={{
                  OpenPickerIcon: DateIcon,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Button
            disabled={status ? false : true}
            onClick={() => handleClick()}
          >
            Yadda saxla
          </Button>
        </PageHeader>
      )}
    </>
  );
}

export default Header;
