import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  sxModalBottom,
  sxModalContainer,
  sxModalDescription,
  sxModalTitle,
  sxTitleBox,
} from "./style";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsModel {
  text: string;
  handleAgree: any;
  open: boolean;
  handleClose: any;
  isWarning:boolean;
  sendBtnText:string;
}

export default function ConfirmModal(props: PropsModel) {
  const { text, handleAgree, open, handleClose,isWarning,sendBtnText } = props;

  return (
    <Dialog
      sx={sxModalContainer}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box sx={sxTitleBox}>
        <DialogTitle sx={sxModalTitle}>{text}</DialogTitle>
        <DialogTitle sx={sxModalDescription}>
          Təsdiq etdiyiniz təqdirdə bu icazə leveli sistemdən tamamilə silinəcək
          və bu leveldə olan istifadəçilər standart levellərə qaytarılacaq.
          {isWarning && <span style={{ fontFamily: "Visby CF Bold" }}>
            Bu prosesi geri qaytarmaq mümkün deyildir.  
          </span>}
        </DialogTitle>
      </Box>
      <DialogActions
        sx={{ padding: 0, fontFamily: "Visby CF Bold, sans-serif" }}
      >
        <Button
          sx={{
            ...sxModalBottom,
            background: "#D54939",
            color: "#FFF",
            "&:hover": {
              background: "#973428",
            },
          }}
          variant="contained"
          onClick={handleAgree}
        >
          {sendBtnText}
         
        </Button>
        <Button
          sx={{
            ...sxModalBottom,
            background: "#FBEDEB",
            color: "#0E0D0D",
            "&:hover": {
              background: "#F2C7C2",
            },
          }}
          variant="contained"
          onClick={handleClose}
        >
          Ləğv et
        </Button>
      </DialogActions>
    </Dialog>
  );
}
