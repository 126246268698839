import {
    useDelete,
      useFetch, usePost, useUpdate,
  } from '../utils/api/reactQuery';
  import { apiRoutes } from '../apiRoutes';
  import { ISupportModel, ISupportCreateModel, ISupportUpdateModel, ISupportCategoriesModel } from '../types';

export const useGetSupportsList = () =>
  useFetch<ISupportModel[]>(apiRoutes.supports.getAll, undefined, [apiRoutes.supports.base, undefined]);

export const useCreateSupportList = (
  updater: (oldData: any, newData: any) => any) => 
  usePost<any, any>(apiRoutes.supports.create, undefined, updater)

export const useUpdateSupport = (id: number, updater: (oldData: any, newdata: any)=> any) => 
  useUpdate<ISupportUpdateModel, any>(apiRoutes.supports.update(id), undefined, updater);

export const useDeleteSupport = (id: number) => 
  useDelete(apiRoutes.supports.delete(id));

export const useGetSupportById = (id: number) => 
  useFetch<ISupportModel>(apiRoutes.supports.getById(id));

export const useGetSupportCategories = () =>
  useFetch<ISupportCategoriesModel>(apiRoutes.supports.getCategories)
