import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { sxModalContainer, sxModalTitle } from "./style";
import { useBanner } from "../../../../../../contexts/BannerContext";
interface PropsModel {
  data: any;
  handleChange: any;
  isOpen: boolean;
}
export const CustomSelectModal = (props: PropsModel) => {
  const { bannerVisible} = useBanner();
  const { data, handleChange, isOpen } = props;
  const calculatedHeight = data.length>3?140:data.length* 50;
  return (
    <>
        <Box sx={{ ...(isOpen ? { ...sxModalContainer, height: calculatedHeight, bottom:-calculatedHeight } : { display: "none" }) }}>
          {React.Children.toArray(
            data.map((item: any) => (
              <MenuItem
                key={item.name}
                onClick={(event) => {event.stopPropagation(); handleChange(item.name)}}
                sx={
                  item.name === "Lab günü" && !bannerVisible 
                    ? { ...sxModalTitle, border: "1px solid var(--Error-error-500, #D54939)"}
                    : sxModalTitle
                }
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))
          )}
        </Box>
    </>
  );
};
