import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "./assets/themes";
import { Provider } from "react-redux";
import "@fontsource/roboto/400.css";
import store from './store';
import {
  RouterProvider
} from "react-router-dom";
import router from './routes';
import AuthProvider from "./contexts/AuthContext";
import { BannerProvider } from "./contexts/BannerContext";

// replace console.* for disable log on production
if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log = () => {}
  console.warn = function () {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider>
          <BannerProvider>
            <RouterProvider router={router} />
          </BannerProvider>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
  </>
);