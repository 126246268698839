import React from "react";

export const sxSelect = {
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
    {
      padding: "0",
      fontFamily: "Visby CF Medium",
      fontSize: "14px",
    },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
};
export const sxTimePicker = {
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
  "& .css-5mr3t8-MuiInputBase-root-MuiOutlinedInput-root": {
    padding: "0",
    fontFamily: "Visby CF Bold, sans-serif",
    fontSize: "12px",
    color: "#000",
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "0",
  },
};
export const sxAutocomplete = {
  "& .MuiAutocomplete-inputRoot": {
    padding: "0",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
};
export const sxtimeSelect = {
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  '& input[type="number"]': {
    MozAppearance: "textfield",
  },
  padding: 0,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};
export const sxTableCell: React.CSSProperties = {
  fontSize: "14px",
  fontFamily: "Visby CF Bold, sans-serif",
  color: "#000000",
};

export const sxSelectInside = {
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
    {
      fontWeight: "400",
    },
  width: "100%",
};

export const sxButtonIcon: React.CSSProperties = {
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "flex-start",
  position: "relative",
  minWidth: "20px",
};

export const sxNoteIcon: React.CSSProperties = {
  width: "20px",
  height: "20px",
};

export const sxPoint: React.CSSProperties = {
  width: "8px",
  height: "8px",
  background: "#D54939",
  border: "2px solid #FFFFFF",
  position: "absolute",
  top: "-1px",
  right: "-2px",
  borderRadius: "50%",
};

export const sxEditIcon = {
  cursor: "pointer",
  height: "20px",
  width: "20px",
  transition: "0.5s ease all",
  "&:hover": {
    color: "primary.main",
  },
};
export const sxMenuItem = {
  display: "flex",
  width: "119px",
  padding: "4.5px 12px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "17px",
  background: "green",
  fontFamily: "Visby CF, sans-serif",
  fontWeight: "700",
  fontSize: "12px",
  color: "#FFF",
  lineHeight: "16px",
  letterSpacing: "0.4px",
  marginBottom: "4px",
};
export const sxSelectStatus = {
  "& .css-g51yed-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    display: "flex",
    width: "119px",
    padding: "4px 12px",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "17px",
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
    {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontFamily: "Visby CF, sans-serif",
      fontWeight: "700",
      fontSize: "12px",
      color: "#FFF",
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
  "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
    display: "none",
  },
};
