import { Box, Grid } from "@mui/material";
import React from "react";
import RightPanel from "../../pages/auth-views/login/components/RightPanel";
import { Outlet } from "react-router-dom";

export const AuthLayout = () => {
  
  return (
    <>
      <Box display="flex" sx={{ minHeight: "100vh" }} justifyContent="center">
        <Grid container spacing={0}>
          <Grid
            sx={{
              margin: "0",
              padding: "0",
            }}
            item
            xs={12}
            md={6}
          >
            <Outlet />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "block" },
              // border: "4px solid black",
              margin: "0",
              // height: "100%",
              padding: "0",
            }}
          >
            <RightPanel />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AuthLayout;
