import { useEffect, useState } from "react";
import Header from "./components/header";
import { Box } from "@mui/material";
import PermissionLevelsRow from "./components/permissionLevelsRow";
import AddPemisssionLevels from "./components/add-permission-levels";
import Footer from "../components/footer";
import { ChangeableStatus, PermissionLevel } from "../models";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";

const initialAddPermissionLevel: PermissionLevel = {
  id: 0,
  name: undefined,
  permissionAccesses: [],
};
const initialIsChangeableStatus: ChangeableStatus = {
  isEditPermissionLevel: false,
  isCreatePermissionLevel: false,
};

function PermissionLevels() {
  const [isChangeable, setIsChangeable] = useState<ChangeableStatus>(
    initialIsChangeableStatus
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addPermissionLevels, setAddPermissionLevel] =
    useState<PermissionLevel>(initialAddPermissionLevel);
  const [permissionLevels, setPermissionLevels] = useState<PermissionLevel[]>(
    []
  );

  const permissionLevelsService = new BaseService(
    "api/PermissionLevels/with-accesses"
  );
  const postPermissionLevelsService = new BaseService("api/PermissionLevels");
  const editPermissionLevelsService = new BaseService("api/PermissionLevels");
  const getPermissionLevels = async () => {
    setIsLoading(true);
    try {
      const res = await permissionLevelsService.getAll();
      setPermissionLevels(res.data);
      setIsLoading(false);
    } catch (err) {
      return console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPermissionLevels();
  }, [isChangeable]);
  const handleClick = () => {
    if (isChangeable.isCreatePermissionLevel) {
      const postPermissionLevels = {
        name: addPermissionLevels.name,
        accesses: addPermissionLevels.permissionAccesses.map((item) => ({
          id: item.id,
          isAccess: item.isAccess,
        })),
      };
      postPermissionLevelsService
        .add(postPermissionLevels)
        .then((res) => {
          setIsChangeable(initialIsChangeableStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (isChangeable.isEditPermissionLevel) {
      const editPermissionLevels = permissionLevels.map((item) => ({
        id: item.id,
        accesses: item.permissionAccesses.map((access) => ({
          id: access.id,
          isAccess: access.isAccess,
        })),
      }));
      editPermissionLevelsService
        .update(editPermissionLevels)
        .then((res) => {
          console.log(res);
          setIsChangeable(initialIsChangeableStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Header setIsChangeable={setIsChangeable} isChangeable={isChangeable} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {!isChangeable.isEditPermissionLevel && (
          <AddPemisssionLevels
            isChangeable={isChangeable}
            setIsChangeable={setIsChangeable}
            addPermissionLevel={addPermissionLevels}
            setAddPermissionLevel={setAddPermissionLevel}
          />
        )}
        {isLoading ? (
          <Progress />
        ) : (
          permissionLevels.map((item: PermissionLevel) => (
            <PermissionLevelsRow
              permissionLevelsRowId={item.id}
              key={item.id}
              isChangeable={isChangeable}
              disabled={isChangeable.isEditPermissionLevel}
              permissionLevels={item}
              setPermissionLevels={setPermissionLevels}
            />
          ))
        )}
      </Box>
      {(isChangeable.isCreatePermissionLevel ||
        isChangeable.isEditPermissionLevel) && (
        <Footer
          handleClose={() => {
            setIsChangeable(initialIsChangeableStatus);
          }}
          handleClick={() => handleClick()}
        />
      )}
    </>
  );
}

export default PermissionLevels;
