import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import TableHeader from "../../../../../../../components/custom-components/table-header";
import { sxDeleteIcon, sxSelect, sxTableCell, sxTimePicker } from "./style";
import TableCellBody from "../../../../../../../components/custom-components/table-cell-body";
import { Session } from "../../models/Session";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { PostClassSessions } from "../../models/PostClassSessions";
import { changeWeekday } from "../../helper/changeWeekDay";
import { changeWeekdayEng } from "../../helper/changeWeekDayEng";
import { SelectChangeEvent } from "@mui/material/Select";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface PropsModel {
  sessions: Session[];
  sessionsData: PostClassSessions;
  setSessionsData: React.Dispatch<React.SetStateAction<PostClassSessions>>;
  setSessions: React.Dispatch<React.SetStateAction<Session[]>>;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableButtonLab: React.Dispatch<React.SetStateAction<boolean>>;
}

function TableList(props: PropsModel) {
  const {
    sessions,
    sessionsData,
    setSessionsData,
    setSessions,
    setDisableButton,
    setDisableButtonLab
  } = props;

  const changedTimeFormat = (value: string) => {
    const now = new Date();
    const [hour, minute] = value.split(":");
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      Number(hour),
      Number(minute)
    );
  };

  const handleChange = (newValue: any, session: Session, name: string) => {
    setSessionsData((prev) => {
      return {
        ...prev,
        sessions: prev.sessions.map((q) => {
          return {
            ...q,
            theoricStart: q.category === "Lab" ? "" : (
              name === "theoricStart" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.theoricStart),
            theoricEnd: q.category === "Lab" ? "" : (
              name === "theoricEnd" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.theoricEnd),
            practicStart: q.category === "Lab" ? "" : (
              name === "practicStart" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.practicStart),
            practicEnd: q.category === "Lab" ? "" : (
              name === "practicEnd" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.practicEnd),
            labStart: q.category === "Lab" ? (
              name === "labStart" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.labStart) : "",
            labEnd: q.category === "Lab" ? (
              name === "labEnd" && q.dayOfWeek === session.dayOfWeek
                ? newValue?.$d!.toString().substring(16, 21)
                : q.labEnd) : "",
          };
        }),
      };
    });
  };
  useEffect(() => {
    const sortSessionsByDayOfWeek = (a: any, b: any) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek);
    };

    sessions.sort(sortSessionsByDayOfWeek);
  }, []);
  
  const weekDayChange = (event: SelectChangeEvent, id: string) => {
    const newDayOfWeek = changeWeekdayEng(event.target.value);
    setSessions((prevSessions) => {
      const updatedSessions = prevSessions.map((prevSession) => {
        if (prevSession.id === id) {
          return {
            ...prevSession,
            dayOfWeek: newDayOfWeek,
          };
        }
        return prevSession;
      });
      return updatedSessions;
    });
  };

  const handleDelete = (id: string, category: string) => {
    setSessions((prev) => prev.filter((session) => session.id !== id));
    if (category === "Lab") {
      setDisableButtonLab(false)
    } else {
      setDisableButton(false)
    }
  };

  return (
    <>
      {sessionsData.sessions.length > 0 && (
        <>
          <TableContainer>

            <Table
              sx={{
                width: "100%",
                border: "none",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={sxTableCell} align="left">
                    Həftənin günü
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    Ümumi saat
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    Dərs
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    Praktika
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontSize: "14px", fontFamily: 'Visby CF Bold, sans-serif' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {React.Children.toArray(
                    sessions.map((session: Session) => (
                      session.category !== "Lab" &&
                      <TableRow>
                        <TableCellBody width="20%">
                          <>
                            {session.days ? (
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  sx={sxSelect}
                                  value={changeWeekday(session.dayOfWeek)}
                                  onChange={(event) =>
                                    weekDayChange(event, session.id)
                                  }
                                >
                                  {React.Children.toArray(
                                    session.days.map((day: string) => (
                                      <MenuItem
                                        key={day}
                                        value={changeWeekday(day)}
                                      >
                                        {changeWeekday(day)}
                                      </MenuItem>
                                    ))
                                  )}
                                </Select>
                              </FormControl>
                            ) : (
                              changeWeekday(session.dayOfWeek)
                            )}
                          </>
                        </TableCellBody>
                        <TableCellBody width="10%">
                          <>
                            {session.hours ? (
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  sx={sxSelect}
                                  value={session.totalHours}
                                  onChange={(event: any) => {
                                    const newHour = event.target.value;
                                    setSessions((prevSessions) => {
                                      const updatedSessions = prevSessions.map(
                                        (prevSession) => {
                                          if (prevSession.id === session.id) {
                                            return {
                                              ...prevSession,
                                              totalHours: newHour,
                                            };
                                          }
                                          return prevSession;
                                        }
                                      );
                                      return updatedSessions;
                                    });
                                  }}
                                >
                                  {React.Children.toArray(
                                    session.hours.map((hour: number) => (
                                      <MenuItem key={hour} value={hour}>
                                        {hour}
                                      </MenuItem>
                                    ))
                                  )}
                                </Select>
                              </FormControl>
                            ) : (
                              session.totalHours
                            )}
                          </>
                        </TableCellBody>
                        <TableCellBody width="32%">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  sessionsData.sessions.find(
                                    (q) => q.dayOfWeek === session.dayOfWeek
                                  )?.theoricStart ?? ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, session, "theoricStart")
                              }
                              sx={{ sxTimePicker }}
                            />
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  sessionsData.sessions.find(
                                    (q) => q.dayOfWeek === session.dayOfWeek
                                  )?.theoricEnd ?? ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, session, "theoricEnd")
                              }
                              sx={{ sxTimePicker }}
                            />
                          </Box>
                        </TableCellBody>
                        <TableCellBody width="32%">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  sessionsData.sessions.find(
                                    (q) => q.dayOfWeek === session.dayOfWeek
                                  )?.practicStart ?? ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, session, "practicStart")
                              }
                              sx={{ sxTimePicker }}
                            />
                            <TimePicker
                              ampm={false}
                              value={dayjs(
                                changedTimeFormat(
                                  sessionsData.sessions.find(
                                    (q) => q.dayOfWeek === session.dayOfWeek
                                  )?.practicEnd ?? ""
                                )
                              )}
                              onChange={(newValue) =>
                                handleChange(newValue, session, "practicEnd")
                              }
                              sx={{ sxTimePicker }}
                            />
                          </Box>
                        </TableCellBody>
                        <TableCellBody width="6%">
                          {session.days && (
                            <DeleteOutlineIcon
                              onClick={() => handleDelete(session.id, "Day")}
                              sx={sxDeleteIcon}
                              color="secondary"
                            />
                          )}
                        </TableCellBody>
                      </TableRow>

                    ))
                  )}
                </LocalizationProvider>
              </TableBody>
            </Table>


            {/* table for lab */}
            {sessions.some((session) => session.category === "Lab") &&
              <Table
                sx={{
                  width: "100%",
                  border: "none",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={[sxTableCell, { width: "20%" }]} align="left">
                      Həftənin günü
                    </TableCell>
                    <TableCell sx={[sxTableCell, { width: "10%" }]} align="left">
                      Ümumi saat
                    </TableCell>
                    <TableCell sx={[sxTableCell, { width: "32%" }]} align="left">
                      Lab
                    </TableCell>
                    <TableCell sx={[sxTableCell, { width: "32%" }]} align="left">
                      
                    </TableCell>
                    <TableCell sx={[sxTableCell, { width: "6%" }]} align="left">
                      
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontSize: "14px", fontFamily: 'Visby CF Bold, sans-serif' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {React.Children.toArray(
                      sessions.map((session: Session) => (
                        session.category === "Lab" &&
                        <TableRow>
                          <TableCellBody width="20%">
                            <>
                              {session.days ? (
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    sx={sxSelect}
                                    value={changeWeekday(session.dayOfWeek)}
                                    onChange={(event) =>
                                      weekDayChange(event, session.id)
                                    }
                                  >
                                    {React.Children.toArray(
                                      session.days.map((day: string) => (
                                        <MenuItem
                                          key={day}
                                          value={changeWeekday(day)}
                                        >
                                          {changeWeekday(day)}
                                        </MenuItem>
                                      ))
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                changeWeekday(session.dayOfWeek)
                              )}
                            </>
                          </TableCellBody>
                          <TableCellBody width="10%">
                            <>
                              {session.hours ? (
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    sx={sxSelect}
                                    value={session.totalHours}
                                    onChange={(event: any) => {
                                      const newHour = event.target.value;
                                      setSessions((prevSessions) => {
                                        const updatedSessions = prevSessions.map(
                                          (prevSession) => {
                                            if (prevSession.id === session.id) {
                                              return {
                                                ...prevSession,
                                                totalHours: newHour,
                                              };
                                            }
                                            return prevSession;
                                          }
                                        );
                                        return updatedSessions;
                                      });
                                    }}
                                  >
                                    {React.Children.toArray(
                                      session.hours.map((hour: number) => (
                                        <MenuItem key={hour} value={hour}>
                                          {hour}
                                        </MenuItem>
                                      ))
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                session.totalHours
                              )}
                            </>
                          </TableCellBody>
                          <TableCellBody width="32%">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <TimePicker
                                ampm={false}
                                value={dayjs(
                                  changedTimeFormat(
                                    sessionsData.sessions.find(
                                      (q) => q.dayOfWeek === session.dayOfWeek && q.category===session.category
                                    )?.labStart ?? ""
                                  )
                                )}
                                onChange={(newValue) =>
                                  handleChange(newValue, session, "labStart")
                                }
                                sx={{ sxTimePicker }}
                              />
                              <TimePicker
                                ampm={false}
                                value={dayjs(
                                  changedTimeFormat(
                                    sessionsData.sessions.find(
                                      (q) => q.dayOfWeek === session.dayOfWeek && q.category===session.category
                                    )?.labEnd ?? ""
                                  )
                                )}
                                onChange={(newValue) =>
                                  handleChange(newValue, session, "labEnd")
                                }
                                sx={{ sxTimePicker }}
                              />
                            </Box>
                          </TableCellBody>
                          <TableCellBody width="32%">
                          </TableCellBody>
                          <TableCellBody width="6%">
                            {session.days && (
                              <DeleteOutlineIcon
                                onClick={() => handleDelete(session.id, "Lab")}
                                sx={sxDeleteIcon}
                                color="secondary"
                              />
                            )}
                          </TableCellBody>
                        </TableRow>

                      ))
                    )}
                  </LocalizationProvider>
                </TableBody>
              </Table>
            }
          </TableContainer>

        </>


      )}

    </>
  );
}

export default TableList;
