import { theme } from "../../../assets/themes"

export const sxHeader: React.CSSProperties = {
    height: "76px",
    padding: "6px 76px",
    background: "#FFFFFF",
    borderBottom: "1px solid #E9E9E9",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    position:"sticky",
   
}

export const sxSearch: React.CSSProperties = {
    height: "100%",
    width: "20%",
    display: "flex",
    alignItems: "center",
}

export const sxSearchIcon: React.CSSProperties = {
    width: "24px",
    height: "24px",
    marginRight: "10px"
}

export const sxNotification: React.CSSProperties = {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    marginRight:"4px"
}

export const sxUser: React.CSSProperties = {
    display: "flex",
    height:"100%",
    alignItems:"center",
}

export const sxAccount= {
    padding: "0px 16px", 
    display: "flex",
    alignItems:"center",
    height:"100%",
    borderRadius:"45px",
    transition:"0.5s ease all",
    cursor:"pointer",
    marginLeft:"4px",
    "&:hover": {
        background:theme.palette.primary.light
    }
}

export const sxAvatar: React.CSSProperties = {
    width: "32px",
    height: "32px",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight:"16px",
}

export const sxUserName:React.CSSProperties = {
    color:"#000000",
    fontFamily: 'Visby CF Bold, sans-serif',
    fontSize:"16px"
}
export const sxVideo: React.CSSProperties = {
    display:"flex",
    padding:"10px 16px",
    cursor:"pointer",
    alignItems:"center",
    gap:"8px",
    borderRadius:"16px",
    border:"1.5px solid #30A5FF",
    // borderImage: "linear-gradient(to right,#30A5FF,#702DFF) 1",
    
}