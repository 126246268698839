import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { BaseService } from "../../../../network/services/base/BaseService";
import { IRoleAssignee, WorkerModel } from "./models/assignRoles";
import Button from "../../../../components/core-components/inputs/button";
import axios from "axios";
import Progress from "../../../../components/custom-components/progress";
import Alert from "../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import { API_URL } from "../../../../configs/Appconfig";

function RoleAssignee() {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [roles, setRoles] = React.useState<IRoleAssignee[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedWorkerRoles, setSelectedWorkerRoles] = React.useState<any[]>(
    []
  );
  const [id, setId] = React.useState<any[]>([]);
  const [workerObj, setWorkerObj] = React.useState<any>({});
  const [selectedWorker, setSelectedWorker] = React.useState<string>("");
  const [workers, setWorkers] = React.useState<WorkerModel[]>([]);
  const roleService = new BaseService("api/Roles");
  const workerService = new BaseService("api/Workers");
  const [checked, setChecked] = useState<string[]>([]);
  const getWorkers = async () => {
    setLoading(true);
    const response = await workerService.getAll();
    setWorkers(response.data);
    setLoading(false);
  };
  const changeRole = (role: string): string => {
    switch (role) {
      case "admin":
        return "Admin";
      case "muellim":
        return "Müəllim";
      case "mentor":
        return "Mentor";
      default:
        return "-";
    }
  };
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const getRoles = async () => {
    setLoading(true);
    const response = await roleService.getAll();
    setRoles(response.data);
    setLoading(false);
  };
  function handleCheckboxChange(roleId: any, isChecked: any) {
    setId((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((obj) => obj.id === roleId);
      if (isChecked && index === -1) {
        newState.push({ id: roleId });
      } else if (!isChecked && index !== -1) {
        newState.splice(index, 1);
      }
      return newState;
    });
  }

  const handleSubmit = async () => {
    setLoading(true);

    const response = await axios.post(
       API_URL + `api/Users/${selectedWorker}/roles`,
      id,
      { withCredentials: true }
    );
    setLoading(false);

    if (response.status === 204) {
      setAlertInside({
        text: "Əməliyyat uğurlu oldu",
        severity: "success",
      });
      setAlertOpen(true);
      setId([]);
      setSelectedWorker("");
    } else {
      setAlertInside({
        text: "Əməliyyat uğursuz oldu",
        severity: "error",
      });
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    if (selectedWorker) {
      const defaultCheckedIds = roles
        .filter((role) =>
          workerObj.roles.some(
            (workerRole: IRoleAssignee) => workerRole.id === role.id
          )
        )
        .map((role) => ({ id: role.id }));

      setId(defaultCheckedIds);
    }
  }, [roles, selectedWorker, workerObj]);

  useEffect(() => {
    getRoles();

    getWorkers();
  }, [workerObj]);

  if (loading) {
    return <Progress />;
  }
  return (
    <>
        <Header />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Seçin</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ borderRadius: "15px" }}
                value={selectedWorker ?? ""}
                label="Seçin"
                onChange={(e) => {
                  setLoading(true);
                  const worker = workers.find((w) => w.id === e.target.value);
                  setWorkerObj(worker);

                  setSelectedWorker(e.target.value);

                  setLoading(false);
                }}
              >
                {workers &&
                  workers.map((worker) => (
                    <MenuItem key={worker.id} value={worker.id}>
                      {worker.name} {worker.surname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              {roles.map((role) => (
                <FormControlLabel
                  key={role.id}
                  control={
                    <Checkbox
                      disabled={!selectedWorker}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const roleId = role.id;
                        handleCheckboxChange(roleId, isChecked);
                      }}
                      value={role.id}
                      checked={id.some((item) => item.id === role.id)}
                    />
                  }
                  label={changeRole(role?.name)}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleSubmit}>Təyin et</Button>
          </Grid>
        </Grid>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default RoleAssignee;
