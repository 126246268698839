import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody as MaterialTableBody,
  TableContainer,
  TableRow,
  Button,
  TextField,
  SelectChangeEvent,
  Popover,
  Dialog,
  Slide,
} from "@mui/material";
import {
  sxButtonIcon,
  sxEditIcon,
  sxPoint,
  sxSelect,
  sxSelectInside,
} from "../style";
import { ChatLinesIcon } from "../../../../../../../../components/custom-components/chat-lines";
import { Student } from "../../../../models/Student";
import TableCellBody from "../../../../../../../../components/custom-components/table-cell-body";
import { Class } from "../../../../models/Class";
import { CategoryHours } from "../../../../models/CategoryHours";
import { LessonFormat } from "../../../../models/LessonFormat";
import StudentTableHeader from "../../table-header/student-table-header";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Modal from "../../../../../../EducationProcessesManagement/continuity-result-students/components/modal";
import { TransitionProps } from "react-transition-group/Transition";
import { ModalData } from "../../../../../../EducationProcessesManagement/continuity-result-students/models/ModalData";
import { StudentAttendance } from "../../../../../../EducationProcessesManagement/continuity-result-students/models/StudentAttendance";
import { BaseService } from "../../../../../../../../network/services/base/BaseService";
import { AlertColor } from "@mui/material/Alert";
import Alert from "../../../../../../../../components/custom-components/alert";
import { Teachers } from "../../../../models/Teachers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface TableListModel {
  hasTheoric: boolean;
  setHasTheoric: React.Dispatch<React.SetStateAction<boolean>>;
  hasPratice: boolean;
  hasLab: boolean;
  setHasLab: React.Dispatch<React.SetStateAction<boolean>>;
  students: Student[];
  classes?: Class[];
  selectedClass?: Class;
  categoryHours: CategoryHours[];
  setCategoryHours: React.Dispatch<React.SetStateAction<CategoryHours[]>>;
  setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  setSelectedClass?: React.Dispatch<React.SetStateAction<Class>>;
  lessonFormat: LessonFormat;
  format: string;
  teacherChecks?: any;
  setTeachers: React.Dispatch<React.SetStateAction<Teachers[]>>;
  isLessonsModalOpen:boolean;
  setIsLessonsModalOpen:React.Dispatch<React.SetStateAction<boolean>>;
}

function StudentTableList(props: TableListModel) {
  let {
    hasTheoric,
    hasPratice,
    students,
    categoryHours,
    setCategoryHours,
    setStudents,
    selectedClass,
    setSelectedClass,
    lessonFormat,
    format,
    hasLab,
    teacherChecks,
    setHasTheoric,
    setHasLab,
    setTeachers,
    isLessonsModalOpen,
    setIsLessonsModalOpen,
  } = props;
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [theoricHours, setTheoricHours] = useState<number[]>([]);
  const [practiceHours, setPracticeHours] = useState<number[]>([]);
  const [labHours, setLabHours] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<Student>({
    id: "",
    studentId: "",
    name: "",
    surname: "",
    attendance: 0,
    sessions: [],
  });
  const [studentAttendance, setStudentAttendance] = useState<StudentAttendance>(
    {
      id: "",
      name: "",
      surname: "",
      phone: "",
      eMail: "",
      attendancePercent: 0,
      attendances: [],
    }
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedStudentNote, setSelectedStudentNote] = useState<
    string | null | undefined
  >("");
  const [selectedStudentDetail, setSelectedStudentDetail] = useState([]);
  const [modalData, setModalData] = useState<ModalData[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const disableTheoric = false;
  // hasTheoric && hasPratice
  //   ? lessonFormat.name === "Dərs ləğv edildi" ||
  //     !teacherChecks.muellim ||
  //     lessonFormat.name === "Dərs/Praktika ləğv edildi"
  //   : lessonFormat.name === "Dərs ləğv edildi" ||
  //     (!teacherChecks.muellim && !teacherChecks.mentor);

  const disablePractice = false;
  // (hasPratice &&
  //   (lessonFormat.name === "Praktika ləğv edildi" ||
  //     !teacherChecks.mentor)) ||
  // lessonFormat.name === "Dərs/Praktika ləğv edildi";

  const disableLab = false;
  // !teacherChecks.mentor || lessonFormat.name === "Lab ləğv edildi";

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    student: Student,
    category: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedStudent(student);
    setSelectedCategory(category);
    setSelectedStudentNote(
      student.sessions.find(
        (session) => session.classSessionCategory === category
      )?.note
    );
  };

  const handleClose = () => setOpen(false);
  useEffect(() => {
    setStudents((prev: Student[]) => {
      return prev.map((student: Student) => {
        if (student.studentId === selectedStudent.studentId) {
          if (selectedCategory === "Theoric") {
            return {
              ...student,
              sessions: student.sessions.map((session) => {
                if (session.classSessionCategory === "Theoric") {
                  return {
                    ...session,
                    note: selectedStudentNote,
                  };
                } else {
                  return session;
                }
              }),
            };
          } else if (selectedCategory === "Practice") {
            return {
              ...student,
              sessions: student.sessions.map((session) => {
                if (session.classSessionCategory === "Practice") {
                  return {
                    ...session,
                    note: selectedStudentNote,
                  };
                } else {
                  return session;
                }
              }),
            };
          } else {
            return {
              ...student,
              sessions: student.sessions.map((session) => {
                if (session.classSessionCategory === "Lab") {
                  return {
                    ...session,
                    note: selectedStudentNote,
                  };
                } else {
                  return session;
                }
              }),
            };
          }
        } else {
          return student;
        }
      });
    });
  }, [selectedStudentNote]);
  useEffect(() => {
    setTheoricHours(
      categoryHours
        .filter((item) => item.category === "Theoric")
        .flatMap((item) => Array.from({ length: item.hour + 1 }, (x, i) => i))
    );
    setPracticeHours(
      categoryHours
        .filter((item) => item.category === "Practice")
        .flatMap((item) => Array.from({ length: item.hour + 1 }, (x, i) => i))
    );
    setLabHours(
      categoryHours
        .filter((item) => item.category === "Lab")
        .flatMap((item) => Array.from({ length: item.hour + 1 }, (x, i) => i))
    );
  }, [categoryHours]);
  const handleChange = (e: SelectChangeEvent, id: string, category: string) => {
    const updatedHour = parseInt(e.target.value);
    setStudents((prev: Student[]): Student[] => {
      const updatedData = prev.map((item: Student) => {
        if (item.studentId === id) {
          if (category === "Theoric") {
            return {
              ...item,
              sessions: item.sessions.map((session) => {
                if (session.classSessionCategory === "Theoric") {
                  return {
                    ...session,
                    hour: updatedHour,
                  };
                } else {
                  return session;
                }
              }),
            };
          } else if (category === "Practice") {
            return {
              ...item,
              sessions: item.sessions.map((session) => {
                if (session.classSessionCategory === "Practice") {
                  return {
                    ...session,
                    hour: updatedHour,
                  };
                } else {
                  return session;
                }
              }),
            };
          } else {
            return {
              ...item,
              sessions: item.sessions.map((session) => {
                if (session.classSessionCategory === "Lab") {
                  return {
                    ...session,
                    hour: updatedHour,
                  };
                } else {
                  return session;
                }
              }),
            };
          }
        } else {
          return {
            ...item,
          };
        }
      });
      return updatedData;
    });
  };

  const handleClickModal = (student: any) => {
    setSelectedStudentDetail(student);
    getStudentAttendance(student.id, selectedClass?.id!);
    setOpenModal(true);
  };

  const getStudentAttendance = (studentId: string, id: string) => {
    setModalLoading(true);
    const studdentAttendanceService = new BaseService(
      `/api/Students/${studentId}/attendances?classId=${id}`
    );
    studdentAttendanceService
      .getAll()
      .then((res: any) => {
        setStudentAttendance(res.data);

        setModalData(
          res.data.attendances.map((attendance: ModalData, index: number) => ({
            ...attendance,
            id: index,
          }))
        );
      })
      .catch((err: any) => console.log(err))
      .finally(() => setModalLoading(false));
  };
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            width: "100%",
            border: "none",
          }}
          aria-label="simple table"
        >
          <StudentTableHeader
            setTeachers={setTeachers}
            students={students}
            hasPratice={hasPratice}
            hasTheoric={hasTheoric}
            setHasTheoric={setHasTheoric}
            setHasLab={setHasLab}
            hasLab={hasLab}
            setStudents={setStudents}
            categoryHours={categoryHours}
            setCategoryHours={setCategoryHours}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            disableTheoric={disableTheoric}
            disablePractice={disablePractice}
            disableLab={disableLab}
            format={format}
            isLessonsModalOpen={isLessonsModalOpen}
            setIsLessonsModalOpen={setIsLessonsModalOpen}
          />
          <MaterialTableBody
            sx={{ fontSize: "14px", fontFamily: "Visby CF Bold, sans-serif" }}
          >
            {categoryHours.length > 0 ? (
              <>
                {React.Children.toArray(
                  students.map((student, index) => {
                    return (
                      <>
                        <TableRow key={student.id}>
                          <TableCellBody width="5%">{index + 1}</TableCellBody>
                          <TableCellBody width={"50%"}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <PersonOutlineOutlinedIcon
                                color={"secondary"}
                                sx={sxEditIcon}
                                onClick={() => handleClickModal(student)}
                              />
                              {student.name} {student.surname}
                            </Box>
                          </TableCellBody>
                          {hasTheoric ? (
                            <>
                              <TableCellBody
                                disabled={disableTheoric}
                                width="6%"
                              >
                                {theoricHours.length > 0 && (
                                  <Select
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        student.studentId,
                                        "Theoric"
                                      )
                                    }
                                    value={student.sessions
                                      .find(
                                        (item) =>
                                          item.classSessionCategory ===
                                          "Theoric"
                                      )
                                      ?.hour?.toString()}
                                    sx={[
                                      sxSelect,
                                      sxSelectInside,
                                      {
                                        pointerEvents: disableTheoric
                                          ? "none"
                                          : "",
                                        opacity: disableTheoric ? "0.4" : "1",
                                      },
                                    ]}
                                  >
                                    {React.Children.toArray(
                                      theoricHours.map((item) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })
                                    )}
                                  </Select>
                                )}
                              </TableCellBody>
                              <TableCellBody
                                disabled={disableTheoric}
                                width={"10%"}
                              >
                                <Box
                                  sx={{
                                    pointerEvents: disableTheoric ? "none" : "",
                                    opacity: disableTheoric ? "0.4" : "1",
                                  }}
                                >
                                  <Button
                                    onClick={(e) =>
                                      handleClick(e, student, "Theoric")
                                    }
                                    sx={sxButtonIcon}
                                  >
                                    <ChatLinesIcon student={student} />
                                    {student.sessions.find(
                                      (session) =>
                                        session.classSessionCategory ===
                                        "Theoric"
                                    )?.note && <Box sx={sxPoint}></Box>}
                                  </Button>
                                </Box>
                              </TableCellBody>
                            </>
                          ) : (
                            <></>
                          )}
                          {hasPratice ? (
                            <>
                              <TableCellBody
                                disabled={disablePractice}
                                width={"6%"}
                              >
                                {practiceHours.length > 0 && (
                                  <Select
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        student.studentId,
                                        "Practice"
                                      )
                                    }
                                    value={student.sessions
                                      .find(
                                        (item) =>
                                          item.classSessionCategory ===
                                          "Practice"
                                      )
                                      ?.hour?.toString()}
                                    sx={[
                                      sxSelect,
                                      sxSelectInside,
                                      {
                                        pointerEvents: disablePractice
                                          ? "none"
                                          : "",
                                        opacity: disablePractice ? "0.4" : "1",
                                      },
                                    ]}
                                  >
                                    {React.Children.toArray(
                                      practiceHours.map((item) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })
                                    )}
                                  </Select>
                                )}
                              </TableCellBody>
                              <TableCellBody
                                disabled={disablePractice}
                                width={"10%"}
                              >
                                <Box
                                  sx={{
                                    pointerEvents: disablePractice
                                      ? "none"
                                      : "",
                                    opacity: disablePractice ? "0.4" : "1",
                                  }}
                                >
                                  <Button
                                    onClick={(e) =>
                                      handleClick(e, student, "Practice")
                                    }
                                    sx={sxButtonIcon}
                                  >
                                    <ChatLinesIcon student={student} />
                                    {student.sessions.find(
                                      (session) =>
                                        session.classSessionCategory ===
                                        "Practice"
                                    )?.note && <Box sx={sxPoint}></Box>}
                                  </Button>
                                </Box>
                              </TableCellBody>
                            </>
                          ) : (
                            <></>
                          )} 
                          {hasLab ? (
                            <>
                              <TableCellBody disabled={disableLab} width={"6%"}>
                                {labHours.length > 0 && (
                                  <Select
                                    onChange={(e) =>
                                      handleChange(e, student.studentId, "Lab")
                                    }
                                    value={student.sessions
                                      .find(
                                        (item) =>
                                          item.classSessionCategory === "Lab"
                                      )
                                      ?.hour?.toString()}
                                    sx={[
                                      sxSelect,
                                      sxSelectInside,
                                      {
                                        pointerEvents: disableLab ? "none" : "",
                                        opacity: disableLab ? "0.4" : "1",
                                      },
                                    ]}
                                  >
                                    {React.Children.toArray(
                                      labHours.map((item) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })
                                    )}
                                  </Select>
                                )}
                              </TableCellBody>
                              <TableCellBody
                                disabled={disableLab}
                                width={"10%"}
                              >
                                <Box
                                  sx={{
                                    pointerEvents: disableLab ? "none" : "",
                                    opacity: disableLab ? "0.4" : "1",
                                  }}
                                >
                                  <Button
                                    onClick={(e) =>
                                      handleClick(e, student, "Lab")
                                    }
                                    sx={sxButtonIcon}
                                  >
                                    <ChatLinesIcon student={student} />
                                    {student.sessions.find(
                                      (session) =>
                                        session.classSessionCategory === "Lab"
                                    )?.note && <Box sx={sxPoint}></Box>}
                                  </Button>
                                </Box>
                              </TableCellBody>
                            </>
                          ) : (
                            <></>
                          )}
                          <TableCellBody width={"8%"}>
                            {student.attendance.toFixed(2)}%
                          </TableCellBody>
                        </TableRow>
                      </>
                    );
                  })
                )}
              </>
            ) : (
              <></>
            )}
          </MaterialTableBody>
        </Table>
      </TableContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TextField
          variant="outlined"
          defaultValue={selectedStudentNote || ""}
          multiline
          rows={3}
          inputProps={{
            maxLength: 300,
          }}
          placeholder="Qeyd..."
          sx={{ width: "100%"}}
          onBlur={(e) => {
            const updatedNote = e.target.value;
            if (updatedNote.length > 300) {
              setAlertOpen(true);
              setAlertInside({
                text: "Qeyd 300 simvoldan çox ola bilməz",
                severity: "error",
              });
            } else {
              setSelectedStudentNote(updatedNote);
            }
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      </Popover>

      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        <Modal
          selectedStudentDetail={selectedStudentDetail}
          studentAttendance={studentAttendance}
          modalData={modalData}
          modalLoading={modalLoading}
        />
      </Dialog>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default StudentTableList;
