import { SxProps } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      display: "flex",
      width: "600px",
      padding: "32px",
      margin: "0",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "32px",
      borderRadius: "16px",
      background: "#FFF",
      boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
    },
    "& .MuiDialogContent-root": {
      margin: 0,
      padding: 0,
      border: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "16px",
      alignSelf: "stretch",
    },
    "& #customized-dialog-title": {
      padding: "0",
      margin: "0",
      fontSize: "34px",
      color: "#000000",
      fontWeight: 700,
      fontStyle: "normal",
      letterSpacing: "0.4px",
      lineHeight: "40px",
      alignSelf: "stretch",
      fontFamily: "Visby CF Bold",
    },
    "& .MuiTypography-root": {
      color: "#131112",
      fontFamily: "Visby CF sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.4px",
    },
    "& .lesson": {
      fontWeight: 700,
      fontFamily: "Visby CF Bold",
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      padding: " 8px 24px",
      alignItems: "center",
      gap: "8px",
      borderRadius: "12px",
      background: "#F3EDF4",
      hover: "none",
    },
    "& .MuiButtonBase-root": {
      fontFamily: "Visby CF",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
      letterSpacing: "0.4px",
      color: "#0E0D0D",
      hover: "none",
    },
    "& .MuiIconButton-sizeMedium": {
      color: "#468CBC",
      background: "#EDF4F8",
      position: "absolute",
      right: "12px",
      top: "12px",
      fill: "#EDF4F8"
    },
  }));