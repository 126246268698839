import React from 'react';
import { ProgressPercentage } from '../../../../../../components/custom-components/progress-percentage';
import styles from './attendance-card.module.css';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import {Days} from '../../../../../../types';
import { Link, useNavigate } from 'react-router-dom';

export interface IAttendanceCardModel{
    attendingStudentsCount : number;
    date : string ;
    status : string | null;
    totalStudentsCount : number;
    percentage: number,
    classId: string
}

export default function AttendanceCard({status, date, attendingStudentsCount, totalStudentsCount, percentage, classId} : IAttendanceCardModel) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/admin/app/classes/${classId}/class-sessions/${date}`)
    }

  return (
        <div onClick={handleClick} className={`${styles.attendanceCard} 
                        ${status === null ? styles.attendanceCardAbsent : null}
                        ${status === "cancelled" ? styles.attendanceNoLesson : null}`} >
            <ModeEditOutlineSharpIcon className={styles.penIcon} fontSize='small'/>
            <div className={styles.dateWrapper}>
                <span className={styles.day}>
                    {new Date(date).getDate().toString().length === 1 ? `0${new Date(date).getDate()}` : new Date(date).getDate()}</span>
                <span className={styles.text}>{Days[new Date(date).getDay() as keyof typeof Days]}</span>
            </div>
            <div className={styles.percentageWrapper}>
                {
                    status === "cancelled" ? (
                        <p className={styles.text}>Bugun üçün dərs ləğv olunub</p>
                    ) : (
                        <>
                            <ProgressPercentage value={percentage} size={40} className={styles.percentage}/>
                            <div>
                        
                                <p className={styles.text}>{percentage}% total</p>
                                <p className={styles.text}>{attendingStudentsCount}/{totalStudentsCount} tələbə</p>
                            </div>
                        </>
                        )
                    }
            </div>

        </div>
  )
}
