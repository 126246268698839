import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
    { field: 'title', 
      headerName: 'Başlıq',
      flex: 1},
    {
      field: 'user.email',
      headerName: 'İstifadəçi',
      flex: 1,
      valueGetter: ({ row }) => row.user?.email
    },
    {
      field: 'createdDate',
      headerName: 'Tarix',
      flex: 1,
      renderCell: (params) => <span>{new Date(params.row.createdDate).toLocaleString()}</span>
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1
    },
  ];