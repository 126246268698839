import Box from "@mui/material/Box";
import { ProgressPercentage } from "../progress-percentage";
interface calculatePercentage {
  currentHour: number;
  totalHours: number;
}
export function CustomizedProgressBars(props: calculatePercentage) {
  const { currentHour, totalHours } = props;
  let percentage = 0;
  if (totalHours === 0) {
    return <ProgressPercentage  format="standart" value={percentage} />;
  }
  percentage = (currentHour / totalHours) * 100;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ProgressPercentage  format="standart" value={percentage} />
    </Box>
  );
}
