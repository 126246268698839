export const sxBoxCenter: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  justifyContent: "end",
  width: "100%",
};
export const sxFormControl = {
  display: "flex",

  "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
    right: "20px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
    {
      right: "20px",
    },
};
