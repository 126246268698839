import React from "react";
import Header from "./components/Header";
import SupportForm from "./components/SupportForm";

function Support() {
  return (
    <>
      <Header />
      <SupportForm />
    </>
  );
}

export default Support;
