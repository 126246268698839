import React from "react";

export const sxSelect={
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "0",
            fontWeight: "400",
            fontSize: "14px"
        },
        '& .MuiOutlinedInput-notchedOutline':
            { border: "none" },
}