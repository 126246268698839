import { theme } from "../../../../../../assets/themes"

export const sxFiltetText: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
  
}

export const sxArrowIcon = {
    padding: "0px",
    width:"24px",
    height:"24px",
    '&:hover': {
        background: theme.palette.secondary,
    },
}

export const sxReadOnlyFilter = {
    background: "#F7F7F7",
    border: "none",
    boxShadow: "none !important"
}