import React from 'react'
import PageHeader from '../../../../../../../components/custom-components/page-header';
import { Box } from '@mui/material';
import Breadcrumbs from '../../../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import { APP_PREFIX_PATH } from '../../../../../../../configs/Appconfig';
import PageTitle from '../../../../../../../components/custom-components/page-title';
import BreadcrumbsTypography from '../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';

function Header() {
    return (
      <>
        <PageHeader>
          <Box>
            {/* <Breadcrumbs>
              <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
              <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
              <BreadcrumbsLink name="İşçilər" url={`${APP_PREFIX_PATH}/worker-classes`} />
              <BreadcrumbsTypography name="Düzəliş et" />
            </Breadcrumbs> */}
            <Box>
              <PageTitle name="Düzəliş et" />
            </Box>
          </Box>
        </PageHeader>
      </>
    );
  }
  
  export default Header;