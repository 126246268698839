export const sxModalContainer = {
  position: "absolute",
  left:"0",
  display: "flex",
  width: "100%",
  overflowY: "auto",
  padding: "8px 0px",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "flex-start",
  borderRadius: "0px 0px 12px 12px",
  background: "#FFF",
  boxShadow: "0px 28px 44px 0px rgba(0, 0, 0, 0.13)",
  zIndex:999,
  scrollbarWidth: "thin", // scrollbar'ı daha ince yapar (firefox, safari gibi tarayıcılarda çalışabilir)
  scrollbarColor: "rgba(0, 0, 0, 0.5) transparent", // renk ayarı
};
export const sxModalTitle = {
  display: "flex",
  padding: "8px 24px",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  background: "#FFF",
  borderRadius: "6px"
};
