import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

interface PropsModel {
    name: string,
    value: string,
    bgColor:string
}
function ModalField(props: PropsModel) {
    const { name, value,bgColor } = props
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} padding={"16px 24px"} sx={{background:bgColor,borderRadius:"16px"}}>
                <Typography variant='h3' sx={{ fontSize: "14px", fontWeight: "500" }}>{name}</Typography>
                <Typography variant='h2' sx={{ fontSize: "14px", fontFamily: 'Visby CF Bold, sans-serif',}}>{value}</Typography>
            </Stack>
        </>
    )
}

export default ModalField