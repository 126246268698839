import React, { useState, useEffect } from "react";
import { Box, Collapse, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { datagridSx, detailStyles } from "./styles";
import {
  Modules,
  SubModules,
} from "../../../../../../network/models/program/Program";
import Header from "./components/header";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Progress from "../../../../../../components/custom-components/progress";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../../../store/reducers";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { fetchPrograms } from "../../../../../../store/reducers/programReducer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";

function List() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [clickedIndex, setClickedIndex] = useState<string>("");
  const [clickedSubIndex, setClickedSubIndex] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  let navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const data = useSelector((state: RootState) => state.program.programs);
  const statusPrograms = useSelector(
    (state: RootState) => state.program.status
  );

  const sortedData = data
    ?.slice()
    .sort((a:any, b:any) => a?.name.localeCompare(b?.name));
  useEffect(() => {
    dispatch(fetchPrograms());
  }, []);
  const filteredPrograms = sortedData?.filter((item) => {
    return item?.name?.toLowerCase().includes(searchQuery.toLowerCase());
  });
  useEffect(() => {
    if (statusPrograms === "loading") setLoading(true);
    else setLoading(false);
  }, [statusPrograms]);

  const columns: GridColDef[] = [
    {
      field: "list",
      headerName: "",
      // width: 27,
      flex: 0.1,
      sortable: false,
      headerClassName: "column-list-grid",
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.modules.length > 0) {
          return (
            <>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  onClick={() =>
                    clickedIndex === params.row.id
                      ? setClickedIndex("")
                      : setClickedIndex(params.row.id)
                  }
                  sx={{
                    color: "#4F5259",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    height: "48px",
                  }}
                >
                  {params.row.id === clickedIndex ? (
                    <KeyboardDoubleArrowDownOutlinedIcon color="secondary" />
                  ) : (
                    <KeyboardDoubleArrowRightOutlinedIcon color="secondary" />
                  )}
                </Box>

                {/* <Collapse in={params.id === clickedIndex}> */}
                  {params.id === clickedIndex && React.Children.toArray(
                    params.row.modules.map((item: any) => (
                      <>
                        <Divider
                          sx={{
                            position: "absolute",
                            width: "100%",
                            left: "0",
                          }}
                        />
                        <Box
                          sx={[
                            {
                              color: "#4F5259",
                              cursor: "pointer",
                              paddingLeft: "10px",
                            },
                            detailStyles,
                          ]}
                        >
                          {item.subModules.length > 0 ? (
                            <Box
                              onClick={() =>
                                clickedSubIndex === item.id
                                  ? setClickedSubIndex("")
                                  : setClickedSubIndex(item.id)
                              }
                            >
                              {item.id === clickedSubIndex ? (
                                <KeyboardDoubleArrowDownOutlinedIcon color="secondary" />
                              ) : (
                                <KeyboardDoubleArrowRightOutlinedIcon color="secondary" />
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        {/* <Collapse in={item.id === clickedSubIndex}> */}
                          {item.id === clickedSubIndex && React.Children.toArray(
                            item.subModules.map(() => (
                              <>
                                <Divider
                                  sx={{
                                    position: "absolute",
                                    width: "100%",
                                    left: "0",
                                  }}
                                />
                                <Box
                                  sx={[detailStyles, { paddingLeft: "20px" }]}
                                ></Box>
                              </>
                            ))
                          )}
                        {/* </Collapse> */}
                      </>
                    ))
                  )}
                {/* </Collapse> */}
              </Box>
            </>
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Proqram adı",
      // width: 167,
      flex: 0.8,
      cellClassName: "programName-cell",
      renderCell: (params: GridRenderCellParams) => {
        return cell(params);
      },
    },
    {
      field: "subModulesSize",
      headerName: "Alt modullar",
      // width: 125,
      flex: 0.6,
      valueGetter: (params) => params.row.modules.length,
      renderCell: (params: GridRenderCellParams) => {
        return cell(params);
      },
    },
    {
      field: "totalHours",
      headerName: "Ümumi saat",
      align: "left",
      headerAlign: "left",
      // width: 125,
      flex: 0.6,
      renderCell: (params: GridRenderCellParams) => {
        return cell(params);
      },
    },
    {
      field: "operations",
      headerName: "Əməliyyat",
      headerClassName: "column-operation-grid",
      cellClassName: "row-operation-grid",
      sortable: false,
      align: "right",
      headerAlign: "right",
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={detailStyles}>
              <EditOutlinedIcon
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`${params.row.id}`, {
                    state: {
                      program: params.row,
                    },
                  })
                }
              />
            </Box>
          </Box>
        );
      },
      width: 125,
    },
  ];

  const cell = (params: GridRenderCellParams) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={detailStyles}>
          <div>
            {params.value}{" "}
            {params.field === "totalHours"
              ? "Saat"
              : params.field === "subModulesSize"
                ? "Alt modul"
                : ""}
          </div>
        </Box>
        {params.row.modules.length > 0 && (
          // <Collapse in={params.id === clickedIndex}>
          <>
            {params.id === clickedIndex && React.Children.toArray(
              params.row.modules.map((item: Modules) => (
                <>
                  <Box
                    sx={[
                      detailStyles,
                      { paddingLeft: params.field === "name" ? "10px" : "0" },
                    ]}
                  >
                    {params.field === "name"
                      ? `${item.version}. ${item.name}`
                      : params.field === "totalHours"
                        ? `${item.hours} Saat`
                        : `${item.subModules.length} Alt modul`}
                  </Box>
                  {/* <Collapse in={item.id!.toString() === clickedSubIndex}> */}
                    {item.id!.toString() === clickedSubIndex && React.Children.toArray(
                      item.subModules.map((subModule: SubModules) => (
                        <Tooltip
                          title={params.field === "name"
                            ? `${subModule.version} ${subModule.name}`
                            : params.field === "totalHours"
                              ? `${subModule.hours} Saat`
                              : `0 Alt modul`} >
                          <Typography
                            sx={[
                              detailStyles,
                              {
                                paddingLeft:
                                  params.field === "name" ? "20px" : "0",
                                fontSize: "14px"
                              },
                            ]}
                          >
                            {params.field === "name"
                              ? `${subModule.version} ${subModule.name}`
                              : params.field === "totalHours"
                                ? `${subModule.hours} Saat`
                                : `0 Alt modul`}
                          </Typography>

                        </Tooltip>

                      ))
                    )}
                  {/* </Collapse> */}
                </>
              ))
            )}
            </>
          // </Collapse>
        )}
      </Box>
    );
  };

  return (
    <>
        <Header />
        <Box sx={{ width: "100%" }}>
          {data.length > 0 ? (
            <DataGrid
              rows={filteredPrograms!}
              autoHeight={true}
              components={{
                Toolbar: () => (
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      autoFocus
                      variant="standard"
                      placeholder="Proqram axtar.."
                      value={searchQuery}
                      onChange={(event: any) =>
                        setSearchQuery(event.target.value.trimStart())
                      }
                    />
                  </Box>
                ),
              }}
              columns={columns}
              // autoHeight={true}
              disableColumnMenu
              pageSize={pageSize}
              getRowHeight={() => "auto"}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: "Səhifədəki sətir sayı:",
                },
              }}
              rowsPerPageOptions={[5, 10, 15]}
              // experimentalFeatures={{ newEditingApi: true }}
              // rowThreshold={0}
              sx={datagridSx}
            />
          ) : (
            loading && <Progress />
          )}
        </Box>
    </>
  );
}

export default List;
