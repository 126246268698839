import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  sxAvatar,
  sxClassName,
  sxModal,
  sxPieChart,
  sxStudentName,
} from "./style";
import Avatar from "@mui/material/Avatar";
import ModalField from "../modal-field";
import DataGrid from "../../../../../../components/custom-components/data-grid";
import { GridColDef } from "@mui/x-data-grid";
import "moment/locale/az";
import { ModalData } from "../../models/ModalData";
import Progress from "../../../../../../components/custom-components/progress";
import moment from "moment";
import { StudentAttendance } from "../../models/StudentAttendance";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

interface PropsModel {
  modalData: ModalData[];
  modalLoading: boolean;
  studentAttendance: StudentAttendance;
  selectedStudentDetail: any;
}

function Modal(props: PropsModel) {
  const { modalData, modalLoading, selectedStudentDetail, studentAttendance } =
    props;
  const [pageSize, setPageSize] = useState<number>(8);

  const changeCategory = (category: string): string => {
    switch (category) {
      case "Theoric":
        return "Dərs";
      case "Practice":
        return "Lab";
      default:
        return "-";
    }
  };

  const tabColumns: GridColDef[] = [
    {
      field: "date",
      headerName: "Tarix",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params?.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "attendanceHours",
      headerName: "İştirak",
      flex: 0.6,
    },
    {
      field: "lessonHours",
      headerName: "Ümumi saat",
      flex: 0.6,
    },
    {
      field: "category",
      headerName: "Kategoriya",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {changeCategory(params?.value)}
          </Typography>
        );
      },
    },
  ];

  if (modalLoading) {
    return <Progress />;
  }

  return (
    <>
      <Box sx={sxModal}>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
          <Box sx={{ paddingRight: "16px", borderRight: "1px solid #E9E9E9" }}>
            <Stack direction={"row"} spacing={"12px"} marginBottom={"24px"}>
              <Avatar sx={sxAvatar} alt="Remy Sharp" src="" />
              <Stack justifyContent={"space-evenly"}>
                <Typography variant="h2" sx={sxStudentName}>
                  {studentAttendance.name} {studentAttendance.surname}
                </Typography>
                <Typography variant="h5" sx={sxClassName}>
                  {selectedStudentDetail?.className}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"column"}>
              <ModalField
                name={"Telefon nömrəsi:"}
                value={`${studentAttendance?.phone || "-"}`}
                bgColor={"transparent"}
              />
              <ModalField
                name={"Mail:"}
                value={`${
                  studentAttendance?.eMail ? studentAttendance?.eMail : "-"
                }`}
                bgColor={"#EDF4F8"}
              />
              <ModalField
                name={"Davamiyyət faizi:"}
                value={
                  selectedStudentDetail?.attendance === 100
                    ? `${selectedStudentDetail?.attendance}%`
                    : (selectedStudentDetail?.attendance?.toFixed(2) ||
                        studentAttendance?.attendancePercent ||
                        "-") + "%"
                }
                bgColor={"transparent"}
              />
              <Box sx={sxPieChart}>
                <PieChart width={360} height={270}>
                  <Pie
                    data={[
                      {
                        name: "İştirak",
                        value: selectedStudentDetail?.attendance,
                      },
                      {
                        name: "Yoxdur",
                        value: 100 - selectedStudentDetail?.attendance,
                      },
                    ]}
                    innerRadius={20}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={(item) => `${item.value.toFixed(2)}%`}
                  >
                    <Cell fill={"#2E7D32"} />
                    <Cell fill={"#d32f2f"} />
                  </Pie>
                  <Tooltip formatter={(value) => `${value}%`} />
                </PieChart>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ paddingLeft: "16px" }} position={"relative"}>
            <Box sx={{ width: "100%", height: "524px", position: "relative" }}>
              <DataGrid
                data={modalData ?? []}
                columnsData={tabColumns}
                pageSizeData={15}
                setPageSize={setPageSize}
                disableColumnMenu={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Modal;
