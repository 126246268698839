import AuthPage from "../../403";
import { IPermissionModel } from "../../permissions";
import { UserHasPermission } from "../hooks/userPermissions";

export function RoleProvider({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: IPermissionModel;
}) {

  const res = UserHasPermission(permission);

  if(res){
    return children;
  }else{
    window.location.href = '/403';
    return <></>;
  }
}
