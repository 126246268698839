import React, { useEffect, useState } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "moment/locale/az";
import moment from "moment";
import { TeacherDetails } from "../models/ReportModels";
import {
  sxAvatar,
  sxClassName,
  sxModal,
  sxStudentName,
} from "../../../../EducationProcessesManagement/continuity-result-students/components/modal/style";
import Progress from "../../../../../../components/custom-components/progress";
import ModalField from "./ModalField";
import { localeText } from "../../../../../../configs/Locales";
import { WorkerByClassDetails } from "../models/ClassModels";
import { Cell, Pie, PieChart, Tooltip as RechartsTooltip } from "recharts";

interface PropsModel {
  modalData: WorkerByClassDetails;
  modalLoading: boolean;
}
let counter: number = 0;

const getRowIdClass = () => {
  counter++;
  return counter;
};

function ClassDetailsModal(props: PropsModel) {
  const { modalData, modalLoading } = props;
  const [pageSize, setPageSize] = useState<number>(8);
  const changeStatus = (status: string): string => {
    switch (status) {
      case "Offline":
        return "Oflayn";
      case "Online":
        return "Onlayn";
      default:
        return "-";
    }
  };
  const changeFormat = (category: string): string => {
    switch (category) {
      case "Theoric":
        return "Dərs";
      case "Practice":
        return "Praktika";
      case "Lab":
        return "Lab";
      default:
        return "-";
    }
  };

  const tabColumns: GridColDef[] = [
    {
      field: "date",
      headerName: "Tarix",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {changeStatus(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "totalHours",
      headerName: "Ümumi saat",
      flex: 0.6,
    },
    {
      field: "category",
      headerName: "Kategoriya",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {changeFormat(params.value)}
          </Typography>
        );
      },
    },
  ];

  const getRowId = (row: any) => row.classId;
  if (modalLoading) {
    return <Progress />;
  }

  return (
    <>
      <Box sx={sxModal}>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
          <Box sx={{ paddingRight: "16px", borderRight: "1px solid #E9E9E9" }}>
            <Stack direction={"row"} spacing={"12px"} marginBottom={"24px"}>
              <Avatar sx={sxAvatar} alt="Remy Sharp" src="" />
              <Stack justifyContent={"space-evenly"}>
                <Typography variant="h2" sx={sxStudentName}>
                  {modalData?.className}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"column"}>
              <ModalField
                name={"Online Dərs saatı sayğacı"}
                value={modalData?.onlineHours?.toString()}
                bgColor={"#EDF4F8"}
              />
              <ModalField
                name={"Offline dərs saat sayğacı"}
                value={modalData?.offlineHours?.toString()}
                bgColor={"transparent"}
              />
              <ModalField
                name={"Ləğv edilən dərs saatı sayğacı"}
                value={modalData?.cancaledHours}
                bgColor={"#EDF4F8"}
              />
              <ModalField
                name={"Toplam Dərs saatı"}
                value={`${
                  modalData.cancaledHours +
                  modalData.offlineHours +
                  modalData.onlineHours
                }`}
                bgColor={"transparent"}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <PieChart width={360} height={270}>
                  <Pie
                    data={[
                      {
                        name: "Tədris edilən dərs saatı",
                        value:
                          (100 *
                            (modalData.offlineHours + modalData.onlineHours)) /
                          (modalData.cancaledHours +
                            modalData.offlineHours +
                            modalData.onlineHours),
                      },
                      {
                        name: "Ləğv edilən dərs saatı",
                        value:
                          (100 * modalData.cancaledHours) /
                          (modalData.cancaledHours +
                            modalData.offlineHours +
                            modalData.onlineHours),
                      },
                    ]}
                    // isAnimationActive={false}
                    innerRadius={20}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={(item) => `${item.value.toFixed(2)}%`}
                  >
                    <Cell fill={"#2E7D32"} />
                    <Cell fill={"#d32f2f"} />
                  </Pie>
                  <RechartsTooltip formatter={(value) => `${value}%`} />
                </PieChart>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ paddingLeft: "16px" }} position={"relative"}>
            <Box sx={{ width: "100%", height: "524px", position: "relative" }}>
              <DataGrid
                // hideFooterPagination
                hideFooterSelectedRowCount
                autoHeight
                localeText={localeText}
                rowsPerPageOptions={[8]}
                pageSize={8}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: "Səhifədəki sətir sayı:",
                  },
                }}
                getRowId={getRowIdClass}
                columns={tabColumns}
                rows={modalData.workerClassSessions ?? []}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ClassDetailsModal;
