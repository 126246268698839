import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertColor } from "@mui/material/Alert";
import HistoryIcon from "@mui/icons-material/History";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { SelectChangeEvent } from "@mui/material/Select";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import Filter from "../../../../../../components/layout-components/filter";
import { sxFiltetText } from "../../../../EducationProcessesManagement/continuity/components/filters/style";
import { Session } from "../models/Session";
import { ApiInstance } from "../../../../../../network/services/core/apiInstance";
import Progress from "../../../../../../components/custom-components/progress";
import TableList from "../components/table-list";
import Alert from "../../../../../../components/custom-components/alert";
import { SessionDetail } from "../models/SessionDetail";
import { PostClassSessions } from "../../models/PostClassSessions";

interface Seans {
  id: string;
  name: string;
  details: Session[];
}
interface PropsModel {
  classId: any;
  DatePicker: boolean;
  selectedSeans: string;
  handleChangeSeans: (event: SelectChangeEvent) => void;
  sessionsData?: PostClassSessions;
  setSessionsData?: React.Dispatch<React.SetStateAction<PostClassSessions>>;
}
function SetSesion(props: PropsModel) {
  const {
    classId,
    selectedSeans,
    DatePicker,
    sessionsData,
    setSessionsData,
    handleChangeSeans,
  } = props;
  const [seans, setSeans] = useState<Seans[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionsDetail, setSessionsDetail] = useState<SessionDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [groupedDataState, setGroupedDataState] = useState<any>();
  const seansService = new BaseService("/api/Sessions");
  const getSeans = async () => {
    try {
      const res = await seansService.getAll();
      setSeans(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  const SessionDetails = new BaseService("/api/Sessions");
  const getSessionDetails = async () => {
    try {
      setLoading(true);
      const res = await SessionDetails.getById(selectedSeans);
      const sortSessionsByDayOfWeek = (a: any, b: any) => {
        const daysOfWeek = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
      };
      const sortedData = [...res.data.details].sort((a, b) =>
        sortSessionsByDayOfWeek(a.dayOfWeek, b.dayOfWeek)
      );
      setSessions(sortedData ?? []);
    } catch (err) {
      return console.log(err);
    }
  };
  useEffect(() => {
    getSeans();
  }, [selectedSeans]);
  useEffect(() => {
    getStudentsDetailBySession(classId, selectedSeans);
    getSessionDetails();
  }, [selectedSeans]);
  const getStudentsDetailBySession = async (
    id: string,
    selectedSeans: string
  ) => {
    try {
      const classService = new BaseService(`api/Classes`);
      const res = await classService.getById(
        `${id}?sessionId=${selectedSeans}`
      );
      setSessionsDetail(res.data);
    } catch (error) {
      return console.log(error);
    }
  };
  useEffect(() => {
    if (sessions.length > 0) {
      let groupedData: Record<
        string,
        Record<string, Session>
      > = sessions.reduce((acc, entry) => {
        const dayKey = entry.dayOfWeek;
        const categoryKey = entry.category;
        if (!acc[dayKey]) {
          acc[dayKey] = {};
        }
        acc[dayKey][categoryKey] = entry;
        return acc;
      }, {} as Record<string, Record<string, Session>>);
      setGroupedDataState(groupedData);
      setLoading(false);
    }
  }, [sessions]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <Grid container spacing={"8px"}>
            <Grid item xs={2.4}>
              <Filter>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    marginBottom: "8px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Sean
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiSelect-select": { padding: "0" },
                    height: "24px",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSeans}
                    onChange={handleChangeSeans}
                    // disabled={categoryHours ? (categoryHours.length>0 ? false : true) : true}
                    sx={[
                      sxFiltetText,
                      {
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    ]}
                  >
                    {React.Children.toArray(
                      seans.map((item: Seans) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Filter>
            </Grid>
            <Grid item xs={2.4}>
              <Filter>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    marginBottom: "8px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Dərs saatı
                </Typography>
                <Box
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={sxFiltetText} variant="h5">
                    {sessionsDetail?.totalHours} Saat
                  </Typography>
                  <HistoryIcon color="primary" />
                </Box>
              </Filter>
            </Grid>
            <Grid item xs={2.4}>
              <Filter>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    marginBottom: "8px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Həftə sayı
                </Typography>
                <Box
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={sxFiltetText} variant="h5">
                    32 həftə
                  </Typography>
                  <CalendarTodayOutlinedIcon color="primary" />
                </Box>
              </Filter>
            </Grid>
            <Grid item xs={2.4}>
              <Filter>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    marginBottom: "8px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Başlama tarixi
                </Typography>
                <Box
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={sxFiltetText} variant="h5">
                    {moment(sessionsDetail?.startDate).format("DD MMMM YYYY")}
                  </Typography>
                  <CalendarTodayOutlinedIcon color="primary" />
                </Box>
              </Filter>
            </Grid>
            <Grid item xs={2.4}>
              <Filter>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    marginBottom: "8px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  Bitmə tarixi
                </Typography>
                <Box
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={sxFiltetText} variant="h5">
                    {moment(sessionsDetail?.endDate).format("DD MMMM YYYY")}
                  </Typography>
                  <CalendarTodayOutlinedIcon color="primary" />
                </Box>
              </Filter>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="flex-start" gap="56px">
            {DatePicker && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="16px"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    // value={selectedDate}
                    // onChange={(date) => setSelectedDate(date)}
                    sx={{
                      "& .MuiDayCalendar-weekDayLabel": {
                        color: "#0E0D0D",
                        fontFamily: "Visby CF Bold, sans-serif",
                      },
                      "& .MuiDateCalendar-root": {
                        borderRadius: "24px",
                        border: "1px solid #E9E9E9",
                      },
                      "& .MuiPickersDay-root": {
                        color: "#0E0D0D",
                        fontFamily: "Visby CF DemiBold, sans-serif",
                      },
                      "& .Mui-selected": {
                        color: "#FFF",
                      },
                      "& .MuiPickersToolbar-root": {
                        display: "none",
                      },
                      "& .MuiDialogActions-root": {
                        display: "none",
                      },
                    }}
                  />
                </LocalizationProvider>
                <Box>
                  <Typography
                    component="span"
                    sx={{ color: "#6B696A", fontSize: "14px" }}
                  >
                    Göstərilir:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: "#0E0D0D",
                      fontSize: "14px",
                      fontFamily: "Visby CF Bold, sans-serif",
                    }}
                  >
                    {" "}
                    9 Dec 2023 - 15 Dec 2023
                  </Typography>
                </Box>
              </Box>
            )}
            <TableList
              sessionsData={sessionsData}
              setSessionsData={setSessionsData}
              groupedDataState={groupedDataState}
            />
          </Box>
          {/* <InformativeText
            text={
              "Diqqət! Yadda saxla düyməsinin aktiv olması üçün daxil edilən saatların toplamı ümumi saata bərabər olmalıdır."
            }
          /> */}
        </>
      )}
    </>
  );
}
export default SetSesion;
