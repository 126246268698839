import { SxProps, Theme } from "@mui/material";

export const sxTableCell: React.CSSProperties = {
  border: "none",
  padding: "5px 0px 16px 16px",
};
export const sxTableCellTypography: React.CSSProperties = {
  borderRadius: "8px",
  padding: "8px 16px",
  fontSize: "14px",
  fontFamily: "Visby CF Bold, sans-serif",
  lineHeight: "24px" /* 171.429% */,
  letterSpacing: " 0.4px",
  color: "#FFF",
};

export const sxGroupName: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const sxTimePicker: SxProps<Theme> = {
  "& .MuiInputBase-root": {
    padding: 0,
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F7F7F7",
    // border: "1px solid red",
    // '&:hover': {
    //   border: "1px solid green",
    // },
    // '&.Mui-focused': {
    //   border: "1px solid blue",
    // },
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border: 0,
  },
  "& .MuiInputBase-input": {
    padding: "10px 16px",
  },
};

export const sxSelect = {
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
    {
      padding: "0",
      fontSize: "14px",
    },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
};

export const sxDeleteIcon = {
  cursor: "pointer",
  transition: "0.5s ease all",
  "&:hover": {
    color: "primary.main",
  },
};
