export const sxModal: React.CSSProperties = {
  padding: "32px",
};

export const sxModalHeader: React.CSSProperties = {
  fontSize: "24px",
  fontFamily: 'Visby CF Bold, sans-serif',
  marginBottom: "32px",
};

export const sxFieldHeader: React.CSSProperties = {
  fontSize: "12px",
  fontFamily: 'Visby CF Bold, sans-serif',
};

export const sxField = {
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: "14px",
  },
  fontSoize: "14px",
};

export const sxSelect = {
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
    {
      fontFamily: 'Visby CF Bold, sans-serif',
      fontSize: "14px",
    },
  borderRadius: "14px",
};

export const sxSearch: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const sxSearchIcon: React.CSSProperties = {
  width: "24px",
  height: "24px",
  marginRight: "10px",
  // marginTop: "30px",
};
