import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button
} from '@mui/material';
import { useState } from 'react';
import uuid from 'react-uuid';
import CustomDatePicker from '../date-picker';
import CustomAutocomplete from "../../../../../../../components/custom-components/custom-autocomplete";
import AddHoursInput from './addHoursInput';
import dayjs from 'dayjs';

export interface IWorkerModel{
  workerId: number | undefined,
  roleId: number | undefined,
  name: string| undefined,
  surname: string| undefined,
  id: any,
  role: string | null
}

export interface IAddModuleModel{
  name: string,
  hours: number | null,
  workers: IWorkerModel[],
  startDate: string | null,
  endDate: string | null,
  id: any,
  isChecked: boolean,
  isNewModel: boolean
}

const bgColor = '#eef4f8';
const errorMessage = {
  color: 'red',
  display: 'block',
}

const tableCellStyle = {
  py: 0,
  backgroundColor: bgColor,
  position: 'relative'
}

export default function AddModuleRow({newModules, setNewModules, setIsAddRowOpen} : any) {
    const [model, setModel] = useState<IAddModuleModel>({
        name:  '',
        hours:  null,
        workers: [
            {
                workerId: undefined,
                roleId: undefined,
                name: undefined,
                surname: undefined,
                id: null,
                role: 'muellim'
            },
            {
                workerId: undefined,
                roleId: undefined,
                name: undefined,
                surname: undefined,
                id: null,
                role: 'mentor'
            }
        ],
        startDate: null,
        endDate: null,
        id: uuid(),
        isChecked: false,
        isNewModel: true
    });

    const [modelErrors, setModelErrors] = useState<any>({
        name:  null,
        hours:  null,
        startDate: null,
        endDate: null,
    })

    const handleClick = () => {
      let errors: any = {};
      for(let key in model){
        if((model as any)[key] === null || (model as any)[key] === '' || (model as any)[key] === 0 ){
          errors = {...errors, [key]: 'Boş ola bilməz!'};
        }
      }
      if(dayjs(model.endDate!).isBefore(dayjs(model.startDate))){
        errors = {...errors, endDate: 'Bitmə tarixi başlama tarixindən tez ola bilməz'}
      }
      if(Object.keys(errors).length !== 0){
        setModelErrors(errors);
        return;
      }

      setNewModules([...newModules, model]);
      setIsAddRowOpen(false)
    }

    const handleDelete = () => {
      setIsAddRowOpen(false)
    }

    const handleFieldChange = (key: string, value: any) => {
      setModelErrors({...modelErrors, [key] : null })
      if(key === 'muellim' || key === 'mentor'){
        const index: any = model.workers?.findIndex(x=> x.role === key);
        (model as any).workers[index] = {id: (value! as any).id,  workerId: (value! as any).id, roleId: (value! as any).roles[0].id, name: (value! as any).name, surname: (value! as any).surname, role: key}
        setModel({...model});
      }
      setModel({...model, [key] : value})
    }

  return (
    <>
      <TableRow>
        <TableCell width={'3%'} sx={{ backgroundColor: bgColor }}></TableCell>
        <TableCell width={'18%'} sx={tableCellStyle}>
          <TextField
            label=""
            placeholder="Adını daxil edin"
            value={model.name}
            onChange={(e)=> handleFieldChange('name', e.target.value)}
            sx={{ width: '100%', height: '100%', backgroundColor: 'white' }}
          />
          <span style={errorMessage}>{modelErrors.name}</span>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Typography>-</Typography>
        </TableCell>
        <TableCell sx={tableCellStyle}>
              <CustomDatePicker date={model.startDate!} 
                                isSubmodule={false}
                                id={module.id}
                                handleDateChange={(e)=> handleFieldChange('startDate', e as any)}
                                type='date'/>
              <span style={errorMessage}>{modelErrors.startDate}</span>
        </TableCell>
        <TableCell sx={tableCellStyle}>
              <CustomDatePicker date={model.endDate!} 
                                isSubmodule={false}
                                id={module.id}
                                handleDateChange={(e)=> handleFieldChange('endDate', e as any)}
                                type='date'/>
              <span style={errorMessage}>{modelErrors.endDate}</span>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <AddHoursInput value={model.hours} onChange={handleFieldChange}/>
          <span style={errorMessage}>{modelErrors.hours}</span>
        </TableCell>
        <TableCell sx={tableCellStyle}>
            <CustomAutocomplete value={model.workers[0]} 
                                label='' 
                                url={`Workers?role=muellim` }
                                propName='name' 
                                onChange={(e: any)=> handleFieldChange('muellim', e)}/>
        </TableCell>
        <TableCell sx={tableCellStyle}>
            <CustomAutocomplete value={model.workers[1]} 
                                label='' 
                                url={`Workers?role=mentor` }
                                propName='name' 
                                onChange={(e: any)=> handleFieldChange('mentor', e)}/>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{border: 'none'}} width={'3%'}></TableCell>
        <TableCell sx={{border: 'none'}}>
            <Button
            type="submit"
            sx={{
                backgroundColor: '#4ba458',
                borderRadius: '8px',
                m: 1,
                color: 'white',
            }}
            onClick={handleClick}
            >
            Yadda saxla
            </Button>
            <Button
              sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  m: 1,
                  border: '1px solid red',
                  color: 'red',
              }}
              onClick={handleDelete}
              >
                Ləğv et
            </Button>
        </TableCell>
      </TableRow>
    </>
  );
}
