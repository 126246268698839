import { useState } from "react";
import { styled } from "@mui/system";
import { Box, Typography, TextField } from "@mui/material";
import { sxContainer, sxRow } from "./style";
import CheckboxLabels from "../../../components/checkbox-labels";
import { ReactComponent as RemoveRecycleIcon } from "../../../../../../assets/icons/trash.svg";
import {
  ChangeableStatus,
  GetPermissionAccesses,
  PermissionLevel,
} from "../../../models";
import ConfirmModal from "../../../../../../components/custom-components/confirm-modal";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import { ApiInstance } from "../../../../../../network/services/core/apiInstance";
interface PermissionLevelsRowProps {
  permissionLevelsRowId?: number;
  isChangeable: ChangeableStatus;
  disabled: boolean;
  addPermissionLevel?: PermissionLevel;
  setAddPermissionLevel?: React.Dispatch<React.SetStateAction<PermissionLevel>>;
  permissionLevels?: PermissionLevel;
  setPermissionLevels?: React.Dispatch<React.SetStateAction<PermissionLevel[]>>;
}
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& input::placeholder": {
    fontFamily: "Visby CF Bold",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "& input": {
      paddingLeft: 0,
    },
  },
}));
function PermissionLevelsRow(props: PermissionLevelsRowProps) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const {
    isChangeable,
    disabled,
    addPermissionLevel,
    setAddPermissionLevel,
    permissionLevels,
    setPermissionLevels,
    permissionLevelsRowId,
  } = props;
  const handleDeletePermissionLevels = async () => {
    try {
      const res =  await ApiInstance.delete(`api/PermissionLevels/${permissionLevelsRowId}`);
      setPermissionLevels && setPermissionLevels((oldData) =>
        oldData.filter((item) => item.id !== permissionLevelsRowId)
      );
    } catch (error) {
      return console.log(error);
    }
  }
  const handleCheckboxChange = (id: number,e: React.ChangeEvent<HTMLInputElement>) => {
      setPermissionLevels? setPermissionLevels((oldData) =>
        oldData.map((item) =>
          item.id === permissionLevelsRowId
            ? {
                ...item,
                permissionAccesses: item.permissionAccesses.map((access) =>
                  access.id === id ? { ...access, isAccess:(e.target as HTMLInputElement).checked } : access
                ),
              }
            : item
        )
      ):setAddPermissionLevel && setAddPermissionLevel((oldData) =>
        ({ ...oldData, permissionAccesses: oldData.permissionAccesses.map((access) =>
          access.id === id ? { ...access, isAccess:(e.target as HTMLInputElement).checked } : access
        )})
      )
  }
  return (
    <Box sx={sxContainer}>
      <Box sx={sxRow}>
        <Typography sx={{ fontFamily: "Visby CF Bold" }}>
          {setAddPermissionLevel ? (
            <CustomTextField
              label=""
              placeholder="Adını daxil edin..."
              value={addPermissionLevel?.name}
              onChange={(e) =>
                setAddPermissionLevel((oldData) => ({
                  ...oldData,
                  name: e.target.value,
                }))
              }
              sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
            />
          ) : (
            permissionLevels?.name
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {addPermissionLevel &&
            addPermissionLevel.permissionAccesses.map(
              (item: GetPermissionAccesses) => (
                <CheckboxLabels
                  disabled={!disabled}
                  label={item.name}
                  key={item.id}
                  id={item.id}
                  checked={item.isAccess}
                  handleClick={(e:any) => handleCheckboxChange(item.id,e)}
                />
              )
            )}
          {permissionLevels &&
            permissionLevels.permissionAccesses.map(
              (item: GetPermissionAccesses) => (
                <CheckboxLabels
                  disabled={!disabled}
                  label={item.name}
                  key={item.id}
                  id={item.id}
                  checked={item.isAccess}
                  handleClick={(e:any) => handleCheckboxChange(item.id,e)}
                />
              )
            )}
          {isChangeable.isEditPermissionLevel && (
            <RemoveRecycleIcon
              cursor="pointer"
              onClick={() => setDeleteConfirmOpen(true)}
            />
          )}
        </Box>
      </Box>
      {deleteConfirmOpen && (
        <ConfirmModal
          open={deleteConfirmOpen}
          text={"İcazə levelini silmək istədiyinizə əminsiniz?"}
          handleClose={() => setDeleteConfirmOpen(false)}
          handleAgree={() => handleDeletePermissionLevels()}
          isWarning={true}
          sendBtnText="İcazə levelini sil"
        />
      )}
    </Box>
  );
}

export default PermissionLevelsRow;
