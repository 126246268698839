export const sxContainer = {
  display: "flex",
  width: "32.2%",
  padding: "24px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  borderRadius: "16px",
  background: "var(--Natural-natural-15, #F7F7F7)",
  transition: "0.5s ease all",

};
export const sxContent = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
};
export const sxTitle = {
  fontFamily: "Visby CF Bold, sans-serif",
  fontSize: "20px",
  lineHeight: "24px",
  letterSpacing: "0.4px",
};
export const sxDescription = {
  color: "var(--Natural-natural-900, #0E0D0D)",
  fontSize: "14px",
  letterSpacing: "0.4px",
  fontFamily: "Visby CF Regular, sans-serif",
};
export const sxAvatar = {
  boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
  border: "3px solid var(--Other-White, #FFF)",
};
