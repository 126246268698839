import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableHeader from "../../../../../../../components/custom-components/table-header";
import {
  sxTableCell,
  sxTimePicker,
} from "../../../detail/components/table-list/style";
import TableCellBody from "../../../../../../../components/custom-components/table-cell-body";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import Progress from "../../../../../../../components/custom-components/progress";
import moment from "moment";
import { useParams } from "react-router-dom";

interface PropsModel {
  sessions: any[];
  sessionsData: any;

  practiceEndHours: string;
  setPracticeEndHours: React.Dispatch<React.SetStateAction<string>>;
  practiceStartHours: string;
  setPracticeStartHours: React.Dispatch<React.SetStateAction<string>>;
  theoricStartHours: string;
  setTheoricStartHours: React.Dispatch<React.SetStateAction<string>>;
  theoricEndHours: string;
  setTheoricEndHours: React.Dispatch<React.SetStateAction<string>>;
  labStartHours: string;
  setLabStartHours: React.Dispatch<React.SetStateAction<string>>;
  labEndHours: string;
  setLabEndHours: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setSessionsData: React.Dispatch<React.SetStateAction<any>>;
}

function TableList(props: PropsModel) {
  const {
    sessionsData,
    isLoading,
    setSessionsData,
    practiceEndHours,
    practiceStartHours,
    theoricEndHours,
    theoricStartHours,
    labEndHours,
    labStartHours,
    setTheoricStartHours,
    setTheoricEndHours,
    setPracticeStartHours,
    setPracticeEndHours,
    setLabStartHours,
    setLabEndHours,
  } = props;
  const params = useParams();

  const changedTimeFormat = (value: string) => {
    if (value) {
      const now = new Date(sessionsData?.date);
      const [hour, minute] = value.split(":");
      const newDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        Number(hour),
        Number(minute)
      );
      if (isNaN(newDate.getTime())) {
        return null;
      }
      return newDate;
    }
    return null;
  };

  const handleChange = (data: any, category: string) => {
    if (category === "theoricStart") {
      setTheoricStartHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        theoricStart: data?.$d!.toString().substring(16, 21),
      });
    } else if (category === "theoricEnd") {
      setTheoricEndHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        theoricEnd: data?.$d!.toString().substring(16, 21),
      });
    } else if (category === "praticStart") {
      setPracticeStartHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        practicStart: data?.$d!.toString().substring(16, 21),
      });
    } else if (category === "praticEnd") {
      setPracticeEndHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        practicEnd: data?.$d!.toString().substring(16, 21),
      });
    } else if (category === "labStart") {
      setLabStartHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        labStart: data?.$d!.toString().substring(16, 21),
      });
    } else if (category === "labEnd") {
      setLabEndHours(data?.$d!.toString().substring(16, 21));
      setSessionsData({
        ...sessionsData,
        labEnd: data?.$d!.toString().substring(16, 21),
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Progress />
      ) : (
        <TableContainer>
          <Table
              sx={{
                width: "100%",
                border: "none",
              }}
              aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={sxTableCell} align="left">
                  Həftənin günü
                </TableCell>
                <TableCell sx={sxTableCell} align="left">
                  Ümumi saat
                </TableCell>
                <TableCell sx={sxTableCell} align="left">
                  Teorika
                </TableCell>
                <TableCell sx={sxTableCell} align="left">
                  Praktika
                </TableCell>
                <TableCell sx={sxTableCell} align="left">
                  Lab
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{ fontSize: "14px", fontFamily: "Visby CF Bold, sans-serif" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TableRow>
                  <TableCellBody width="15%">
                    {moment(params.date).format("dddd ")}
                  </TableCellBody>
                  <TableCellBody width="10%">
                    {sessionsData.totalHours}
                  </TableCellBody>
                  <TableCellBody width="25%">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <TimePicker
                        ampm={false}
                        onChange={(value: any) =>
                          handleChange(value, "theoricStart")
                        }
                        value={dayjs(
                          changedTimeFormat(
                            theoricStartHours
                              ? theoricStartHours
                              : sessionsData?.theoricStart
                          ) || ""
                        )}
                        sx={{ sxTimePicker }}
                      />
                      <TimePicker
                        ampm={false}
                        onChange={(value: any) =>
                          handleChange(value, "theoricEnd")
                        }
                        value={dayjs(
                          changedTimeFormat(
                            theoricEndHours
                              ? theoricEndHours
                              : sessionsData?.theoricEnd
                          ) || ""
                        )}
                        sx={{ sxTimePicker }}
                      />
                    </Box>
                  </TableCellBody>
                  <TableCellBody width="25%">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <TimePicker
                        ampm={false}
                        value={dayjs(
                          changedTimeFormat(
                            practiceStartHours
                              ? practiceStartHours
                              : sessionsData?.practicStart
                          ) || ""
                        )}
                        onChange={(value: any) =>
                          handleChange(value, "praticStart")
                        }
                        sx={{ sxTimePicker }}
                      />
                      <TimePicker
                        ampm={false}
                        value={dayjs(
                          changedTimeFormat(
                            practiceEndHours
                              ? practiceEndHours
                              : sessionsData?.practicEnd
                          ) || ""
                        )}
                        onChange={(value: any) =>
                          handleChange(value, "praticEnd")
                        }
                        sx={{ sxTimePicker }}
                      />
                    </Box>
                  </TableCellBody>
                  <TableCellBody width="25%">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <TimePicker
                        ampm={false}
                        value={dayjs(
                          changedTimeFormat(
                            labStartHours
                              ? labStartHours
                              : sessionsData?.labStart
                          ) || ""
                        )}
                        onChange={(value: any) =>
                          handleChange(value, "labStart")
                        }
                        sx={{ sxTimePicker }}
                      />
                      <TimePicker
                        ampm={false}
                        value={dayjs(
                          changedTimeFormat(
                            labEndHours ? labEndHours : sessionsData?.labEnd
                          ) || ""
                        )}
                        onChange={(value: any) => handleChange(value, "labEnd")}
                        sx={{ sxTimePicker }}
                      />
                    </Box>
                  </TableCellBody>
                </TableRow>
              </LocalizationProvider>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default TableList;
